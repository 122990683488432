import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// pages
import { useDispatch } from 'react-redux';
import { setPlayerLogout } from 'src/redux/slices/user';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children, ual }) {
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const dispatch = useDispatch();

  const logout= () =>{
    dispatch(setPlayerLogout(ual));
    return <Navigate to={'/'} />;
  }

  if (!ual.activeUser) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
      localStorage.setItem('pathname', '/seedball/nurture');
    }
    return logout();
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
