import { Typography } from "@mui/material";

export const repairinfo = <div>
	<Typography variant="h5" color={'#14a6a5'}>
		Mission task
	</Typography>
	<Typography mb={1}>
        Blend your "Halted" containers and receive one or more new containers.
	</Typography>
    <Typography>
        Required Ingredients
	</Typography>
    <Typography mb={1}>
        * Check out the current Deals for Recycle our Mechanics have prepared: These Deals are limited, but don't worry - new Deals will follow.....
	</Typography>
    <Typography mb={1}>
        Rarity of the container(s) you will receive is random, so be Surprised !
	</Typography>
</div>;


export const repairinfoTitleText = <div>
	<Typography mb={2}>
		Good to see you here, my Friend!
	</Typography>
	<Typography mb={2}>
		When the lifetime of container has come to its end, it becomes "Halted" and can no longer be used to nurture Seedballs.
	</Typography>
	<Typography>
		But don't worry - you can sell them on the Secondary Market or bring them to the Recycle Center where our Mechanics can bring new life to them.
	</Typography>
</div>