import PropTypes from 'prop-types';
import React from 'react';
// material
import {
  Divider,Grid,Box,
  Drawer, Stack, Typography
} from '@mui/material';
import { Icon } from '@iconify/react';
import { MIconButton } from 'src/components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import Scrollbar from 'src/components/Scrollbar';
import { Link } from 'react-router-dom';
// components

// ----------------------------------------------------------------------

BoostDrawer.propTypes = {
  isOpenFilter: PropTypes.bool,
  onCloseFilter: PropTypes.func
};

export default function BoostDrawer({isOpenFilter, onCloseFilter, type, boost, selectedBoost, updateBoost}) {
  const onSelectBoost = (data) => {
    if(!selectedBoost || (selectedBoost && selectedBoost.name !== data.name)){
      if(selectedBoost && selectedBoost.name !== data.name) {
        selectedBoost.quantity = selectedBoost.quantity + 1;
      }
      data.quantity = data.quantity - 1;
      updateBoost(data);
      onCloseFilter();
    } else{
      onCloseFilter();
    }
    
  }
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={isOpenFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' }
        }}
      >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
            <Typography variant="subtitle1">{type} Boost</Typography>
            <MIconButton onClick={onCloseFilter}>
              <Icon icon={closeFill} width={20} height={20} />
            </MIconButton>
          </Stack>
          <Divider />
          <Scrollbar>
          {boost.map((data, index) => (
            <Grid key={data.name} container sx={{p:3}} spacing={2}>
                <Grid item xs={5} md={5} lg={5}>
                <Box
                  component="img"
                  src={data.image}
                  onClick={()=>{onSelectBoost(data)}}
                  sx={{
                    left: 40,
                    width: 100,
                    cursor: 'pointer',
                    borderRadius: '10px',
                    filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
                  }}
                />
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  <Typography variant="h6" gutterBottom>
                    {data.name}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    Available quantity
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {data.quantity}
                  </Typography>
                </Grid>
            </Grid>
          ))}
          </Scrollbar>
      </Drawer>
    </React.Fragment>
  );
}
