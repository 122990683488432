import { Typography } from "@mui/material";

export const seedballinfo = <div>
        <Typography variant="h5" color={'#14a6a5'}>
                Mission task
        </Typography>
        <Typography mb={3}>
            Collect all 5 Items and blend to get a &quot;Seedball&rdquo; as a blended result.
        </Typography>
        <Typography>
            Required Ingredients
        </Typography>
        <Typography ml={4}>
            <ul>
                <li>Seed</li>
                <li>Soil</li>
                <li>Water Spray</li>
                <li>Compost</li>
                <li>Light</li>
            </ul>  
        </Typography>
        <Typography mt={4} variant="h5" color={'#14a6a5'}>
            What next?
        </Typography>
        <Typography>
            After blending the required ingredients you will get a "Seedball", which you can use in the next mission task.
        </Typography>
    </div>;

export const seedballInfoTitleText = <div>
    <Typography>
        Welcome new Friend
    </Typography>
    <Typography>
        We have travelled far and wide to reach this moment in time.
    </Typography>
    <Typography>
        Here you learn the basics of crafting the beginning of life.
    </Typography>
    <Typography>
        Today starts your new journey to relight the planets with life and resources.
    </Typography>
</div>