// import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { 
  // Button,
   Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
//
import { MaintenanceIllustration } from '../assets';
import Logo from 'src/components/Logo';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPlayerLogout } from 'src/redux/slices/user';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Maintenance({ual}) {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setPlayerLogout(ual));
  },[])
  return (
    <RootStyle title="Maintenance">
      <Container sx={{ textAlign: 'center' }}>
        <Logo sx={{margin: '0 auto'}}/>
        <Typography variant="h3" sx={{ color: 'primary.main', mt: 3 }} paragraph>
          SERVER MAINTENANCE
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>Due to scheduled maintenance activity. The game will not be available on 19th March 2022, 10.30 am UTC - 12.30 pm UTC.</Typography>

        <MaintenanceIllustration />

        {/* <Button variant="contained" size="large" component={RouterLink} to="/">
          Go to Home
        </Button> */}
      </Container>
    </RootStyle>
  );
}
