// mock api
import './_apis_';

// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
// import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
// import { AuthProvider } from './contexts/Auth0Context';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import * as AWS from 'aws-sdk'
import { applicationConfig } from './config';
import { store } from './redux/store';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

const configuration = {
    region: 'ap-south-1',
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    accessKeyId: process.env.REACT_APP_ACCESS_ID
}

AWS.config.update(configuration)
const appName = 'seedball';
const myChain = {
  chainId: applicationConfig.chainId,
  rpcEndpoints: [{
      protocol: 'https',
      host: applicationConfig.eosioUrl,
      port: ''
  }]
};
const anchor = new Anchor([myChain], { appName });
const wax = new Wax([myChain], { appName });
const AppConsumer = withUAL(App)

// ----------------------------------------------------------------------
ReactDOM.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <CollapseDrawerProvider>
        <BrowserRouter>
        <UALProvider chains={[myChain]} authenticators={[wax,anchor]} appName={appName}>
            <AppConsumer />
          </UALProvider>
        </BrowserRouter>
      </CollapseDrawerProvider>
    </ReduxProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
