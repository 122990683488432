import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Typography, Stack, Card, alpha, Link, Grid } from '@mui/material';
import { Divider } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { varFadeInDown, varWrapEnter } from '../animate';
import { motion } from 'framer-motion';

const RootStyle = styled(motion.div)(({ theme }) => ({
}));

const IconStyleContainer = styled('div')(({ theme }) => ({
  marginTop: 5,
  borderRadius: '50%',
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  backgroundColor: theme.palette.error.main,  
  border: `solid 0px ${theme.palette.background.paper}`
}));

const IconStyle = styled('div')(({ theme }) => ({
  marginTop: 5,
  borderRadius: '50%',
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  backgroundColor: theme.palette.warning.main,  
  border: `solid 0px ${theme.palette.background.paper}`
}));

const BgImage = styled('img')(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  zIndex:0
}));

const TopContainer =  styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: 'end',
  justifyContent: 'center'
}));

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object
};

function CarouselItem({ item, index, assetsLength }) {
  const { id, creator_count, producer_count,master_count, iconi_price, slots} = item;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const getTransformOnLength = () => {
    let transformValue;
    switch(assetsLength) {
      case 5:
        transformValue = index === 2 ? 'scale(1)' : ([1,3].includes(index) ? 'scale(.75)' : 'scale(.65)');
        break;
      case 4:
        transformValue = [1,2].includes(index) ? 'scale(1)' : 'scale(.75)'
        break;
      case 3:
        transformValue = index === 1 ? 'scale(1)' : 'scale(.75)'
        break;
      case 2:
        transformValue = 'scale(1)';
        break;
      case 1:
        transformValue = 'scale(1)';
        break;
      default:
        transformValue = 'scale(1)';
        break;
    }
    return transformValue
  }

  const getpositionForLastElements = () => {
    let transformOriginValue;
    switch(index) {
      case 4:
        transformOriginValue = 'left';
        break;
      case 0:
        transformOriginValue = 'right';
        break;
      default:
        transformOriginValue = 'center';
        break
    }
    return transformOriginValue;
  }
  const getAnimationScale = () => {
    let transformValue;
    switch(assetsLength) {
      case 5:
        transformValue = index === 2 ? 'scale(1.03)' : ([1,3].includes(index) ? 'scale(.78)' : 'scale(.68)');
        break;
      case 4:
        transformValue = [1,2].includes(index) ? 'scale(1.03)' : 'scale(.78)'
        break;
      case 3:
        transformValue = index === 1 ? 'scale(1.03)' : 'scale(.78)'
        break;
      case 2:
        transformValue = 'scale(1.03)';
        break;
      case 1:
        transformValue = 'scale(1.03)';
        break;
      default:
        transformValue = 'scale(1.03)';
        break;
    }
    return transformValue;
  }

  return (
    <Link component={RouterLink} underline="none"  to={`${id}`}>
    <Card sx={!isMobile?{
      cursor: 'pointer',
      opacity: 1,
      transform: getTransformOnLength(),
      transformOrigin: assetsLength >= 5 ? getpositionForLastElements() : 'center',
      zIndex: 9,
      minHeight: "230px",
      backgroundColor: "transparent",
      overflow: "unset",
      width: !isTablet ? '90%' : '100%',
      '&:hover': !isTablet ? {
        boxShadow: `0px 1px 16px 5px ${alpha('rgb(8,153,228)', '0.74')}`,
        transform: getAnimationScale(),
        transition: 'all 0.5s linear',
        opacity: 1,
     } : 'none'
    }: {
      mx:1,
      backgroundColor: "transparent",
      width: '178px',
      overflow: 'unset'
      }}>

    <TopContainer>
    <BgImage src="/static/seedball/repair-card-bg.png"/>
      <Stack sx={{ 
          p:2,
          textAlign: 'center',
          minHeight:"170px",
          alignItems:"start",
          justifyContent:"center",
          width: 'fit-content'
          // backgroundImage: "url(/static/seedball/repair-card-bg.png)"
        }}>
          {+master_count > 0 && <Typography display={'flex'} zIndex={2} variant="subtitle1">
          <IconStyleContainer sx={{ mr: 1}}/> {master_count} Master
          </Typography>}
          {+producer_count > 0 && <Typography display={'flex'} zIndex={2} variant="subtitle1">
          <IconStyleContainer sx={{ mr: 1}}/> {producer_count} Producer
          </Typography>}
          {+creator_count > 0 && <Typography display={'flex'} zIndex={2} variant="subtitle1">
          <IconStyleContainer sx={{ mr: 1}}/> {creator_count} Creator
          </Typography>}
          {iconi_price && <Typography display={'flex'} zIndex={2} variant="subtitle1">
          <IconStyle sx={{ mr: 1}}/> {iconi_price.split('.')[0]} ICONI
          </Typography>}
      </Stack>
    </TopContainer>
    <Divider/>
    <Stack sx={{ p: 2, backgroundImage: `linear-gradient(0deg,
          ${theme.palette.grey['800']} 0%,
          ${theme.palette.grey['900']} 100%)`,
        }}>
        {slots && slots[0] && slots[0].value && <Typography variant="subtitle1">
          Get {slots[0].value} container(s)
        </Typography>}
        <Typography variant="caption" sx={{color: 'text.secondary'}} noWrap>
          Deal ID: {id}
        </Typography>
    </Stack>
  </Card>
  </Link>
  );
}

export default function RepairDataCard({assets}) {
  const theme = useTheme();
  const isComputer = useMediaQuery(theme.breakpoints.up('lg'));
  const isTab = useMediaQuery(theme.breakpoints.down('lg') && theme.breakpoints.up('md'));

  const getGridSpacing = () => {
    if( isComputer ){
      return (assets && assets.length >= 5) ? undefined : 3;
    } else if(isTab){
      return 4;
    } else {
      return 2;
    }
  }

  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      {assets && assets?.length > 0 && <>
        <Grid container sx={{justifyContent: 'center'}} p={{xs:6, sm:6, md:0}} px={{xs:8, sm:18, md:0}} spacing={getGridSpacing()}>
          {assets.map((item, index) => {      
            return (<Grid sx={{
              justifyContent: 'flex-start',
              padding: assets.length === 5 ? 'auto !important' : "0 !important",
              margin:assets.length === 5 ? '0 !important' : '24px',
              }}
              flexBasis={{xs:'auto', sm:'auto', md:'19% !important'}}
              maxWidth={{xs:'auto', sm:'auto', md:'19% !important'}}
              minWidth={{xs:'auto', sm:'auto', md:'175px !important'}}
              key={item.id} item xs={12} sm={6} md={2}>
              <motion.div variants={varFadeInDown}>
                  <CarouselItem index={index} key={item.id} item={item} assetsLength={assets.length} />
              </motion.div>
            </Grid>)
          })}
        </Grid>
        </>}
        </RootStyle>
  );
}