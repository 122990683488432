import { Typography } from "@mui/material";

export const containerNurtureInfo = <div>
        <Typography variant="h5" color={'#14a6a5'}>
            Mission task
        </Typography>
        <Typography mb={1}>
            In the Nurture Stage, the user will nurture a “Seedball” with a container, the complete nurture process will require three special powers at 100% to complete this Stage.
        </Typography>
        <Typography mb={1}>
        Once the user starts container power, the user can’t remove or add any power or seed ball to the container. 
        </Typography>
        <Typography mb={1}>
        The container nurturing process stops if a container that has been started is transferred, sold or traded, and the user must start over, losing one life and all the ingredients needed to start the container.
        </Typography>
    </div>