import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
//
import { MIconButton } from '../../../@material-extend';
import Scrollbar from '../../../Scrollbar';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' }
];
export const FILTER_ALL_OPTIONS = ['All'];
export const FILTER_BASKET_OPTIONS = ['Unbox Deal', 'Unbox Basket'];
export const FILTER_CONTAINER_OPTIONS = ['Propagator', 'Creator', 'Producer', 'Master'];
export const FILTER_STATUS_OPTIONS = ['Available', 'In Progress', 'Sprout', 'Halted'];
export const FILTER_HARVEST_STATUS_OPTIONS = ['Available', 'In Progress', 'Harvest'];
export const FILTER_HARVEST_RARITY_OPTIONS = ['Common', 'Uncommon', 'Rare', 'Super Rare', 'Legendary', 'Mythic'];
export const FILTER_SEEDBALL_OPTIONS = ['Water spray', 'Soil', 'Vermi compost', 'Seed', 'Sun light', 'Seed ball', 'Seed ball Kit'];
export const FILTER_NURTURE_OPTIONS = ['Nutrition', 'Sun', 'Rain', 'Sprout'];
export const FILTER_CARE_OPTIONS = ['Fence', 'Scarecrow', 'Temperature', 'PH Level', 'Care Kit'];
export const FILTER_HARVEST_OPTIONS = ['Apricot', 'Apple', 'Onion', 'Broccoli', 'Orange', 'Banana', 'Kiwi',
'Carrot', 'Potatoes', 'Green Pepper', 'Avocado', 'Tomato', 'Peanut', 'Strawberry', 'Grapes', 'Pineapple', 'Cashew',
'Walnuts', 'Mango', 'Pistachio'];
export const FILTER_BOOST_OPTIONS = ['Nurture', 'Harvest'];
export const FILTER_BOOST_POWER_OPTIONS = ['50%', '25%', '10%'];

// ----------------------------------------------------------------------

ShopFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object
};

export default function ShopFilterSidebar({ isOpenFilter, onResetFilter, onOpenFilter, onCloseFilter, formik }) {
  const { values, getFieldProps } = formik;
  const setValuesToEmpty = (field) =>{
    if(field === 'selectAll'){
      values.container = [];
      values.seedball = [];
      values.care = [];
      values.nurture = [];
      values.harvest = [];
      values.status = [];
      values.harvestStatus = [];
      values.basket = [];
      values.rarity = [];
      values.boost = [];
      values.power = [];
    }else{
      values.selectAll = [];
    }
  }

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Icon icon={roundFilterList} />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: 'none', overflow: 'hidden' }
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Filters
              </Typography>
              <MIconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Divider />

            <Scrollbar>
              <Stack spacing={3} sx={{ p: 3 }}>
              {/* <div>
                  <FormGroup>
                  {FILTER_ALL_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('selectAll')}} {...getFieldProps('selectAll')} value={item} checked={values.selectAll.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                </div> */}
                { Object.keys(values).indexOf('container') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Container
                  </Typography>
                  <FormGroup>
                  {FILTER_CONTAINER_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('container')}} {...getFieldProps('container')} value={item} checked={values.container.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('status') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Status
                  </Typography>
                  <FormGroup>
                  {FILTER_STATUS_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('status')}} {...getFieldProps('status')} value={item} checked={values.status.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('seedball') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Seedball
                  </Typography>
                  <FormGroup>
                  {FILTER_SEEDBALL_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('seedball')}} {...getFieldProps('seedball')} value={item} checked={values.seedball.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('nurture') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Nurture
                  </Typography>
                  <FormGroup>
                  {FILTER_NURTURE_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('nurture')}} {...getFieldProps('nurture')} value={item} checked={values.nurture.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('care') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Care
                  </Typography>
                  <FormGroup>
                  {FILTER_CARE_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('care')}} {...getFieldProps('care')} value={item} checked={values.care.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('harvestStatus') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Status
                  </Typography>
                  <FormGroup>
                  {FILTER_HARVEST_STATUS_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('harvestStatus')}} {...getFieldProps('harvestStatus')} value={item} checked={values.harvestStatus.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}

                {Object.keys(values).indexOf('rarity') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Rarity
                  </Typography>
                  <FormGroup>
                  {FILTER_HARVEST_RARITY_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('rarity')}} {...getFieldProps('rarity')} value={item} checked={values.rarity.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}

                {Object.keys(values).indexOf('harvest') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Harvest
                  </Typography>
                  <FormGroup>
                  {FILTER_HARVEST_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('harvest')}} {...getFieldProps('harvest')} value={item} checked={values.harvest.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('basket') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Unpack Items
                  </Typography>
                  <FormGroup>
                  {FILTER_BASKET_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('basket')}} {...getFieldProps('basket')} value={item} checked={values.basket.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('boost') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Boost
                  </Typography>
                  <FormGroup>
                  {FILTER_BOOST_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('boost')}} {...getFieldProps('boost')} value={item} checked={values.boost.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
                {Object.keys(values).indexOf('power') > -1 && <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Boost Power
                  </Typography>
                  <FormGroup>
                  {FILTER_BOOST_POWER_OPTIONS.map((item) => (
                      <FormControlLabel
                      key={item}
                      control={
                        <Checkbox onClick={()=>{setValuesToEmpty('power')}} {...getFieldProps('power')} value={item} checked={values.power.includes(item)} />
                      }
                      label={item}
                    />
                    ))}
                  </FormGroup>
                    
                </div>}
              </Stack>
            </Scrollbar>

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="button"
                color="inherit"
                variant="outlined"
                onClick={onResetFilter}
                startIcon={<Icon icon={roundClearAll} />}
              >
                Clear All
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </>
  );
}
