// external
import PropTypes from "prop-types";
// material
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import React, { useState } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  height: "15%",
  width: "100%",
  display: "inline-block",
  [theme.breakpoints.down('md')]: {
    height: "8%"
  }
}));

const ImgStyle = styled('img')({
  width: 'auto',
  height: 'auto',
  cursor: 'pointer',
  margin: 'auto'
});

// ----------------------------------------------------------------------

FooterBanner.propTypes = {
  showData: PropTypes.func
};

export default function FooterBanner({showData}) {
  const [bannerArray, setbannerArray] = useState([]);
  showData(true);
  React.useEffect(() => {
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", "/static/seedball/banner.json", true);
    rawFile.onreadystatechange = function() {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
            if(window.location.href.includes('nurture')) {
              if(JSON.parse(rawFile.response).nurture.length)
                setbannerArray(sortArrayByPosition(JSON.parse(rawFile.response).nurture));
              else
                showData(false);
            } else {
              if(JSON.parse(rawFile.response).harvest.length)
                setbannerArray(sortArrayByPosition(JSON.parse(rawFile.response).harvest));
              else
                showData(false);
            }
        } else {
          showData(false);
        }
    }
    rawFile.send(null);
  },[]);

  const sortArrayByPosition = (arr) => {
    return arr.sort((a,b)=> a.position - b.position);
  };

  const imgError = (error) => {
    error.target.parentElement.parentElement.style.display = 'none'
  };

  return (
    <>
      {bannerArray.length > 0 && <RootStyle>
        <Box height={"100%"} margin="0 10%" alignItems="center" justifyContent="center">
          <Grid
            height={"100%"}
            container
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            {bannerArray.map((item, index) => { 
              return (<Grid key={item.imageUrl} item xs={12} md={6} alignItems="center" justifyContent="center">
                <a href={item.redirectUrl} target="_blank">
                <ImgStyle
                  src={item.imageUrl}
                  alt='banner'
                  loading="lazy"
                  onError={imgError}
                />
                </a>
              </Grid>)
            })}
          </Grid>
        </Box>
      </RootStyle>}
    </>
  );
}
