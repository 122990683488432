import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import kanbanReducer from './slices/kanban';
import templateReducer from './slices/templates';
import seedballReducer from './slices/seedball';
import productReducer from './slices/product';
import harvestReducer from './slices/harvest';
import containerReducer from './slices/container';
import loaderReducer from  './slices/loader';
import userTemp from './slices/user-temp';
import repair from './slices/repair';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  user: userReducer,
  kanban: kanbanReducer,
  template: templateReducer,
  seedball: seedballReducer,
  harvest: harvestReducer,
  container: containerReducer,
  loader: loaderReducer,
  product: persistReducer(productPersistConfig, productReducer),
  userTemp: userTemp,
  repair: repair
});

export { rootPersistConfig, rootReducer };
