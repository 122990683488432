import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHarvestAssets } from "src/redux/slices/harvest";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { ShopFilterSidebar, ShopProductList } from "src/components/_dashboard/e-commerce/shop";
import { filterAllResources } from "src/redux/slices/seedball";

function applyFilter(resources, filters) {
  let harvestResources = ((filters.harvest && filters.harvest.length > 0) || (filters.harvestStatus && filters.harvestStatus.length > 0) || (filters.rarity && filters.rarity.length > 0))  ?
  resources.filter(obj => obj.category === 'Harvest' && (filters.harvest.includes(obj.subCategory) || filters.rarity.includes(obj.rarity) || (filters.harvestStatus.includes(obj.status) || (filters.harvestStatus.includes('Available') && obj.status === 'Harvest' && obj.percent === 0))))
  : resources.filter(obj => obj.category === 'Harvest');
  return harvestResources.length > 0 ? [...harvestResources] : [];
}

export default function HarvestTab() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [harvestFilters, setHarvestFilters] = React.useState({ harvest: [], harvestStatus: [], rarity: [] });
  const filters = useSelector((state) => state.seedball.filters);
  const harvests = useSelector((store) => store.harvest.harvestAssets);
  const UserState = useSelector((store) => store.user.userDetails);
  const filteredProducts = applyFilter([...harvests], harvestFilters);
  const formik = useFormik({
    initialValues: {
      harvest: [],
      harvestStatus: [],
      rarity: []
    },
    onSubmit: () => {
      
    }
  });

  const { values, resetForm, initialValues } = formik;

  React.useEffect(() => {
    dispatch(getHarvestAssets(UserState.name, UserState.session));
    setHarvestFilters(filters);
  }, [dispatch, UserState.name, UserState.session, filters]);

  React.useEffect(() => {
    dispatch(filterAllResources(values));
  }, [dispatch, values]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    resetForm();
  };

  return (
    <>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ShopFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
        </Stack>
      </Stack>
      <ShopProductList products={filteredProducts} isLoad={!filteredProducts && !initialValues} />
    </>
  )
}
