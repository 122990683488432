

import FiberSmartRecordOutlinedIcon from '@mui/icons-material/FiberSmartRecordOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
// ----------------------------------------------------------------------


const GameMenuConfig = [
  {
    title: 'Seed ball',
    icon: <FiberSmartRecordOutlinedIcon/>,
    path: '/seedball/seedball'
  },
  {
    title: 'Nurture',
    icon: <DeviceHubOutlinedIcon/>,
    path: '/seedball/nurture'
  },
  {
    title: 'Care',
    icon: <SpaOutlinedIcon/>,
    path: '/seedball/care'
  },
  {
    title: 'Harvest',
    icon: <PublishedWithChangesOutlinedIcon/>,
    path: '/seedball/harvest'
  },
  {
    title: 'Recycle Center',
    icon: <BuildCircleOutlinedIcon/>,
    path: '/seedball/recycle'
  },
  {
    title: 'Inventory',
    icon: <CategoryOutlinedIcon/>,
    path: '/seedball/inventory'
  }
  // {
  //   title: 'Dashboard',
  //   icon: <CategoryOutlinedIcon/>,
  //   path: '/seedball/dashboard'
  // }
  // {
  //   title: 'Profile',
  //   icon: <Icon icon={homeFill} {...ICON_SIZE} />,
  //   path: '/seedball/profile'
  // }
];

export default GameMenuConfig;
