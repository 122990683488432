import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { ShopFilterSidebar, ShopProductList } from "src/components/_dashboard/e-commerce/shop";
import { filterAllResources, getBasketPayments } from "src/redux/slices/seedball";

function applyFilter(resources, filters) {
  // let basketResources = (filters.basket && filters.basket.length > 0) ? resources.filter(obj => (obj.category === 'Unbox Basket')) : [];
  let basketResources = (filters.basket && filters.basket.length > 0) ?
  resources.filter(obj => obj.category === 'Unbox Basket' && (filters.basket.includes(obj.name) || (filters.basket.includes('Unbox Deal') && obj.name === 'Unbox')))
  : resources.filter(obj => obj.category === 'Unbox Basket');
  return basketResources.length > 0 ? [...basketResources] : [];
}
export default function UnpackItemsTab() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [basketFilters, setBasketFilters] = React.useState({ basket: [] });
  const filters = useSelector((state) => state.seedball.filters);
  const baskets = useSelector((store)=> store.seedball.basketPayments);
  const UserState = useSelector((store) => store.user.userDetails);
  const filteredProducts = applyFilter([...baskets], basketFilters);
  const formik = useFormik({
    initialValues: {
      basket: [],
    },
    onSubmit: () => {
      
    }
  });

  const { values, resetForm, initialValues } = formik;

  React.useEffect(() => {
    dispatch(getBasketPayments(UserState.session, UserState.name));
    setBasketFilters(filters);
  }, [dispatch, UserState.name, UserState.session, filters]);

  React.useEffect(() => {
    dispatch(filterAllResources(values));
  }, [dispatch, values]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    resetForm();
  };

  return (
    <>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ShopFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
        </Stack>
      </Stack>
      <ShopProductList products={filteredProducts} isLoad={!filteredProducts && !initialValues} />
    </>
  )
}
