import * as React from "react";
import PropTypes from "prop-types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import Logo from "src/components/Logo";
import { useTheme } from "@emotion/react";

InfoDialog.propTypes = {
  message: PropTypes.object,
  title: PropTypes.string,
};

const ImageStyle = styled("img")(({ theme }) => ({
  position: "absolute",
  right: "-125px",
  bottom: "0px",
  width: "50%",
}));

const DialogStyle = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-rounded": {
    overflow: "unset",
    // height: "550px",
    minWidth: "750px",
    maxWidth: "auto",
    borderRadius: "25px",
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiPaper-rounded": {
      minWidth: "auto"
    }
  }
}));

export default function InfoDialog({ message, title, titleText }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <span>
      <InfoOutlinedIcon
        onClick={handleClickOpen()}
        sx={{ ml: "15px", color: "#ffffff", cursor: "pointer" }}
      />
      <DialogStyle
        open={open}
        onClose={handleClose}
        styles={{
          "& .MuiPaper-rounded": {
            position: "relative",
            overflow: "unset",
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <CancelIcon
          sx={{
            position: "absolute",
            right: "-10px",
            top: "-10px",
            fontSize: "2rem",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />

        {!isMobile && (
          <ImageStyle
            src="/static/seedball/inforamtion-window-creative.png"
            alt="BackGround overlay"
          ></ImageStyle>
        )}
        {/* <DialogTitle id="scroll-dialog-title">{title}</DialogTitle> */}
        <DialogContent
          sx={{
            borderRadius: "16px",
            backgroundImage: "url(/static/seedball/info-window-bg.jpg)",
            border: "1px solid #494c4e",
            overflow: "auto",
            maxHeight: "80vh"
          }}
        >
          {/* <DialogContent
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div dangerouslySetInnerHTML={template}></div>
          </DialogContent> */}
          <Container
            sx={{ paddingRight: isMobile ? "0px" : "200px !important", overflow: "auto" }}
          >
            {/* Title */}
            <Box sx={{ marginBottom: "20px" }}>
              <Typography variant="h5" color={"primary"}>
                {title}
              </Typography>

              {titleText}
            </Box>

            <Box sx={{ marginBottom: "30px" }}>
              {/* <div dangerouslySetInnerHTML={template}></div> */}
              {message}
            </Box>

            <Box>
              <Logo image={"/static/seedball/seedball-logo.svg"} />
            </Box>
          </Container>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions> */}
      </DialogStyle>
    </span>
  );
}
