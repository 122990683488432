import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  templates: { assets: { containers: [], harvests: []} },
  rewards: {
    winners: [],
    spirit: [],
    admirer: []
  },
  labels: []
};

const slice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getWinners(state, action) {

      state.isLoading = false;
      state.rewards[action.payload.type] = action.payload.winners;
    }
  }
});

// Reducer
export default slice.reducer;
export const {
  getWinners
} = slice.actions;
// ----------------------------------------------------------------------


export function getWinnersList(templateId) {
    try {
      return fetch(`https://wax.api.atomicassets.io/atomicassets/v1/accounts?collection_name=theiconical1&template_id=${templateId}&page=1&limit=100&order=desc`)
      .then(response => response.json())
      .then(res => {
        return res.data;
      });
      
    } catch (error) {
      return [];
    }
}