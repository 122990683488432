import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
// material
import { Container, Typography, Box, Tabs, Tab } from '@mui/material';
import FiberSmartRecordOutlinedIcon from '@mui/icons-material/FiberSmartRecordOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
// redux
import { useSelector } from '../../redux/store';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Page500 from '../Page500';
import HarvestTab from './InventoryTabs/HarvestTab';
import SeedballTab from './InventoryTabs/SeedballTab';
import NurtureTab from './InventoryTabs/NurtureTab';
import CareTab from './InventoryTabs/CareTab';
import ContainerTab from './InventoryTabs/ContainerTab';
import UnpackItemsTab from './InventoryTabs/UnpackItemsTab';
import { useDispatch } from 'react-redux';
import { seedballStatusFalse, setAssetQuantityToInitialState, setHarvestBoxStatusFalse, sproutedFalse } from 'src/redux/slices/seedball';
import { setRepairStatusFalse } from 'src/redux/slices/repair';
import OfflineBoltOutlined from '@mui/icons-material/OfflineBoltOutlined';
import BoostTab from './InventoryTabs/BoostTab';

// ----------------------------------------------------------------------

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Container>{children}</Container>
        </Box>
      )}
    </div>
  );
}

export default function EcommerceShop({ual}) {
  const { themeStretch } = useSettings();
  const {search} = useLocation();
  const showErrorPage = useSelector(state=> state.loader.isError);
  const [showError, setshowError] = useState(false);
  const [value, setValue] = React.useState(['container'].includes(search.split('?type=')[1]) ? 4 : (['harvest'].includes(search.split('?type=')[1]) ? 3 : 0) );

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  useEffect(()=>{
    setshowError(showErrorPage);
    dispatch(sproutedFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(setHarvestBoxStatusFalse());
    dispatch(setRepairStatusFalse());
    dispatch(seedballStatusFalse());
  },[showErrorPage]);

  return (
    <>
    {!showError && <Page title="Inventory">
      <Container maxWidth={themeStretch ? false : 'lg'}>
      <Typography color='primary.main' gutterBottom variant="h3" sx={{ textAlign: 'center' , mx: 'auto' }}>
              Inventory
        </Typography>

        <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '12px' }}>
          <Tabs
            sx={{
              '.MuiTabs-flexContainer': {
                justifyContent: ['auto', 'auto', 'center']
              }
            }}
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons='auto'
            allowScrollButtonsMobile
            aria-label="basic tabs example">
            <Tab label="Seedball Craft" icon={<FiberSmartRecordOutlinedIcon />}/>
            <Tab label="Nurture" icon={<DeviceHubOutlinedIcon />}/>
            <Tab label="Care" icon={<SpaOutlinedIcon />}/>
            <Tab label="Harvest" icon={<PublishedWithChangesOutlinedIcon />}/>
            <Tab label="Container" icon={<DeviceHubOutlinedIcon />}/>
            <Tab label="Unpack Item" icon={<Inventory2OutlinedIcon />}/>
            <Tab label="Boost" icon={<OfflineBoltOutlined />}/>
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <SeedballTab></SeedballTab>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <NurtureTab></NurtureTab>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <CareTab></CareTab>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <HarvestTab></HarvestTab>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <ContainerTab></ContainerTab>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <UnpackItemsTab></UnpackItemsTab>
        </TabPanel>

        <TabPanel value={value} index={6}>
          <BoostTab></BoostTab>
        </TabPanel>
      </Container>
    </Page>}
    {showError && <Page500 ual={ual}/>}
    </>
  );
}
