import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { ShopFilterSidebar, ShopProductList } from "src/components/_dashboard/e-commerce/shop";
import { filterAllResources, getSimpleAssets } from "src/redux/slices/seedball";

function applyFilter(resources, filters) {
  let seedballResources;
  seedballResources = (filters.seedball && filters.seedball.length > 0) ?
    resources.filter(obj => ((obj.category === 'Seedball' || obj.name === 'Seed ball') && obj.quantity !== '0')  &&  filters.seedball.includes(obj.subCategory))
    : resources.filter(obj => ((obj.category === 'Seedball' || obj.name === 'Seed ball') && obj.quantity !== '0'));
  return seedballResources.length > 0 ? [...seedballResources] : [];
}
export default function SeedballTab() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [seedballFilters, setSeedballFilters] = React.useState({ seedball: [] });
  const { simpleAssets, filters } = useSelector((state) => state.seedball);
  const UserState = useSelector((store) => store.user.userDetails);
  const filteredProducts = applyFilter([...simpleAssets], seedballFilters);
  const formik = useFormik({
    initialValues: {
      seedball: [],
    },
    onSubmit: () => {
      
    }
  });

  const { values, resetForm, initialValues } = formik;

  React.useEffect(() => {
    dispatch(getSimpleAssets(UserState.session));
    setSeedballFilters(filters);
  }, [dispatch, UserState.name, UserState.session, filters]);

  React.useEffect(() => {
    dispatch(filterAllResources(values));
  }, [dispatch, values]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    resetForm();
  };

  return (
    <div>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ShopFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
        </Stack>
      </Stack>
      <ShopProductList products={filteredProducts} isLoad={!filteredProducts && !initialValues} />
    </div>
  )
}
