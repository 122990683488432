// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import FiberSmartRecordOutlinedIcon from '@mui/icons-material/FiberSmartRecordOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';

// ----------------------------------------------------------------------

const ICONS = {
  seedball: <FiberSmartRecordOutlinedIcon/>,
  nurture: <DeviceHubOutlinedIcon/>,
  care: <SpaOutlinedIcon/>,
  harvest: <PublishedWithChangesOutlinedIcon/>,
  profile: <AccountCircleOutlinedIcon/>,
  inventory: <CategoryOutlinedIcon/>,
  repair: <BuildCircleOutlinedIcon/>
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Game',
    items: [
      {
        title: 'Seedball',
        path: PATH_DASHBOARD.general.seedball,
        icon: ICONS.seedball
      },
      {
        title: 'Nurture',
        path: PATH_DASHBOARD.general.container,
        icon: ICONS.nurture
      },
      {
        title: 'Care',
        path: PATH_DASHBOARD.general.care,
        icon: ICONS.care
      },
      {
        title: 'Harvest',
        path: PATH_DASHBOARD.general.harvest,
        icon: ICONS.harvest
      },
      {
        title: 'Recycle Center',
        path: PATH_DASHBOARD.general.repair,
        icon: ICONS.repair
      }
    ]
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Inventory',
        path: PATH_DASHBOARD.general.inventory,
        icon: ICONS.inventory
      }
      // {
      //   title: 'Dashboard',
      //   path: PATH_DASHBOARD.general.dashboard,
      //   icon: ICONS.inventory
      // }
      // {
      //   title: 'Profile',
      //   path: PATH_DASHBOARD.general.profile,
      //   icon: ICONS.profile
      // }
    ]
  }
];

export default sidebarConfig;
