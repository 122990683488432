// material
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/styles';

// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useLayoutEffect, useState } from 'react';
import {  varWrapEnter } from 'src/components/animate';
import { seedballStatusFalse, setAssetQuantityToInitialState, setHarvestBoxStatusFalse, sproutedFalse } from 'src/redux/slices/seedball';
import TopDataCards from '../../components/seedballgame/TopDataCards';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getContainerAssets } from 'src/redux/slices/container';
import { MHidden } from 'src/components/@material-extend';
import InfoDialog from '../InfoDialog';
import { nurtureinfo, nurtureInfoTitleText } from './nurtureinfo';
import { useTheme } from '@emotion/react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { setRepairStatusFalse } from 'src/redux/slices/repair';
import Page500 from '../Page500';
import FooterBanner from '../FooterBanner';
// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/container-background.jpg)',
  padding: theme.spacing(10, 0),
  height: '85%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0)
  },
  [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0)
  },
  [theme.breakpoints.down('md')]: {
    height: '92%',
    backgroundImage: 'url(/static/seedball/mobile_seedball_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));

export default function ContainerLayout({ual}) {
  const dispatch = useDispatch();
  const UserState = useSelector((store) => store.user.userDetails);
  const containers = useSelector((store) => store.container.containerAssets);
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const navigateToInventory = () => {
    navigate({pathname:PATH_DASHBOARD.general.inventory, search:'?type=container'});
  }
  const showErrorPage = useSelector(state=> state.loader.isError);
  const sideBarStatus = useSelector((store) => store.seedball.sideBarStatus);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showError, setshowError] = useState(false);
  useEffect(()=>{
    dispatch(seedballStatusFalse());
    dispatch(sproutedFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(setHarvestBoxStatusFalse());
    dispatch(setRepairStatusFalse());
    dispatch(getContainerAssets(UserState.name, UserState.session));
  },[dispatch, UserState.session, UserState.name]);

  useEffect(()=>{
    setshowError(showErrorPage);
  },[showErrorPage]);

  useLayoutEffect(() => {
    let sortedData = containers.slice().sort((a, b) => {
      if (a.statusOrder === b.statusOrder) {
        return b.percent - a.percent;
     }
     return a.statusOrder > b.statusOrder ? 1 : -1;
    });
      if(sortedData.length > 5) {
        let newArray = sortedData.slice(0,5);
        const updatedArray = [newArray[3], newArray[1], newArray[0], newArray[2],newArray[4]];
        setAssets(updatedArray);
      } else {
        if(sortedData.length === 3){
          const updatedArray = [sortedData[1], sortedData[0], sortedData[2]];
          setAssets(updatedArray);
        } else if(sortedData.length === 4){
          const updatedArray = [sortedData[2], sortedData[0], sortedData[1], sortedData[3]];
          setAssets(updatedArray);
        }else if(sortedData.length === 5){
          const updatedArray = [sortedData[3], sortedData[1], sortedData[0], sortedData[2],sortedData[4]];
        setAssets(updatedArray);
        } else{
          setAssets(sortedData);
        }
      }
  },[containers])

  const showBanner = (flag) => {
    let rootStyle = document.getElementById('root-style');
    if(rootStyle) 
      rootStyle.style.height = !flag ? '100%' : isMobile ? '92%' : '85%';
  };


  return (
   <>
  {!showError &&<RootStyle id="root-style" initial="initial" animate="animate" variants={varWrapEnter}>
  <Page title="Container" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      <Container sx={{marginBottom:"30px"}}>
      <Box sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        top: isMobile ? '-15px' : '30px',
        left: isMobile ? '10px' : (sideBarStatus.toggle && sideBarStatus.hover ? '200px' : '97px')}}>
          <Button onClick={()=>{dispatch(getContainerAssets(UserState.name, UserState.session))}} variant="contained">
            <RefreshIcon/>
          </Button>
      </Box>
        <Typography gutterBottom color='primary.main' variant="h3" sx={{ textAlign: 'center' , mx: 'auto' }}>
          Nurture
          <InfoDialog title="Nurture" message={nurtureinfo} titleText={nurtureInfoTitleText}/>
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto'}}>
          Add your container to start the game.
        </Typography>
        <MHidden width="mdDown">
        {assets.length > 0 && <Box sx={{textAlign: 'center', margin:'30px 0 40px 0'}}>
            <Button color="warning" sx={{borderRadius: '30px'}} variant="contained" target="_blank" href={`https://neftyblocks.com/c/theiconical1/`}>
                Buy Container
            </Button>
          </Box> }  
        </MHidden>
        <MHidden width="mdDown">
          {assets.length === 0 && 
          <Box sx={{textAlign: 'center', mt: 5}}>
            <Button color="warning" variant="contained" target="_blank" href={`https://neftyblocks.com/c/theiconical1/`}>
                Buy Container
            </Button>
          </Box> }  
        </MHidden>
        <MHidden width="mdUp">
          <Box sx={{textAlign: 'center', mt: 5}}>
            <Button color="warning" variant="contained" target="_blank" href={`https://neftyblocks.com/c/theiconical1/`}>
                Buy Container
            </Button>
          </Box>   
        </MHidden>
        <TopDataCards assets={assets}/>
        {containers.length > 5 && 
        <Box sx={{margin:'30px 0 40px 0'}} pr={{xs:0, sm:0, md:12}} textAlign={{xs:"center",sm:"center",md:"right"}}>
          <Button color="inherit" variant="outlined" onClick={navigateToInventory} >
              View all container
          </Button>
        </Box> }
      </Container>
    </Page>
  </RootStyle>}
  {!showError && <FooterBanner showData={showBanner}/>}
  {showError && <Page500 ual={ual}/>}
  </>
  );
}
