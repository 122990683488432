import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { varWrapEnter } from 'src/components/animate';
import Logo from 'src/components/Logo';
import { setPlayerData, setPlayerLogout } from 'src/redux/slices/user';
import { useDispatch } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
    backgroundImage: 'url(/static/seedball/projectseedball-splashscreen.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    padding: theme.spacing(10, 0),
    height: '100%',
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: 0
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0)
    },
    [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 0)
    }
  }));

// ----------------------------------------------------------------------

export default function SeedballSplashScreen({ual}) {
    const {activeUser} = ual;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [endPoint, setEndPoint] = React.useState('https://wax.cryptolions.io');
    // localStorage.setItem('eosioUrl','https://wax.cryptolions.io' );
    const handleLogin = async() => {
        if(!activeUser){
         ual.showModal();
        } else if(activeUser && (!activeUser.api || !activeUser.rpc)){
          ual.showModal();
        }
    }

    const handleChange = (event) => {
      setEndPoint(event.target.value);
      localStorage.setItem('eosioUrl',event.target.value);
    };

    useEffect(()=>{
      localStorage.setItem('eosioUrl','https://wax.cryptolions.io');
        async function fetchData(){
          if(activeUser && activeUser.accountName){
            await dispatch(setPlayerData(ual));
            const pathname = localStorage.getItem('pathname');
            navigate(pathname || `${PATH_DASHBOARD.general.seedball}`);
          } else {
            dispatch(setPlayerLogout(ual));
          }
        }
        fetchData();
      },[dispatch,navigate,ual,activeUser])
    return(
        <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
          <Box sx={{
        mt: 2,
        position: 'absolute',
        ml: 2
       }}>
          <Button sx={{backgroundColor:'#888888', color:'black'}} href="https://www.projectseedball.io" variant="contained">
              Home
          </Button>
      </Box>
            <Container
        maxWidth="lg"
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%'
        }}>
            <Logo sx={{ width: 200, height: 100 }}/>
            <Typography variant="body1">
                Powered by
            </Typography>
            <Box component="a" href="https://www.theiconical.com" target="_blank"  sx={{ width: 100, my:2}}>
                <img sx={{height: '100%'}} src="/static/seedball/theiconical-logo.png" alt="Seedball logo"/>
            </Box>
            {/* <FormControl sx={{width: '200px', mb:5}}>
              <InputLabel id="demo-simple-select-label">Select RPC Endpoint</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={endPoint}
                label="Select RPC Endpoint"
                onChange={handleChange}
                defaultValue={'https://wax.cryptolions.io'}
              >
                <MenuItem value={'https://wax.cryptolions.io'}>https://wax.cryptolions.io</MenuItem>
                <MenuItem value={'https://api.wax.alohaeos.com'}>https://api.wax.alohaeos.com</MenuItem>
                <MenuItem value={'https://api.waxsweden.org'}>https://api.waxsweden.org</MenuItem>
                <MenuItem value={'https://wax.pink.gg'}>https://wax.pink.gg</MenuItem>
                <MenuItem value={'https://wax.dapplica.io'}>https://wax.dapplica.io</MenuItem>
                <MenuItem value={'https://wax.eosphere.io'}>https://wax.eosphere.io</MenuItem>
                <MenuItem value={'https://api.wax.greeneosio.com'}>https://api.wax.greeneosio.com</MenuItem>
                <MenuItem value={'https://wax.eu.eosamsterdam.net'}>https://wax.eu.eosamsterdam.net</MenuItem>
                <MenuItem value={'https://api.wax.bountyblok.io'}>https://api.wax.bountyblok.io</MenuItem>
              </Select>
            </FormControl> */}
        <Button onClick={handleLogin} sx={{width: '200px', mb:5}} variant="contained">
          Login
        </Button>
        </Container>
        </RootStyle>
    )
}
