import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, Typography, Button } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import { MHidden, MIconButton } from '../../components/@material-extend';
import MenuMobile from '../main/MenuMobile';
import navConfig from '../main/GameMenuConfig';
import { useDispatch, useSelector } from 'src/redux/store';
import { getBalanceFromEOSIO } from 'src/redux/slices/user';
import { useEffect } from 'react';
import MyAvatar from 'src/components/MyAvatar';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar({ual}) {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/seedball/container';
  const UserState = useSelector((store) => store.user.userDetails);
  const balance = useSelector((store) => store.user.balance);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getBalanceFromEOSIO(UserState.session));
  },[dispatch, UserState.session])
  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent', opacity:0.7 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: "20%"
          }}
        >
          <MHidden width="lgUp">
            <MenuMobile isOffset={isOffset} ual={ual} isHome={isHome} navConfig={navConfig} />
          </MHidden>
          
          
          {/* <MHidden width="mdDown">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden> */}
        </Container>
        
        <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: "80%"
        }}>
        {/* <AccountPopover /> */}
        <Box sx={{textAlign: 'center', margin:'30px 20px 40px 0', float: 'right'}}>
            <Button color="warning" sx={{borderRadius: '30px'}} variant="contained" target="_blank" href={`https://neftyblocks.com/c/theiconical1/`}>
            <MHidden width="lgUp">
            <ShoppingCartOutlined/>
            </MHidden>
            <MHidden width="lgDown">
            Buy Container
            </MHidden>
            </Button>
          </Box>
        <MIconButton
        onClick={()=>{
          dispatch(getBalanceFromEOSIO(UserState.session))
        }}
        sx={{
          padding: 0,
          width: 44,
          height: 44
        }}
      >
        
        <MyAvatar/>
      </MIconButton>
          <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {UserState.name}
          </Typography>
          <Typography variant="body2"  sx={{ color: 'primary.main' }}>
            {balance.iconi}
          </Typography>
          </Box>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
