import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import { Backdrop, Dialog, Typography, DialogContent, useMediaQuery } from '@mui/material';
import { useSelector } from 'src/redux/store';
import React, { useEffect, useState } from 'react';
import DashboardSidebar from './DashboardSidebar';
import { Player } from '@lottiefiles/react-lottie-player';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: APP_BAR_DESKTOP
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ual}) {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const isLoading = useSelector(state=> state.loader.isLoading);
  const isLongLoading = useSelector(state=> state.loader.isLongLoading);
  const [showLoader, setShowLoader] = useState(false);
  const [showLongLoader, setShowLongLoader] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(()=>{
    setShowLoader(isLoading);
    setShowLongLoader(isLongLoading);
  }, [isLoading, isLongLoading])
  return (
    <RootStyle>
      <DashboardNavbar ual={ual}/>
      <DashboardSidebar ual={ual}/>
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
        {showLongLoader && 
        <Dialog
        disablebackdropclick
        open={showLongLoader}>
          <DialogContent sx={{textAlign:'center', width:isMobile ? 'auto' : '400px', padding:'24px 0'}}>
            <Typography variant='h3'>
              Please Wait...
            </Typography>
            <div style={{margin:'10px 0'}}>
              <Player
                autoplay
                loop
                src="/static/seedball/loader.json"
                style={{ height: '25%', width: '25%'}}
              >
              </Player>
            </div>
            <Typography varient="string">
              Please do not refresh or close this page.
            </Typography>
            <Typography varient="string">
              This may take some time to complete.
            </Typography>
          </DialogContent>
        </Dialog>
        }

        {!showLongLoader && showLoader && 
          // <Backdrop open={showLoader} sx={{ zIndex: 9999 }}>
          //   <CircularProgress />
          // </Backdrop>
          <Backdrop open={showLoader} sx={{ zIndex: 9999 }}>
          <Player
              autoplay
              loop
              src="/static/seedball/loader.json"
              style={{ height: '15%', width: '15%'}}
            >
          </Player>
          </Backdrop>
        }
      </MainStyle>
    </RootStyle>
  );
}
