import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { ShopFilterSidebar, ShopProductList } from "src/components/_dashboard/e-commerce/shop";
import { filterAllResources } from "src/redux/slices/seedball";
import { getContainerAssets } from "src/redux/slices/container";

function applyFilter(resources, filters) {
  let containerResources;
  if(filters.status && filters.container) {
    if(filters.status.length > 0 && filters.container.length === 0) {
      containerResources = resources.filter(obj => (obj.category === 'Container' && filters.status.includes(obj.status)));
    } else if(filters.status.length === 0 && filters.container.length > 0){
      containerResources = (filters.container.includes('All')? resources.filter(obj => obj.category === 'Container'):
      resources.filter(obj => obj.category === 'Container' && filters.container.includes(obj.subCategory)));
    } else if(filters.status.length > 0 && filters.container.length > 0){
      containerResources = (filters.container.includes('All')? resources.filter(obj => obj.category === 'Container' && filters.status.includes(obj.status)):
      resources.filter(obj => obj.category === 'Container' && filters.container.includes(obj.subCategory) && filters.status.includes(obj.status)));
    } else {
      containerResources = resources.filter(obj => obj.category === 'Container');
    }
  } else {
    containerResources = [];
  }
  return containerResources.length > 0 ? [...containerResources] : [];
}

export default function ContainerTab() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [containerFilters, setContainerFilters] = React.useState({ container: [], status: [] });
  const filters = useSelector((state) => state.seedball.filters);
  const containers = useSelector((store) => store.container.containerAssets);
  const UserState = useSelector((store) => store.user.userDetails);
  const filteredProducts = applyFilter([...containers], containerFilters);
  const formik = useFormik({
    initialValues: {
      container: [],
      status: []
    },
    onSubmit: () => {
      
    }
  });

  const { values, resetForm, initialValues } = formik;

  React.useEffect(() => {
    dispatch(getContainerAssets(UserState.name, UserState.session));
    setContainerFilters(filters);
  }, [dispatch, UserState.name, UserState.session, filters]);

  React.useEffect(() => {
    dispatch(filterAllResources(values));
  }, [dispatch, values]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    resetForm();
  };

  return (
    <>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ShopFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
        </Stack>
      </Stack>
      <ShopProductList products={filteredProducts} isLoad={!filteredProducts && !initialValues} />
    </>
  )
}
