// material
import { Container, Grid, Button, Typography, Box, useMediaQuery, Card, Divider, Dialog, Slide, alpha } from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'src/redux/store';
import React, { useEffect, useState } from 'react';
import { getSimpleAssets, seedballStatusFalse, setAssetQuantityToInitialState, setHarvestBoxStatusFalse, sproutedFalse } from 'src/redux/slices/seedball';
import { useNavigate, useParams } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { MotionInView, varWrapEnter, varZoomIn, varFadeInDown } from 'src/components/animate';
import { useSnackbar } from 'notistack';
import InfoDialog from '../InfoDialog';
import { useTheme } from '@emotion/react';
import SeedballFTCard from '../seedball/SeedballFTCard';
import { getContainerAssets, getContainerAssetsEosio } from 'src/redux/slices/container';
import { claimRepairEosio, getPaymentsDataFromTable, sendAtomicAssets, sendMoney } from 'src/redux/slices/repair';
import { Player } from '@lottiefiles/react-lottie-player';
import { hideLoader, hideLongLoader, showLoader, showLongLoader } from 'src/redux/slices/loader';
import { repairinfo, repairinfoTitleText } from './repaitInfo';
import { getBalanceFromEOSIO } from 'src/redux/slices/user';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/repair-backgorund.jpg)',
  padding: theme.spacing(10, 0),
  height: '100%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0)
  },
  [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0)
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/static/seedball/mobile_repair_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function StartRepair({ual}) {
  const dispatch = useDispatch();
  

  const UserState = useSelector((store) => store.user.userDetails);
  
  const [isSeedballCreated, setIsSeedballCreated] = useState(false);
  const [hasUnpacked, setHasUnpacked] = useState(false);
  const [receivedContainers, setReceivedContainers] = useState([]);
  const [enableRepairButton, setEnableRepairButton] = useState(true);
  const [showPayButton, setShowPayButton] = useState(true);
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  // const assetQuantity = useSelector((store) => store.seedball.assetQuantity);
  const theme = useTheme();
  const {id} = useParams();
  const repairDetails = useSelector((store) => store.repair.repairDealsById[id]);
  const [repairCards, setRepairCards] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const sideBarStatus = useSelector((store) => store.seedball.sideBarStatus);
  const containers = useSelector((store)=> store.container.containerAssets);
  const balance = useSelector((store)=> store.user.balance);

  const payIconi = async() => {
    try {
      dispatch(showLoader());
      await sendMoney(UserState.session, UserState.name, repairDetails.iconi_price, repairDetails.id);
      await new Promise(resolve => setTimeout(resolve, 3000));
      await dispatch(getBalanceFromEOSIO(UserState.session));
      enqueueSnackbar(`Congratulations, we have received the payment successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      setShowPayButton(false);
      await dispatch(hideLoader());
    } catch (error) {
      await dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }
  
  const repairSeedball = async () => {
    try {
      const creatorAsset = containers.filter(obj=>obj.name === 'Creator' && obj.status === 'Halted').splice(0, repairDetails.creator_count);
      const producerAsset = containers.filter(obj=>obj.name === 'Producer' && obj.status === 'Halted').splice(0, repairDetails.producer_count);
      const masterAsset = containers.filter(obj=>obj.name === 'Master' && obj.status === 'Halted').splice(0, repairDetails.master_count);
      const assetIds = [...creatorAsset, ...producerAsset, ...masterAsset].map(obj=>obj.assetId);
      dispatch(showLoader());
      const filterPayments = await getPaymentsDataFromTable(UserState.name, UserState.session);
      const initialPayment = filterPayments.filter(obj=>obj.asset_ids.length === 0);
      const unboxId = Math.max.apply(Math, initialPayment.map((obj)=> obj.id));
      await sendAtomicAssets(UserState.session, UserState.name, assetIds, unboxId);
      setShowPayButton(true);
      await dispatch(hideLoader());
      enqueueSnackbar(`Congratulations, you have recycled your container successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      setIsSeedballCreated(true);
    } catch (error) {
      await dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }
  useEffect(()=>{
    
    dispatch(getSimpleAssets(UserState.session));
    dispatch(seedballStatusFalse());
    dispatch(sproutedFalse());
    dispatch(setHarvestBoxStatusFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(getContainerAssets(UserState.name, UserState.session));
  },[]);
  

  useEffect(()=>{
      let cards = [];
      if(repairDetails.creator_count > 0){
        cards.push({name:'Creator', code: null, type: 'Repair', value: 'CREATOR', quantity: repairDetails.creator_count, image: '/static/seedball/creator-test-graphics.jpg', iconi: 0, isSelected: false})
      }
      if(repairDetails.producer_count > 0){
        cards.push({name:'Producer', code: null, type: 'Repair', value: 'PRODUCER', quantity: repairDetails.producer_count, image: '/static/seedball/producer-test-graphics.jpg', iconi: 0, isSelected: false})
      }
      if(repairDetails.master_count > 0){
        cards.push({name:'Master', code: null, type: 'Repair', value: 'MASTER', quantity: repairDetails.master_count, image: '/static/seedball/master-test-graphics.jpg', iconi: 0, isSelected: false})
      }
      if(repairDetails.iconi_price){
        cards.push({name:repairDetails.iconi_price, code: null, type: 'Repair', value: 'ICONI', quantity: '1', image: '/static/seedball/care-sprout.png', iconi: 0, isSelected: false})
      }
      setRepairCards(cards);
      const creatorHalted = containers.filter(obj=> obj.name.toLowerCase() === 'creator' && obj.status.toLowerCase() === 'halted');
      const producerHalted = containers.filter(obj=> obj.name.toLowerCase() === 'producer' && obj.status.toLowerCase() === 'halted');
      const masterHalted = containers.filter(obj=> obj.name.toLowerCase() === 'master' && obj.status.toLowerCase() === 'halted');
      if(repairDetails.creator_count <= creatorHalted.length && 
        repairDetails.producer_count <= producerHalted.length &&
        repairDetails.master_count <= masterHalted.length && 
        +balance.iconi.split(' ICONI')[0] >= +repairDetails.iconi_price.split(' ICONI')[0]){
          setEnableRepairButton(false);
        } else{
          setEnableRepairButton(true);
        }
    
  },[repairDetails, containers, balance]);

  const getLatestContainer = async(containerAssetId, count) => {
    console.log('Count', count);
    if(count>=5 ) return [];
  const containerList = await getContainerAssetsEosio(UserState.name, UserState.session);
  console.log('Container Asset ID',containerAssetId, 'Top value', containerList[0].assetId)
  if(containerList.length === 0){
    let newCount = count + 1;
    await new Promise(resolve => setTimeout(resolve, 10000));
   return getLatestContainer(containerAssetId, newCount);
  }else if(containerList[0].assetId === containerAssetId){
      let newCount = count + 1;
      await new Promise(resolve => setTimeout(resolve, 10000));
     return getLatestContainer(containerAssetId, newCount);
  } else{
    console.log('Containers list in getLatestContainer', containerList);
      return containerList;
  }
}

  const unpack = async () => {
    try {
      dispatch(showLongLoader());
      const filterPayments = await getPaymentsDataFromTable(UserState.name, UserState.session);
      console.log('Filter payments',filterPayments);
      const containerList = await getContainerAssetsEosio(UserState.name, UserState.session);
      containerList.length > 0 ? localStorage.setItem('containerAssetId', containerList[0].assetId) : localStorage.setItem('containerAssetId', null);
      if(filterPayments.length === 0){
        enqueueSnackbar(`We are processing the data, kindly wait or go to inventory to unbox it.`, { variant: 'warning', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
      } else{
        const unboxId = Math.max.apply(Math, filterPayments.map((obj)=> obj.id));
        await claimRepairEosio(UserState.session, UserState.name, unboxId);
        const containerAssetId = localStorage.getItem('containerAssetId');
        const repairedContainerList = await getLatestContainer(containerAssetId, 0);
        console.log('Data---',containerAssetId, repairedContainerList);
        const contArray = ([...repairedContainerList]).splice(0, repairDetails.slots[0].value);
        console.log('Container Array',contArray);
        if(contArray && contArray.length > 0){
          
          setReceivedContainers(contArray);
          
          enqueueSnackbar(`Congratulations, you have unpacked the containers successfully.`, { variant: 'success', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          } });
        } else {
          console.log('Repair Array',repairedContainerList);
          enqueueSnackbar(`It is taking more time than expected, kindly go to Inventory to unbox after some time.`, { variant: 'warning', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          } });
        }
        setHasUnpacked(true);
        setIsSeedballCreated(false);
        dispatch(hideLongLoader());
        
      }
      
    } catch (error) {
      dispatch(hideLongLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  const navigateToContainer = (assetId) => {
    navigate(`${PATH_DASHBOARD.general.container}/${assetId}`);
  }

  const navigateToRepair = () => {
    navigate(`${PATH_DASHBOARD.general.repair}`);
  }

  const updateSeedballImage = (obj) => {
    const elements = document.querySelectorAll('[id^="image-selected"]');
    if((elements.length + 1) === (repairCards.length - 1)){
      setEnableRepairButton(false);
    }
  }

  return (
    <>
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
    <Page title="Seedball" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      {!isSeedballCreated && <Box sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        top: isMobile ? '-50px' : '30px',
        left: isMobile ? '10px' : (sideBarStatus.toggle && sideBarStatus.hover ? '200px' : '97px')}}>
          <Button variant="contained" onClick={navigateToRepair}>
              Back
          </Button>
      </Box>}
      {!isSeedballCreated && !hasUnpacked && 
      <Container>
        <Typography color='primary.main' gutterBottom variant="h3" sx={{ textAlign: 'center' , mx: 'auto' }}>
                  Recycle Deal {id}
                  <InfoDialog title="Recycle Center" message={repairinfo} titleText={repairinfoTitleText}/>
        </Typography>
        {repairDetails.slots && repairDetails.slots[0] && repairDetails.slots[0].value && 
        <Typography variant="h5" sx={{ textAlign: 'center', mx: 'auto', mb: 3 }}>
          Get {repairDetails.slots[0].value} container(s)
        </Typography>}
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 3 }}>
                  Buttons will be enabled once you have all the resources available.
        </Typography>
        <Grid container spacing={3} px={{xs:9, sm:14, md:0}} sx={{justifyContent: 'center'}}>
           {repairCards.map((data, index) => (
            <Grid key={index} item xs={12} sm={6} md={2}>
              <motion.div variants={varFadeInDown}>
                <SeedballFTCard container={data}  updateSeedballImage={updateSeedballImage} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
        
        <Box sx={{textAlign: 'center', mt: 5}}>
          <Button sx={{mr: '10px'}} disabled={enableRepairButton || !showPayButton} color="warning" onClick={payIconi} variant="contained">
            Pay ICONI
          </Button>
          {/* <Button variant="contained" disabled={showPayButton} onClick={repairSeedball}>
          Recycle
          </Button> */}
        </Box>
        
      </Container>}
      <Dialog
        disablebackdropclick="true"
        open={isSeedballCreated}
        TransitionComponent={Transition}>
          <Container sx={{margin: '30px 0'}}>
          <Player
            autoplay
            loop
            src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
            style={{ height: '90%', width: '90%', position:'absolute', zIndex:'9' }}
          >
          </Player>
          <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
              Congratulations! You received new containers.
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
          Unpack the box to receive the containers.
          </Typography>
            
          <MotionInView variants={varZoomIn}>
            <Box
              component="img"
              src="/static/seedball/rapairbox.png"
              sx={{
                width: '50%',
                borderRadius: '10px',
                display: 'block',
                margin: 'auto',
                filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
              }}
            />
          </MotionInView>
          <Box sx={{textAlign: 'center', mt: 5}}>
            <Button variant="contained" onClick={unpack} sx={{zIndex:'99'}}>
                Unpack
            </Button>
          </Box>
        </Container>
      </Dialog>
      {hasUnpacked && receivedContainers.length> 0 &&
        <Container>
          <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
              style={{ height: '75%', width: '75%', position:'absolute', right:'0', left:"0", zIndex:'0' }}
            >
            </Player>
          <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
              Congratulations! You received below containers.
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
              Go to containers page to start them.
          </Typography>

          <Grid container spacing={3} px={{xs:9, sm:14, md:0}} sx={{justifyContent: 'center'}}>
           {receivedContainers.map((data, index) => (
            <Grid sx={{
              justifyContent: 'flex-start',
              padding: receivedContainers.length === 5 ? 'auto !important' : "0 !important",
              margin:receivedContainers.length === 5 ? '0 !important' : '24px',
              }}
              flexBasis={{xs:'auto', sm:'auto', md:'19% !important'}}
              maxWidth={{xs:'auto', sm:'auto', md:'19% !important'}}
              minWidth={{xs:'auto', sm:'auto', md:'175px !important'}}
              key={index} item xs={12} sm={6} md={6}>
              <MotionInView variants={varZoomIn}>
                <Card>
                  <Box
                    component="img"
                    src={data.image}
                    sx={{
                      width: 250,
                      borderRadius: '2px',
                      display: 'block',
                      margin: 'auto',
                      filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
                    }}
                  />
                  <Divider/>
                  <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', my: 1 }}>
                    ID: {data.assetId}
                  </Typography>
                  <Box sx={{textAlign: 'center', my: 2}}>
                    <Button variant="contained" onClick={()=>{navigateToContainer(data.assetId)}} sx={{zIndex:'99'}}>
                        Claim
                    </Button>
                  </Box>
                </Card>
              
          </MotionInView>
            </Grid>
          ))}
        </Grid>
          
        </Container>}
    </Page>
    </RootStyle>
    <Dialog
        disablebackdropclick="true"
        open={!showPayButton}
        TransitionComponent={Transition}>
          <Container sx={{margin: '30px 0'}}>
            <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
              style={{ height: '90%', width: '90%', position:'absolute', zIndex:'9' }}
            >
            </Player>
            <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
              Congratulations! We have received your payment successfully.
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
              Click on the Recycle button to get the latest containers
          </Typography>
          <Box sx={{textAlign: 'center', mt: 5}}>
          <Button variant="contained" onClick={repairSeedball} sx={{zIndex:'99'}}>
            Recycle
          </Button>
          </Box>
          </Container>
      </Dialog>
    </>
  );
}
