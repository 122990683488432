// material
import { Button, Container, Grid, Typography, useMediaQuery, Stack, LinearProgress, Box, Slide, Dialog, IconButton, DialogTitle} from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { getSimpleAssets, seedballStatusFalse, setAssetQuantity, setHarvestBoxStatusFalse } from 'src/redux/slices/seedball';

import { useNavigate, useParams } from 'react-router';
import { MotionInView, varWrapEnter, varZoomIn } from 'src/components/animate';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'src/redux/store';
import SeedBallContainerList from '../seedball/SeedBallContainerList';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getContainerAssets, sproutContainer, startContainer } from 'src/redux/slices/container';
import { useSnackbar } from 'notistack';
import InfoDialog from '../InfoDialog';
import RefreshIcon from '@mui/icons-material/Refresh';
import { setRepairStatusFalse } from 'src/redux/slices/repair';
import { containerNurtureInfo } from './containerNurtureInfo';
import Page500 from '../Page500';
import { hideLoader, showLoader } from 'src/redux/slices/loader';
import BoostDrawer from '../BoostDrawer';
import { getBoostDetails, getContainerBoosts } from 'src/redux/slices/boost';
import CloseIcon from '@mui/icons-material/Close';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/container-background.jpg)',
  padding: theme.spacing(10, 0),
  height: '100%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/static/seedball/mobile_seedball_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));

const SeedballStyle = styled('img')(({ theme }) => ({
    cursor: 'pointer',
    margin: '0 auto 0 auto',
    [theme.breakpoints.up('xs')]: {
        width: '150px'
    },
    [theme.breakpoints.up('md')]: {
        width: '180px'
    },
    [theme.breakpoints.up('lg')]: {
        width: '200px'
    }
  }));

  const SeedballNoClickStyle = styled('img')(({ theme }) => ({
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    margin: '0 auto 0 auto',
    [theme.breakpoints.up('xs')]: {
        width: '150px'
    },
    [theme.breakpoints.up('md')]: {
        width: '180px'
    },
    [theme.breakpoints.up('lg')]: {
        width: '200px'
    }
  }));

const ImageStyle = styled('img')(({ theme }) => ({
    cursor: 'pointer',
    margin: '0 auto 0 auto',
    [theme.breakpoints.up('xs')]: {
        width: '100px'
    },
    [theme.breakpoints.up('md')]: {
        width: '130px'
    },
    [theme.breakpoints.up('lg')]: {
        width: '150px'
    }
  }));

  const ImageNoClickStyle = styled('img')(({ theme }) => ({
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    margin: '0 auto 0 auto',
    [theme.breakpoints.up('xs')]: {
        width: '100px'
    },
    [theme.breakpoints.up('md')]: {
        width: '130px'
    },
    [theme.breakpoints.up('lg')]: {
        width: '150px'
    }
  }));

  const ContainerStyle = styled(Container)(({ theme }) => ({
    position: 'relative',
    margin: '0 auto 0 auto',
    
    backgroundImage: `url(/static/seedball/container-connection.png)`,
    
    [theme.breakpoints.up('xs')]: {
        width: '100% !important',
        backgroundSize: 'cover',
    },
    [theme.breakpoints.up('md')]: {
        width: '75% !important',
        backgroundSize: 'cover',
    },
    [theme.breakpoints.up('lg')]: {
        width: '60% !important',
        backgroundSize: 'cover',
    },
    [theme.breakpoints.up('xl')]: {
        width: '45% !important',
        backgroundSize: 'cover',
    }
  }));

  const DialogImageStyle = styled("img")(({ theme }) => ({
    margin: 'auto'
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ContainerNurture({ual}) {
  const dispatch = useDispatch();
  const UserState = useSelector((store) => store.user.userDetails);
  const theme = useTheme();
  const {assetId} = useParams();
  const containerDetailsById = useSelector((store) => store.container.containerAssetById);
  const [containerDetails, setContainerDetails] = useState(containerDetailsById[assetId]);
  const sideBarStatus = useSelector((store) => store.seedball.sideBarStatus);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [powerDetails, setPowerDetails] = useState({});
  const containerSprouted = useSelector((state) => state.seedball.isSprouted);
  const simpleAssets = useSelector((state) => state.seedball.simpleAssets);
  const assetQuantity = useSelector((state) => state.seedball.assetQuantity);
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const showErrorPage = useSelector(state=> state.loader.isError);
  const [showError, setshowError] = useState(false);
  const [boost, setBoost] = useState([]);
  const [openBoostDialog, setOpenBoostDialog] = useState(false);
  const [boostId, setBoostId] = useState(null);
  const [selectedBoost, setSelectedBoost] = useState({});
  const navigateToCare = () => {
    navigate(PATH_DASHBOARD.general.care);
  }
  const navigateToNurture = () => {
    navigate(PATH_DASHBOARD.general.nurture);
  }
  const powerData = {
    seedball:{name:'Seedball', code: '1014', type: 'Power', value: 'SEEDBAL', quantity: '1', image: '/static/seedball/seedball-container.png', iconi: 0, count: 0},
    nutrition: {name:'Nutrition', code: '1002', type: 'Power', value: 'NUTRI', image: '/static/seedball/power-compost.png', quantity: '1',  iconi: 5, count: 0},
    sun: {name:'Sun', code: '1001', type: 'Power', value: 'SUN', image: '/static/seedball/power-sun.png', quantity: '1', iconi: 5, count: 0},
    rain: {name:'Rain', code: '1003', type: 'Power', value: 'RAIN', image: '/static/seedball/power-rain.png', quantity: '1', iconi: 5, count: 0}
  }

  const [openBoostFilter, setBoostOpenFilter] = React.useState(false);

  const openDrawer = (type, index) => {
    setOpen(true);
    const data = {...powerData[type]};
    if(assetQuantity[data.value] === null){
      const filterAssets = simpleAssets.filter(obj => obj.author === process.env.REACT_APP_FT_SALE && obj.balance.includes(data.value));
      dispatch(setAssetQuantity(data.value, filterAssets.length > 0 ? filterAssets[0].balance.split(`${data.value}`)[0] : 0));
    }
    setPowerDetails({...data, index});
  }
  const handleCloseContainer = () =>{
    setOpen(false);
  }
  const updateImage = (url, name, index) => {
      const elements = document.querySelectorAll(`#img-${index}`);
      elements.forEach((el)=>{
        el.setAttribute('src', url);
        el.style.pointerEvents = 'none';
        el.style.cursor = 'default';
        el.setAttribute('id', `img-selected-${index}`);
      });
  }

  const getEstimatedTime = (name) => {
    if(name.toLowerCase() === 'propagator'){
      return  selectedBoost && selectedBoost.value ? 48 - 48 * selectedBoost.value /100 : 48 ;
    } else if(name.toLowerCase() === 'creator'){
      return selectedBoost && selectedBoost.value ? 48 - 48 * selectedBoost.value /100 : 48 ;
    } else if(name.toLowerCase() === 'producer'){
      return selectedBoost && selectedBoost.value ? 24 - 24 * selectedBoost.value /100 : 24 ;
    } else {
      return selectedBoost && selectedBoost.value ? 12 - 12 * selectedBoost.value /100 : 12 ;;
    }
    // const containerConfig = contractConfig.leveltodelay.find(obj=>obj.key === name)
    // return Math.floor(containerConfig.value / 3600);
  }

  const startContainerButton = async () => {
    try {
      dispatch(showLoader());
      await dispatch(startContainer(UserState.session, UserState.name, assetId, boostId));
      enqueueSnackbar(`Congratulations, you started container with asset id ${assetId} successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  const sproutContainerButton = async() => {
    try {
      dispatch(showLoader());
      await dispatch(sproutContainer(UserState.session, UserState.name, assetId));
      enqueueSnackbar(`Congratulations, your container with asset id ${assetId} has sprouted successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  const showButton = () => {
    const elements = document.querySelectorAll('[id^="img-selected"]');
    if(containerDetails.name === 'Propagator' && elements.length === 4) return true;
    if(containerDetails.name === 'Creator' && elements.length === 4) return true;
    if(containerDetails.name === 'Producer' && elements.length === 7) return true; 
    if(containerDetails.name === 'Master' && elements.length === 10) return true;  
  }

  const closeDialog = () => {
    setOpenBoostDialog(false);
  }
  useEffect(()=>{
    dispatch(seedballStatusFalse());
    dispatch(getSimpleAssets(UserState.session));
    dispatch(setHarvestBoxStatusFalse());
    dispatch(setRepairStatusFalse());
    dispatch(getContainerAssets(UserState.name, UserState.session));
  },[dispatch, UserState.name, UserState.session, containerSprouted]);

  useEffect(()=>{
    setContainerDetails(containerDetailsById[assetId]);
  },[containerDetailsById, assetId])

  useEffect(()=>{
    const fetchData = async()=>{
      const boosts = await getContainerBoosts(UserState.name, UserState.session);
      const boostDetails = await getBoostDetails('nurtureboost', UserState.name);
      setBoost(boosts);
      if(containerDetails.boostId){
        setBoostId(containerDetails.boostId);
        const detail = boostDetails.find(obj=> obj.asset_id === containerDetails.boostId);
        setSelectedBoost({value: detail.data.value, name: detail.name})
      }
      if(boosts.length > 0 && containerDetails.status === 'Available' && !containerSprouted) {
        setOpenBoostDialog(true)
      }
    }
    fetchData();
  },[containerDetails]);

  useEffect(()=>{
    setshowError(showErrorPage);
  },[showErrorPage]);

  const closeFlter = () => {
    setBoostOpenFilter(false)
  }

  const updateBoost = (data) => {
    setSelectedBoost(data);
    setBoostId(data.assetIds[0]);
  }

  return (
    <>
    {!showError && <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      
    {containerDetails && <Page title="Nurture" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      <Box sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        top: isMobile ? '-50px' : '30px',
        left: isMobile ? '10px' : (sideBarStatus.toggle && sideBarStatus.hover ? '200px' : '97px')}}>
          <Button variant="contained" onClick={navigateToNurture}>
              Back
          </Button>
          <Button onClick={()=>{dispatch(getContainerAssets(UserState.name, UserState.session))}} variant="contained" sx={{marginLeft:3}}>
            <RefreshIcon/>
          </Button>
      </Box>
      {!containerSprouted && containerDetails && ['Available'].includes(containerDetails.status) && <Box
      component="img"
      src={selectedBoost && selectedBoost.name?`/static/seedball/${selectedBoost.name.includes(50)?50:(selectedBoost.name.includes(25)?25:10)}-boost.png`: '/static/seedball/apply-boost.png'}
      onClick={()=>{setBoostOpenFilter(true)}}
      sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        width: '100px',
        cursor: 'pointer',
        top: isMobile ? '-50px' : '30px',
        right: isMobile ? '10px' : '97px'}}>
      </Box>}
      {!containerSprouted && containerDetails && ['In Progress'].includes(containerDetails.status) && (containerDetails.boostId !== null && containerDetails.boostId !== undefined && containerDetails.boostId !== 0) && <Box
      component="img"
      src={selectedBoost && selectedBoost.name?`/static/seedball/${selectedBoost.name.includes(50)?50:(selectedBoost.name.includes(25)?25:10)}-boost.png`: '/static/seedball/apply-boost.png'}
      sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        width: '100px',
        pointer: 'none',
        top: isMobile ? '-50px' : '30px',
        right: isMobile ? '10px' : '97px'}}>
      </Box>}
      <BoostDrawer isOpenFilter={openBoostFilter} onCloseFilter={closeFlter} boost={boost.filter(el=>+el.quantity > 0)} type="Nurture" selectedBoost={selectedBoost} updateBoost={updateBoost}></BoostDrawer>
      {!containerSprouted && <>

        <Typography color='primary.main' gutterBottom variant="h3" >
                {containerDetails.name} container
                {containerDetails.status !== 'Halted' &&
                <InfoDialog title="Nurture Conatiner" message={containerNurtureInfo}/>}
        </Typography>
        {['Halted','Sprout'].includes(containerDetails.status) && <Typography variant="body2" sx={{mb: 3}}>
                Asset ID: {assetId}
        </Typography>}
        {['Available','In Progress'].includes(containerDetails.status) && <Typography variant="body2" m={{xs:'0 30px 24px 30px', sm:3, md:3}} sx={{mb: 3, textAlign:'center'}}>
        The estimated process time is {getEstimatedTime(containerDetails.name)} hours from the start of the container (Asset ID: {assetId}).
        </Typography>}
        {containerDetails.status === 'Halted' && <Container sx={{textAlign: 'center'}}>
          <Typography>
          You have used the full life of this container!
          </Typography>
          <Typography>
		      When the lifetime of container has come to its end, it becomes "Halted" and can no longer be used to nurture Seedballs.
	        </Typography>
          <Typography>
          Bring them to the Recycle Center where our Mechanics can bring new life to them.
          </Typography>
          <Button onClick={() => {navigate(PATH_DASHBOARD.general.repair)}} sx={{mt: 2}} color="primary" variant="contained" >
            Recycle Center
          </Button>
        </Container> }
      {!isMobile && containerDetails.status === 'Available' && <ContainerStyle>
        <motion.div variants={varZoomIn}>
        <SeedballStyle onClick={()=>{openDrawer('seedball', 1)}} id="img-1" src='/static/seedball/seedball-default.png'  alt="default seedball"/>
        </motion.div>
        <Grid container direction="row" >
            {containerDetails.name === 'Producer' && <Grid item md sx={{marginTop: '-14%'}}>
              <motion.div variants={varZoomIn}>
                <ImageStyle id="img-2" src='/static/seedball/power-default.png' onClick={()=>{openDrawer('nutrition',2)}} alt="default seedball"/>
                <ImageStyle id="img-3" src='/static/seedball/power-default.png' onClick={()=>{openDrawer('rain',3)}}  alt="default seedball"/>
              </motion.div>
            </Grid>}
            {containerDetails.name === 'Master' && <Grid item md sx={{marginTop: '-14%'}}>
              <motion.div variants={varZoomIn}>
                <ImageNoClickStyle id="img-2" src='/static/seedball/power-default-placeholder.png'  alt="default seedball"/>
                <ImageNoClickStyle id="img-2" src='/static/seedball/power-default-placeholder.png'  alt="default seedball"/>
              </motion.div>
            </Grid>}
            <Grid item md sx={{marginTop: '-7%'}}>
              <motion.div variants={varZoomIn}>
                <ImageStyle id={containerDetails.name === 'Producer'?"img-4": "img-2"} onClick={()=>{containerDetails.name === 'Producer'?openDrawer('sun',4):openDrawer('nutrition',2)}} src='/static/seedball/power-default.png'  alt="default seedball"/>
                {!['Creator', 'Producer', 'Propagator'].includes(containerDetails.name) && <ImageNoClickStyle id="img-3" src='/static/seedball/power-default-placeholder.png'  alt="default seedball"/>}
              </motion.div>
            </Grid>
            <Grid item md >
              <motion.div variants={varZoomIn}>
              {containerDetails.name !== 'Producer' && <ImageStyle id="img-3" onClick={()=>{openDrawer('sun', 3)}} src='/static/seedball/power-default.png'  alt="default seedball"/>}
              </motion.div>
            </Grid>
            <Grid item md sx={{marginTop: '-7%'}}>
            <motion.div variants={varZoomIn}>
            {['Producer'].includes(containerDetails.name) &&<ImageNoClickStyle id="img-4" src='/static/seedball/power-default-placeholder.png' alt="default seedball"/>}
                {['Creator', 'Master', 'Propagator'].includes(containerDetails.name) &&<ImageStyle id="img-4" onClick={()=>{openDrawer(containerDetails.name === 'Producer'?'sun':'rain', 4)}} src='/static/seedball/power-default.png' alt="default seedball"/>}
                {['Master'].includes(containerDetails.name) &&<ImageNoClickStyle id="img-3" src='/static/seedball/power-default-placeholder.png'  alt="default seedball"/>}
              </motion.div>
            </Grid>
            {!['Creator', 'Propagator'].includes(containerDetails.name) && <Grid item md sx={{marginTop: '-14%'}}>
              <motion.div variants={varZoomIn}>
                <ImageNoClickStyle id={containerDetails.name === 'Producer'?"img-2":"img-4"}  src='/static/seedball/power-default-placeholder.png'  alt="default seedball"/>
                <ImageNoClickStyle  id={containerDetails.name === 'Producer'?"img-3":"img-4"}   src='/static/seedball/power-default-placeholder.png'  alt="default seedball"/>
              </motion.div>
            </Grid>}
        </Grid>
          
      </ContainerStyle>}
      {!isMobile && ['In Progress', 'Sprout'].includes(containerDetails.status) &&
        <ContainerStyle>
        <motion.div variants={varZoomIn}>
        <SeedballNoClickStyle src='/static/seedball/seedball-container.png'  alt="default seedball"/>
        </motion.div>
        <Grid container direction="row" >
            {!['Creator', 'Propagator'].includes(containerDetails.name) && <Grid item md sx={{marginTop: '-14%'}}>
              <motion.div variants={varZoomIn}>
                <ImageNoClickStyle src='/static/seedball/power-compost.png'  alt="default seedball"/>
                <ImageNoClickStyle src={containerDetails.name === 'Producer'? '/static/seedball/power-rain.png' : '/static/seedball/power-compost.png'}  alt="default seedball"/>
              </motion.div>
            </Grid>}
            <Grid item md sx={{marginTop: '-7%'}}>
              <motion.div variants={varZoomIn}>
                <ImageNoClickStyle src={containerDetails.name === 'Producer'? '/static/seedball/power-sun.png' : '/static/seedball/power-compost.png'}  alt="default seedball"/>
                {!['Creator', 'Producer', 'Propagator'].includes(containerDetails.name) && <ImageNoClickStyle id="img-5" src='/static/seedball/power-sun.png'  alt="default seedball"/>}
              </motion.div>
            </Grid>
            <Grid item md >
              <motion.div variants={varZoomIn}>
              {containerDetails.name !== 'Producer' && <ImageNoClickStyle id="img-6" src='/static/seedball/power-sun.png'  alt="default seedball"/>}
              </motion.div>
              {/* <motion.div variants={varBounceIn}> */}
              {/* <Box sx={{textAlign: 'center', mt:'20px'}}>
                <Button  variant="contained" size="large">Start</Button>
              </Box> */}
              {/* </motion.div> */}
            </Grid>
            <Grid item md sx={{marginTop: '-7%'}}>
            <motion.div variants={varZoomIn}>
                <ImageNoClickStyle src={containerDetails.name === 'Producer'? '/static/seedball/power-sun.png' : '/static/seedball/power-rain.png'}  alt="default seedball"/>
                {!['Creator', 'Producer', 'Propagator'].includes(containerDetails.name) &&<ImageNoClickStyle src='/static/seedball/power-sun.png'  alt="default seedball"/>}
              </motion.div>
            </Grid>
            {!['Creator', 'Propagator'].includes(containerDetails.name) && <Grid item md sx={{marginTop: '-14%'}}>
              <motion.div variants={varZoomIn}>
                <ImageNoClickStyle src={containerDetails.name === 'Producer'? '/static/seedball/power-compost.png' : '/static/seedball/power-rain.png'} alt="default seedball"/>
                <ImageNoClickStyle  src='/static/seedball/power-rain.png'  alt="default seedball"/>
              </motion.div>
            </Grid>}
        </Grid>
          
      </ContainerStyle>
      }
      {isMobile && containerDetails.status === 'Available' && <ContainerStyle>
        <motion.div variants={varZoomIn}>
        <SeedballStyle onClick={()=>{openDrawer('seedball', 1)}} id="img-1" src='/static/seedball/seedball-default.png'  alt="default seedball"/>
        </motion.div>
        
        <Grid container direction="row" justifyContent="center">
            <Grid item md>
            <motion.div variants={varZoomIn}>
                <ImageStyle src='/static/seedball/power-default.png' onClick={()=>{openDrawer('nutrition', 2)}} id="img-2"  alt="default seedball"/>
                {['Master', 'Producer'].includes(containerDetails.name) &&<ImageNoClickStyle src='/static/seedball/power-default-placeholder.png' id="img-2"  alt="default seedball"/>}
                {['Master'].includes(containerDetails.name) && <ImageNoClickStyle src='/static/seedball/power-default-placeholder.png' id="img-2"  alt="default seedball"/>}
                </motion.div>
            </Grid>
            <Grid item md>
            <motion.div variants={varZoomIn}>
                <ImageStyle src='/static/seedball/power-default.png' onClick={()=>{openDrawer('sun', 3)}} id="img-3"  alt="default seedball"/>
                {['Master', 'Producer'].includes(containerDetails.name) && <ImageNoClickStyle src='/static/seedball/power-default-placeholder.png' id="img-3"  alt="default seedball"/>}
                {['Master'].includes(containerDetails.name) &&<ImageNoClickStyle src='/static/seedball/power-default-placeholder.png' id="img-3"  alt="default seedball"/>}
                </motion.div>
            </Grid>
            <Grid item md>
            <motion.div variants={varZoomIn}>
                <ImageStyle src='/static/seedball/power-default.png' onClick={()=>{openDrawer('rain', 4)}} id="img-4" alt="default seedball"/>
                {['Master', 'Producer'].includes(containerDetails.name) && <ImageNoClickStyle src='/static/seedball/power-default-placeholder.png' id="img-4" alt="default seedball"/>}
                {['Master'].includes(containerDetails.name) &&<ImageNoClickStyle src='/static/seedball/power-default-placeholder.png' id="img-4" alt="default seedball"/>}
              </motion.div>
            </Grid>
        </Grid>
      </ContainerStyle>}
      {isMobile && ['In Progress', 'Sprout'].includes(containerDetails.status) && <ContainerStyle>
        <motion.div variants={varZoomIn}>
        <SeedballNoClickStyle src='/static/seedball/seedball-container.png'  alt="default seedball"/>
        </motion.div>
        
        <Grid container direction="row" justifyContent="center">
            <Grid item md>
            <motion.div variants={varZoomIn}>
                <ImageNoClickStyle src='/static/seedball/power-compost.png' alt="default seedball"/>
                {['Master', 'Producer'].includes(containerDetails.name) &&<ImageNoClickStyle src='/static/seedball/power-compost.png' alt="default seedball"/>}
                {['Master'].includes(containerDetails.name) && <ImageNoClickStyle src='/static/seedball/power-compost.png' alt="default seedball"/>}
                </motion.div>
            </Grid>
            <Grid item md>
            <motion.div variants={varZoomIn}>
                <ImageNoClickStyle src='/static/seedball/power-sun.png'  alt="default seedball"/>
                {['Master', 'Producer'].includes(containerDetails.name) && <ImageNoClickStyle src='/static/seedball/power-sun.png' alt="default seedball"/>}
                {['Master'].includes(containerDetails.name) &&<ImageNoClickStyle src='/static/seedball/power-sun.png'  alt="default seedball"/>}
                </motion.div>
            </Grid>
            <Grid item md>
            <motion.div variants={varZoomIn}>
                <ImageNoClickStyle src='/static/seedball/power-rain.png' alt="default seedball"/>
                {['Master', 'Producer'].includes(containerDetails.name) && <ImageNoClickStyle src='/static/seedball/power-rain.png' alt="default seedball"/>}
                {['Master'].includes(containerDetails.name) &&<ImageNoClickStyle src='/static/seedball/power-rain.png' alt="default seedball"/>}
              </motion.div>
            </Grid>
        </Grid>
      </ContainerStyle>}
      {showButton() && containerDetails.status === 'Available' && <motion.div variants={varZoomIn}>
          <Button color="primary" variant="contained" onClick={startContainerButton}>
                Start Container
          </Button>
        </motion.div>}
        {containerDetails.status === 'Sprout' && <motion.div variants={varZoomIn}>
          <Button color="primary" variant="contained" onClick={sproutContainerButton}>
                Sprout
          </Button>
        </motion.div>}
      {containerDetails.status === 'In Progress' && <Stack spacing={1} sx={{width: isMobile?'80%':'50%',pt:'5%', margin: '0 auto 0 auto'}}>
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
            {'Nurture progress'} (&nbsp;{(containerDetails.percent)}%&nbsp;)
          </Typography>
          {/* <Typography variant="subtitle2">8 days remaining</Typography> */}
          {/* <Typography variant="subtitle2">
            {fPercent(containerDetails.percent)}
          </Typography> */}
        </Stack>
        <LinearProgress
          variant="determinate"
          value={containerDetails.percent}
          sx={{height: '12px', backgroundImage: 'url("/static/seedball/progress-bar-nurutre.gif")', backgroundSize: "8px", backgroundRepeat: 'repeat-x', backgroundPosition: 'center'}}
          color={
            'primary'
          }
        />
    </Stack>}
    <SeedBallContainerList openContainerList={open} drawerDetails={powerDetails} updateImage={updateImage} onCloseContainer={handleCloseContainer} />
   </>}
   {containerSprouted && 
   <>
   <Container>
        <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
            Congratulations! You made one sprout.
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
            You are all ready to sprout.
        </Typography>
          
        <MotionInView variants={varZoomIn}>
          <Box
            component="img"
            src="/static/seedball/care-sprout.png"
            sx={{
              width: 200,
              borderRadius: '10px',
              display: 'block',
              margin: 'auto',
              filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
            }}
          />
        </MotionInView>
        <Box sx={{textAlign: 'center', mt: 5}}>
          <Button variant="contained" onClick={navigateToCare}>
              Care your Sprout
          </Button>
        </Box>
      </Container>
   </>
   }
   <Dialog
        disablebackdropclick="true"
        open={openBoostDialog}
        TransitionComponent={Transition}>
        
          <Container sx={{margin: '30px 0'}}>
          <IconButton
              edge="start"
              color="default"
              sx={{
                right: 5,
                top: 5,
                position: 'absolute'
              }}
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon/>
              </IconButton>
              <DialogImageStyle
            src="/static/seedball/nurture-boost.png"
            alt="nurture boost"
          ></DialogImageStyle>
            <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
                It's time to boost.
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
                You have container boosts in your inventory. Use the boost to nurture your seedball.
            </Typography>
            <Box sx={{mt: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Button sx={{mr: 2}} variant="contained" onClick={()=>{setBoostOpenFilter(true); closeDialog()}}>
                  Use Boost
              </Button>
              <Button  
              sx={{float: 'right',
                background: (theme) => theme.palette.grey[500],
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.grey[600],
                  borderColor: (theme) => theme.palette.grey[600],
                  boxShadow: 'none',
                },
                '&:active': {
                  boxShadow: 'none',
                  backgroundColor: (theme) => theme.palette.grey[600],
                  borderColor: (theme) => theme.palette.grey[600],
                },
                '&:focus': {
                  boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                }
              }}
              onClick={closeDialog} variant="contained">
                  Later
              </Button>
            </Box>
          </Container>
      </Dialog>
    </Page>}
    
    </RootStyle>}
    {showError && <Page500 ual={ual}/>}
    </>
  );
}
