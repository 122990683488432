import { createSlice } from "@reduxjs/toolkit";
import { applicationConfig } from '../../config';
import { hideLoader, hideLongLoader, showLoader, showLongLoader, showErrorPage, hideErrorPage } from "./loader";
import { getBalanceFromEOSIO } from "./user";
import * as eosjsAccountName from 'eosjs-account-name';
import { getBoostDetails } from "./boost";

function objFromArray(array, key = 'assetId') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
    isLoading: false,
    error: false,
    harvestAssets: [],
    harvestAssetById: {}
}

const slice = createSlice({
    name: 'harvest',
    initialState,
    reducers: {
       // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }, 

        setHarvestAssets(state, action) {
            state.isLoading = false;
            state.harvestAssets = action.payload;
            state.harvestAssetById = objFromArray(action.payload, 'assetId');
        }
    }
})

export default slice.reducer;

 export const getHarvestData = async (username, session) => {
  const ualType = localStorage.getItem('UALLoggedInAuthType');
  const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
  return rpcEndpoint.get_table_rows({
    json: true,               // Get the response as json
    code: process.env.REACT_APP_GAME_CONTRACT,      // Contract that we target
    scope: process.env.REACT_APP_GAME_CONTRACT,         // Account that owns the data
    table: 'harvests',        // Table name
    limit: 1000,                // Maximum number of rows that we want to get
    key_type: 'i64',
    index_position: 2,
    lower_bound: eosjsAccountName.nameToUint64(username),
    upper_bound : eosjsAccountName.nameToUint64(username),         // Optional: Show ram payer
    }).then(res=> res.rows || [])
   .catch ((error)=> {
    console.log(`Error fetching harvests table data, err:`, error);
    throw error;
   });
}

const getEndTime = (newTime, boostDetails) => {
  if(boostDetails && boostDetails.data && boostDetails.data.value){
    const date = new Date(newTime * 1000);
    return date.getTime() + (24*60*60*1000 - 24*60*60*1000 * boostDetails.data.value/100);
  } else {
    const date = new Date(newTime * 1000);
    return date.getTime() + 24*60*60*1000;
  }
    
}

  const getPercent = (resourceDetails, remainingCount, totalCount, boostDetails) => {
    if(remainingCount === totalCount){
      return 0;
    } else if(resourceDetails &&  Date.parse(new Date()) < getEndTime(resourceDetails.last_claim, boostDetails)){
      const today = Date.parse(new Date());
      const start = resourceDetails.last_claim * 1000;
      const end = getEndTime(resourceDetails.last_claim, boostDetails);
      return Math.round(((today - start) / (end - start)) * 100);
    } else if(resourceDetails &&  Date.parse(new Date()) >= getEndTime(resourceDetails.last_claim, boostDetails)){
      return 100;
    } else {
      return 0;
    }
  }

  const getStatus = (resourceDetails, remainingCount, totalCount, boostDetails) => {
    if(remainingCount === totalCount){
      return 'Completed';
    } else if(resourceDetails &&  Date.parse(new Date()) < getEndTime(resourceDetails.last_claim, boostDetails)){
      return 'In Progress';
    } else if(resourceDetails &&  Date.parse(new Date()) >= getEndTime(resourceDetails.last_claim, boostDetails)){
      return 'Harvest';
    } else {
      return 'Available';
    }
  }

  const getColor = (resourceDetails,remainingCount, totalCount, boostDetails) => {
    if(remainingCount === totalCount){
      return 'error.main';
    } else if(resourceDetails &&  Date.parse(new Date()) < getEndTime(resourceDetails.last_claim, boostDetails)){
      return 'primary.main';
    } else if(resourceDetails &&  Date.parse(new Date()) >= getEndTime(resourceDetails.last_claim, boostDetails)){
      return 'info.main';
    } else {
      return 'warning.main';
    }
  }

  const getTimeInMin = (resourceDetails, boostDetails) => {
    if(resourceDetails === null || resourceDetails === undefined){
      return '24 hours';
    } else if(resourceDetails &&  Date.parse(new Date()) < getEndTime(resourceDetails.last_claim, boostDetails)){
      const now = Date.parse(new Date());
      const endTime = getEndTime(resourceDetails.last_claim, boostDetails);
      let difference = endTime - now;
      const hoursDifference   = Math.floor(difference / 3.6e6);
      const minutesDifference = Math.floor((difference % 3.6e6) / 6e4);
      // const seconds = Math.floor((difference % 6e4) / 1000);
      
      return `${hoursDifference} hours ${minutesDifference} minutes`;
      // return `${minutesDifference} minutes ${seconds} seconds`;
    } else if(resourceDetails &&  Date.parse(new Date()) >= getEndTime(resourceDetails.last_claim, boostDetails)){
      return '0 hours';
    }
  }

export const startHarvest = (session, username, assetId, boostId) =>{
  return async (dispatch)=>{
    try {
      await dispatch(hideErrorPage());
      await session.signTransaction(
        {
            actions: [{
                account: process.env.REACT_APP_GAME_CONTRACT,
                name: 'regharvest',
                authorization: [{
                    actor: username,
                    permission: session.requestPermission
                }],
                data: {
                    player: username,
                    asset_id: assetId,
                    boost_id: boostId || ''
                }
            }]
        },
        {
            blocksBehind: 3,
            expireSeconds: 30
        }
    );
    await new Promise(resolve => setTimeout(resolve, 2000));
    await dispatch(getHarvestAssets(username,session));
    } catch (error) {
      throw error;
    }
  }
}

export const harvestAll = (session, username, harvestAssets) =>{
  const actions = harvestAssets.map(obj=>({
    account: process.env.REACT_APP_GAME_CONTRACT,
    name: 'claimharvest',
    authorization: [{
        actor: username,
        permission: session.requestPermission
    }],
    data: {
        asset_id: obj.assetId
    }
  }));
  return async (dispatch)=>{
    try {
      await dispatch(hideErrorPage());
      await session.signTransaction(
        {
            actions
        },
        {
            blocksBehind: 3,
            expireSeconds: 30
        }
    );
    await new Promise(resolve => setTimeout(resolve, 10000));
    await dispatch(getHarvestAssets(username,session));
    await dispatch(getBalanceFromEOSIO(session));
    } catch (error) {
      throw error;
    }
  }
}

export const claimHarvest = (session, username, assetId) =>{
  return async (dispatch)=>{
    try {
      await dispatch(hideErrorPage());
      await session.signTransaction(
        {
            actions: [{
                account: process.env.REACT_APP_GAME_CONTRACT,
                name: 'claimharvest',
                authorization: [{
                    actor: username,
                    permission: session.requestPermission
                }],
                data: {
                    asset_id: assetId
                }
            }]
        },
        {
            blocksBehind: 3,
            expireSeconds: 30
        }
    );
    await new Promise(resolve => setTimeout(resolve, 10000));
    await dispatch(getHarvestAssets(username,session));
    await dispatch(getBalanceFromEOSIO(session));
    } catch (error) {
      throw error;
    }
  }
}

export const getHarvestAssets = (username, session) => {
    return async (dispatch) => {
        await dispatch(hideErrorPage());
        await dispatch(showLoader());
        try {
          const statusOrderObject = { 'Harvest': 0, 'In Progress': 1, 'Available': 2, 'Completed': 3}
          const assets = await fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/accounts/${username}/${applicationConfig.collectionName}`)
          .then(response => response.json())
          .then(res => {
            const templateIds = res.data.templates.map(obj=>obj.template_id);
            return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=harvestnfts&owner=${username}&template_whitelist=${templateIds.join('%2c')}&page=1&limit=1000&order=desc&sort=asset_id`)
            .then(response => response.json())
            .then(async(res) => {
              const harvestsFromTable = await getHarvestData(username, session);
              const allBoosts = await getBoostDetails('harvestboost', username);
              res.data = res.data.sort((a,b)=>(a.minted_at_time > b.minted_at_time ? -1: 1));
              return res.data.map(obj=>{
                const harvestObject = harvestsFromTable.find(el=>el.asset_id === obj.asset_id);
                let boostDetails = {} 
              if(harvestObject && harvestObject.boost_id && allBoosts.length > 0){
                boostDetails = allBoosts.find(el=> el.asset_id === harvestObject.boost_id);
              }
                return {
                  assetId: obj.asset_id,
                  name: obj.name,
                  image: `/static/seedball/harvest-carousel-${obj.name.toLowerCase().trim(' ')}.jpg`,
                  rarity: obj.data.Rarity,
                  totalCount: obj.data.totalcycles,
                  iconipercycle: obj.data.iconipercycle,
                  remainingCount: obj.mutable_data['Completed Cycle']|| 0,
                  percent: getPercent(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles, boostDetails),
                  status: getStatus(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles, boostDetails),
                  color: getColor(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles, boostDetails),
                  title: 'Harvest Cycle',
                  statusOrder: statusOrderObject[getStatus(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles, boostDetails)],
                  navigateTo: `${obj.asset_id}`,
                  category: 'Harvest',
                  subCategory: obj.name,
                  time: getTimeInMin(harvestObject, boostDetails),
                  boostId: harvestObject ? harvestObject['boost_id'] : 0
                }
              });
            });
          });
          await dispatch(slice.actions.setHarvestAssets(assets));
          await dispatch(hideLoader());
        } catch (error) {
          dispatch(hideLoader());
          dispatch(showErrorPage());
        }
      };
}

export const getHarvestAssetsFromEosio = async (username, session) => {
  
      try {
        const statusOrderObject = { 'Harvest': 0, 'In Progress': 1, 'Available': 2, 'Completed': 3}
        const assets = await fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/accounts/${username}/${applicationConfig.collectionName}`)
        .then(response => response.json())
        .then(res => {
          const templateIds = res.data.templates.map(obj=>obj.template_id);
          return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=harvestnfts&owner=${username}&template_whitelist=${templateIds.join('%2c')}&page=1&limit=1000&order=desc&sort=asset_id`)
          .then(response => response.json())
          .then(async(res) => {
            const harvestsFromTable = await getHarvestData(username, session);
            const allBoosts = await getBoostDetails('harvestboost', username);
            res.data = res.data.sort((a,b)=>(a.minted_at_time > b.minted_at_time ? -1: 1));
            return res.data.map(obj=>{
              const harvestObject = harvestsFromTable.find(el=>el.asset_id === obj.asset_id);  
              let boostDetails = {} 
              if(harvestObject && harvestObject.boost_id){
                boostDetails = allBoosts.find(el=> el.asset_id === harvestObject.boost_id);
              }
              return {
                assetId: obj.asset_id,
                name: obj.name,
                image: `/static/seedball/harvest-carousel-${obj.name.toLowerCase().trim(' ')}.jpg`,
                rarity: obj.data.Rarity,
                totalCount: obj.data.totalcycles,
                iconipercycle: obj.data.iconipercycle,
                remainingCount: obj.mutable_data['Completed Cycle']|| 0,
                percent: getPercent(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles),
                status: getStatus(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles),
                color: getColor(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles),
                title: 'Harvest Cycle',
                statusOrder: statusOrderObject[getStatus(harvestObject, obj.mutable_data['Completed Cycle']|| 0, obj.data.totalcycles)],
                navigateTo: `${obj.asset_id}`,
                category: 'Harvest',
                subCategory: obj.name,
                time: getTimeInMin(harvestObject),
                boostId: harvestObject ? harvestObject['boost_id'] : 0
              }
            });
          });
        });
        return assets;
      } catch (error) {
        return [];
      }
}