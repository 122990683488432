import { Typography } from "@mui/material";

export const nurtureinfo = <div>
<Typography variant="h5" color={'#14a6a5'}>
    Mission task
</Typography>
<Typography mb={1}>
    In the Nurture Stage, you will nurture a "Seedball with a container. The complete nurture process will require 3 special powers at 100% to complete this stage. The powers are Sun, Rain and Nutrition
</Typography>
<Typography mb={1}>
    Once you start the container power, you can't remove or add any power or "Seedball" to the container.
</Typography>
<Typography mb={1}>
    The container nurturing process stops if a container that has been started is transferred, sold or traded, and the user must start over, losing one life and all the ingredients needed to start the container.
</Typography>
</div>;

export const nurtureInfoTitleText = <div>
    <Typography>
        Good job so far, Friend !
    </Typography>
    <Typography>
        Now it is time to nurture the "Seedball" with the special containers our Scientists have made, so it can become a "Sprout". Having a container is essential to proceed.
    </Typography>
</div>

