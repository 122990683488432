import PropTypes from 'prop-types';
// material
import React, { useEffect, useState } from 'react';
import { Box, Card, Typography, Stack, Divider,Button, Link, Dialog, Slide, Container, IconButton, Grid } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MotionInView, varZoomIn } from 'src/components/animate';
import { useDispatch } from 'react-redux';
import { claimSprout, claimSproutWihDispatch, getBasketPayments } from 'src/redux/slices/seedball';
import { useSnackbar } from 'notistack';
import { useSelector } from 'src/redux/store';
import CloseIcon from '@mui/icons-material/Close';
import { claimRepair, claimRepairEosio, getRepairDeals, getRepairDealsById } from 'src/redux/slices/repair';
import { hideLongLoader, showLongLoader } from 'src/redux/slices/loader';
import { getContainerAssetsEosio } from 'src/redux/slices/container';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getHarvestAssetsFromEosio } from 'src/redux/slices/harvest';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShopProductCard({ product }) {
  const [openDiaog, setOpenDialog] = useState(false);
  const [openUnpackDialog, setOpenUnpackDialog] = useState(false);
  const [resources, setResources] = useState([]);
  const UserState = useSelector(store => store.user.userDetails)
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { assetId, name, image, quantity, cost, category, title, totalCount, remainingCount, status, percent, navigateTo, subCategory, dealId } = product;
  
  // const linkTo = `${PATH_DASHBOARD.eCommerce.root}/product/${paramCase(name)}`;
  const redirectTo = () => {
    if(category === 'Harvest'){
      return `../harvest/${navigateTo}`;
    } else if(category === 'Container'){
      return `../nurture/${navigateTo}`;
    } else {
      return '#';
    }
  }

  // useEffect(()=>{
  //   dispatch(getRepairDeals(UserState.session))
  // },[dispatch, UserState.name, UserState.session]);

  const closeDialog = () => {
    setOpenDialog(false);
  }

  const getLatestHarvest = async(harvestAssetId, count) => {
    console.log(count);
    if(count>=5 ) return {};
  const harvestList = await getHarvestAssetsFromEosio(UserState.name, UserState.session);
  console.log('storage id',harvestAssetId,'top id', harvestList[0].assetId);
  if(harvestList.length === 0){
    let newCount = count + 1;
    await new Promise(resolve => setTimeout(resolve, 10000));
   return getLatestHarvest(harvestAssetId, newCount);
  } else if(harvestList[0].assetId === harvestAssetId){
      let newCount = count + 1;
      await new Promise(resolve => setTimeout(resolve, 10000));
     return getLatestHarvest(harvestAssetId, newCount);
  } else{
      return harvestList[0];
  }
}

  const unpackHarvest = async (session, name, unboxId) => {
    try {
      dispatch(showLongLoader());
      await claimSprout(session, name, unboxId);
      const harvestAssetId = localStorage.getItem('harvestAssetId');
      const fruitObj = await getLatestHarvest(harvestAssetId, 0);
      if(fruitObj && fruitObj.name){
        setResources([].concat(fruitObj));
        enqueueSnackbar(`Congratulations, you have unpacked the harvest successfully.`, { variant: 'success', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          } });
          setOpenUnpackDialog(true);
    } else{
        enqueueSnackbar(`It is taking more time than expected, kindly go to Inventory and refresh harvest.`, { variant: 'warning', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          } });
    }
      setOpenDialog(false);
      dispatch(hideLongLoader());
    } catch (error) {
      dispatch(hideLongLoader());
      dispatch(getBasketPayments(UserState.session, UserState.name));
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  const getLatestContainer = async(containerAssetId, count) => {
    console.log('Count', count);
    if(count>=5 ) return [];
  const containerList = await getContainerAssetsEosio(UserState.name, UserState.session);
  if(containerList.length === 0){
    let newCount = count + 1;
    await new Promise(resolve => setTimeout(resolve, 10000));
   return getLatestContainer(containerAssetId, newCount);
  }else if(containerList[0].assetId === containerAssetId){
      let newCount = count + 1;
      await new Promise(resolve => setTimeout(resolve, 10000));
     return getLatestContainer(containerAssetId, newCount);
  } else{
    console.log('Containers list in getLatestContainer', containerList);
      return containerList;
  }
}

  const unpackRepair = async (session, name, unboxId) => {
    try {
      const repairList = await getRepairDealsById(session);
      const repairDetails = repairList[dealId];
      dispatch(showLongLoader());
      await claimRepairEosio(session, name, unboxId);
      const containerAssetId = localStorage.getItem('containerAssetId');
      const repairedContainerList = await getLatestContainer(containerAssetId, 0);
      console.log('Data---',containerAssetId, repairedContainerList);
      const contArray = ([...repairedContainerList]).splice(0, repairDetails.slots[0].value);
        console.log('Container Array',contArray);
      if(contArray && contArray.length > 0){
        
        setResources(contArray);
        
        enqueueSnackbar(`Congratulations, you have unpacked the containers successfully.`, { variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
        setOpenUnpackDialog(true);
      } else {
        console.log('Repair Array',repairedContainerList);
        dispatch(getBasketPayments(UserState.session, UserState.name));
        enqueueSnackbar(`It is taking more time than expected, kindly go to Inventory and refresh containers.`, { variant: 'warning', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
      }
      setOpenDialog(false);
      dispatch(hideLongLoader());
      
    } catch (error) {
      dispatch(hideLongLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  const unpack = async () => {
    try {
        const unboxId = assetId;
        // if(containers.length > 0){
        //   localStorage.setItem('containerAssetId', containers[0].assetId);
        // } else if(harvests.length > 0){
        //   localStorage.setItem('harvestAssetId', harvests[0].assetId);
        // }
        if(subCategory === 'Repair deal'){
          const containerList = await getContainerAssetsEosio(UserState.name, UserState.session);
          if(containerList.length > 0){
            localStorage.setItem('containerAssetId', containerList[0].assetId);
          } else {
            localStorage.setItem('containerAssetId', null);
          }
          await unpackRepair(UserState.session, UserState.name, unboxId);
        } else{
          const harvestList = await getHarvestAssetsFromEosio(UserState.name, UserState.session);
          localStorage.setItem('harvestAssetId', harvestList.length > 0 ? harvestList[0].assetId: null);
          await unpackHarvest(UserState.session, UserState.name, unboxId);
          
        }
    } catch (error) {
      // enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
      //   vertical: 'top',
      //   horizontal: 'center'
      // } });
    }
  }

  const performAction = () => {
    if(category === 'Unbox Basket') {
        setOpenDialog(true);
      }
  }

  const navigateToContainer = (assetId) => {
    if(subCategory === 'Care for sprout'){
      navigate(`${PATH_DASHBOARD.general.harvest}/${assetId}`);
    } else {
      navigate(`${PATH_DASHBOARD.general.container}/${assetId}`);
    }
  }

  return (
    <>
    <Link component={RouterLink} onClick={performAction} underline="none" to={redirectTo()}>
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <ProductImgStyle alt={name} src={image} />
      </Box>
      <Divider></Divider>
      {['Boost'].includes(category) && <Stack sx={{ p: 3 }}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" noWrap>
          <br/> Quantity: {quantity}
          </Typography>
      </Stack>}
      {['Unbox Basket'].includes(category) && <Stack sx={{ p: 3 }}>
          <Typography variant="subtitle1" noWrap>
            {name === 'Unbox' ? 'Unbox Deal' : name }
          </Typography>
          <Typography variant="body2" noWrap>
            <br/> ID: {assetId}
            <br/> {subCategory}
          </Typography>
      </Stack>}
      {!['Container', 'Harvest', 'Unbox Basket', 'Boost'].includes(category) && <Stack sx={{ p: 3 }}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" noWrap>
            <br/> ID: {assetId}
            <br/> Quantity: {quantity}
            <br/> Cost: {cost || 'Get by playing game'}
          </Typography>
      </Stack>}
      {['Container', 'Harvest'].includes(category) && <Stack sx={{ p: 3 }}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" noWrap>
            <br/> ID: {assetId}
            <br/> {title}: {category === 'Harvest' ? (status === 'Available'?0:(remainingCount === totalCount?totalCount:remainingCount+1)):remainingCount} of {totalCount}
            <br/> Status: {status}
            <br/> Progress: {percent} %
          </Typography>
      </Stack>}
    </Card>
    </Link>
    <Dialog
        disablebackdropclick="true"
        open={openDiaog}
        TransitionComponent={Transition}>
        
          <Container sx={{margin: '30px 0'}}>
          <IconButton
              edge="start"
              color="default"
              sx={{
                right: 5,
                top: 5,
                position: 'absolute'
              }}
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon/>
              </IconButton>
            <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
              style={{ height: '90%', width: '90%', position:'absolute' }}
            >
            </Player>
            {subCategory === 'Care for sprout' && <><Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
                Congratulations! You received a fruit basket.
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
                Unpack this basket to receive the fruit.
            </Typography></>}
            {subCategory === 'Repair deal' && <><Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
                Congratulations! You received new containers.
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
            Unpack the box to receive the containers.
            </Typography></>}
              
            <MotionInView variants={varZoomIn}>
              <Box
                component="img"
                src={subCategory === 'Repair deal' ? "/static/seedball/rapairbox.png" : "/static/seedball/seedball-basket.png"}
                sx={{
                  width: '70%',
                  borderRadius: '10px',
                  display: 'block',
                  margin: 'auto',
                  filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
                }}
              />
            </MotionInView>
            <Box sx={{textAlign: 'center', mt: 5}}>
              <Button variant="contained" onClick={unpack}>
                  Unpack
              </Button>
            </Box>
          </Container>
      </Dialog>
      <Dialog
        disablebackdropclick="true"
        open={openUnpackDialog}
        TransitionComponent={Transition}>
        
          <Container sx={{margin: '30px 0'}}>
          <IconButton
              edge="start"
              color="default"
              sx={{
                right: 5,
                top: 5,
                position: 'absolute'
              }}
              onClick={()=>{
                setOpenUnpackDialog(false);
                dispatch(getBasketPayments(UserState.session, UserState.name));
              }}
              aria-label="close"
            >
              <CloseIcon/>
              </IconButton>
            <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
              style={{ height: '90%', width: '90%', position:'absolute' }}
            >
            </Player>
            {subCategory === 'Care for sprout' && <><Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
                Congratulations! You received a fruit basket.
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
                Unpack this basket to receive the fruit.
            </Typography></>}
            {subCategory === 'Repair deal' && <><Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
              Congratulations! You received below containers.
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
            Go to containers page to start them.
            </Typography></>}
            <Grid container spacing={3} px={{xs:9, sm:14, md:0}} sx={{justifyContent: 'center'}}>
           {resources.map((data, index) => (
            <Grid key={index} item xs={12} sm={6} md={6}>
              <MotionInView variants={varZoomIn}>
                <Card>
                <Box
                component="img"
                src={data.image}
                sx={{
                  width: 250,
                  borderRadius: '2px',
                  display: 'block',
                  margin: 'auto',
                  filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
                }}
              />
              <Divider/>
              <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', my: 1 }}>
                ID: {data.assetId}
            </Typography>
            <Box sx={{textAlign: 'center', my: 2}}>
            <Button variant="contained" onClick={()=>{navigateToContainer(data.assetId)}} sx={{zIndex:'99'}}>
                Claim
            </Button>
          </Box>
                </Card>
              
          </MotionInView>
            </Grid>
          ))}
        </Grid>
          </Container>
      </Dialog>
    </>
  );
}
