
//
import { MAvatar } from './@material-extend';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const UserState = useSelector((store) => store.user.userDetails);

  return (
    <MAvatar
      src="/static/seedball/iconi-token-icon.svg"
      alt={UserState.name}
      // color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
      // {...other}
    >
      {/* {createAvatar(user.displayName).name} */}
    </MAvatar>
  );
}
