import { createSlice } from '@reduxjs/toolkit';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  userDetails: {
    name: '',
    isLogged: false,
    profileImage: '',
    session:{}
  },
  balance: {
    wax:'0.00000000 WAX',
    iconi: '0.0000 ICONI'
  },
  simpleAssets: [],
  isSeedballCreated: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setPlayerData(state, action) {
      state.isLoading = false;
      state.userDetails = {
                name: action.payload.activeUser.accountName,
                isLogged: true,
                session: action.payload.activeUser
            }
    },

    setPlayerLogout(state, action) {
      const { activeUser, activeAuthenticator, logout } = action.payload;
      if (!!activeUser && !!activeAuthenticator) {
        logout();
        localStorage.setItem('pathname', '/seedball/nurture');
        state.userDetails = initialState.userDetails;
      }
    },

    setPlayerBalance(state, action) {
      state.isLoading = false;
      state.balance = action.payload;
    },

    setSimpleAssets(state, action) {
      state.isLoading = false;
      state.simpleAssets = action.payload;
    },

    setSeedballAssetStatus(state, action) {
      state.isLoading = false;
      state.isSeedballCreated = action.payload !== null ? true:false;
    },

    setResourceBoughtSuccessfully(state, action) {
      state.isLoading = false;
    },
  }
});

// Reducer
export default slice.reducer;
export const {
  setPlayerData,
  setPlayerLogout
} = slice.actions;

  export const getBalanceFromEOSIO =  (session) => {
    return async(dispatch) => {
      try {
        const ualType = localStorage.getItem('UALLoggedInAuthType');
        const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
        const iconiBalance = await rpcEndpoint.get_currency_balance(process.env.REACT_APP_TOKEN, session.accountName, null);
        const waxBalance = await rpcEndpoint.get_currency_balance('eosio.token', session.accountName, null);
        const balance = {
          wax: waxBalance.length > 0 ? waxBalance[0] : '0.00000000 WAX',
          iconi: iconiBalance.length > 0 ? iconiBalance[0] : '0.0000 ICONI'
        }
        dispatch(slice.actions.setPlayerBalance(balance));
      } catch (error) {
        dispatch(slice.actions.setPlayerBalance({
          wax: '0.00000000 WAX',
          iconi: '0.0000 ICONI'
        }))
      }
    }
  }
