import PropTypes from 'prop-types';
import { merge } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Typography, Stack, Card, alpha, Link, Grid, Box } from '@mui/material';
//
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from 'src/components/charts';
import { Divider } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { varFadeInDown, varWrapEnter } from '../animate';
import { motion } from 'framer-motion';

const RootStyle = styled(motion.div)(({ theme }) => ({
}));

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object
};

function CarouselItem({ item, index, assetsLength }) {
  const { status, percent, name, assetId, color, totalCount, remainingCount, title, image, navigateTo, category} = item;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const CHART_DATA = [+percent];
   
  const IconStyle = styled('div')(({ theme }) => ({
    marginLeft: -4,
    borderRadius: '50%',
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    border: `solid 2px ${theme.palette.background.paper}`,
    boxShadow: `inset -1px 1px 2px ${alpha(theme.palette.common.black, 0.24)}`
  }));
  
  const chartOptions = merge(BaseOptionChart(), {
    legend: { show: false },
    grid: {
      padding: { top: 0, bottom: 0 }
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          [
            { offset: 0, color: theme.palette.primary.light },
            { offset: 100, color: theme.palette.primary.main }
          ]
        ]
      }
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        dataLabels: {
          name: { show: false },
          value: { offsetY: 8 }
        },
        track: {
          background: '#fff',
          opacity: '0.2'
        }
      }
    }
  });

  const getTransformOnLength = () => {
    let transformValue;
    switch(assetsLength) {
      case 5:
        transformValue = index === 2 ? 'scale(1)' : ([1,3].includes(index) ? 'scale(.75)' : 'scale(.65)');
        break;
      case 4:
        transformValue = [1,2].includes(index) ? 'scale(1)' : 'scale(.75)'
        break;
      case 3:
        transformValue = index === 1 ? 'scale(1)' : 'scale(.75)'
        break;
      case 2:
        transformValue = 'scale(1)';
        break;
      case 1:
        transformValue = 'scale(1)';
        break;
      default:
        transformValue = 'scale(1)';
        break;
    }
    return transformValue
  }

  const getOpacityOnLength = () => {
    let opacityValue;
    switch(assetsLength) {
      case 5:
        opacityValue = index === 2 ? 1 : ([1,3].includes(index) ? 8.5 : .65);
        break;
      case 4:
        opacityValue = [1,2].includes(index) ? 1 : .85
        break;
      case 3:
        opacityValue = index === 1 ? 1 : .85
        break;
      case 2:
        opacityValue = 1;
        break;
      case 1:
        opacityValue = 1;
        break;
      default:
        opacityValue = 1;
        break;
    }
    return opacityValue
  }

  const getpositionForLastElements = () => {
    let transformOriginValue;
    switch(index) {
      case 4:
        transformOriginValue = 'left';
        break;
      case 0:
        transformOriginValue = 'right';
        break;
      default:
        transformOriginValue = 'center';
        break
    }
    return transformOriginValue;
  }
  const getAnimationScale = () => {
    let transformValue;
    switch(assetsLength) {
      case 5:
        transformValue = index === 2 ? 'scale(1.03)' : ([1,3].includes(index) ? 'scale(.78)' : 'scale(.68)');
        break;
      case 4:
        transformValue = [1,2].includes(index) ? 'scale(1.03)' : 'scale(.78)'
        break;
      case 3:
        transformValue = index === 1 ? 'scale(1.03)' : 'scale(.78)'
        break;
      case 2:
        transformValue = 'scale(1.03)';
        break;
      case 1:
        transformValue = 'scale(1.03)';
        break;
      default:
        transformValue = 'scale(1.03)';
        break;
    }
    return transformValue;
  }
  return (
    <Link component={RouterLink} underline="none"  to={navigateTo}>
    <Card sx={!isMobile?{
      cursor: 'pointer',
      opacity: getOpacityOnLength(),
      transform: getTransformOnLength(),
      transformOrigin: assetsLength >= 5 ? getpositionForLastElements() : 'center',
      zIndex: 9,
      backgroundImage: `linear-gradient(0deg,
        ${theme.palette.grey['900']} 0%,
        ${theme.palette.grey['700']} 100%)`, borderRadius: '8px',
        '&:hover': {
          boxShadow: `0px 1px 16px 5px ${alpha('rgb(8,153,228)', '0.74')}`,
          transform: getAnimationScale(),
          transition: 'all 0.5s linear',
          opacity: 1
       }
    }: {mx:1}}>
    {['Halted', 'Completed'].includes(status) && <Box sx={{ pt: '90%', position: 'relative' }}>
        <ProductImgStyle alt={name} src={category === 'Container' ? '/static/seedball/seedball-card.jpg' : image} />
      </Box>}
    {!['Halted', 'Completed'].includes(status) && <Stack>
        <ProductImgStyle alt={name} src={category === 'Container' ? '/static/seedball/seedball-card.jpg' : image} />
        <ReactApexChart type="radialBar" series={CHART_DATA} options={chartOptions} height={200} />
    </Stack>}
    <Divider/>
    <Stack sx={{ p: 2 }}>
        <Typography variant="subtitle1">
          {name}
        </Typography>
        <Typography variant="caption" sx={{color: 'text.secondary'}} noWrap>
          ID: {assetId}
        </Typography>
        <Typography variant="caption" sx={{color: 'text.secondary'}}>
          {title}: {category === 'Harvest' ? (status === 'Available'?0:(remainingCount === totalCount?totalCount:remainingCount+1)):remainingCount} of {totalCount}
        </Typography>
        <Stack direction="row" alignItems="center">
        <IconStyle sx={{ bgcolor: color, mr: 1}}/>
        <Typography variant="caption" sx={{color: 'text.secondary'}}>
           {status}
        </Typography>
    </Stack>
    </Stack>
  </Card>
  </Link>
  );
}

export default function TopDataCards({assets}) {
  const theme = useTheme();
  const isComputer = useMediaQuery(theme.breakpoints.up('lg'));
  const isTab = useMediaQuery(theme.breakpoints.down('lg') && theme.breakpoints.up('md'));

  const getGridSpacing = () => {
    if( isComputer ){
      return (assets && assets.length >= 5) ? undefined : 3;
    } else if(isTab){
      return 4;
    } else {
      return 2;
    }
  }

  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      {assets && assets?.length > 0 && <>
        <Grid container sx={{justifyContent: 'center'}} p={{xs:6, sm:6, md:0}} px={{xs:8, sm:18, md:0}} spacing={getGridSpacing()}>
          {assets.map((item, index) => {      
            return (<Grid sx={{
              justifyContent: 'flex-start',
              padding: assets.length === 5 ? 'auto !important' : "0 !important",
              margin:assets.length === 5 ? '0 !important' : '24px',
              }}
              flexBasis={{xs:'auto', sm:'auto', md:'19% !important'}}
              maxWidth={{xs:'auto', sm:'auto', md:'19% !important'}}
              minWidth={{xs:'auto', sm:'auto', md:'175px !important'}}
              key={item.assetId} item xs={12} sm={6} md={2}>
              <motion.div variants={varFadeInDown}>
                  <CarouselItem index={index} key={item.assetId} item={item} assetsLength={assets.length} />
              </motion.div>
            </Grid>)
          })}
        </Grid>
        </>}
        </RootStyle>
  );
}