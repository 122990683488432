
import SeedballSplashScreen from './SeedballSplashScreen';

// ----------------------------------------------------------------------

export default function MainLayout({ual}) {
  return (
    <>
    <SeedballSplashScreen ual={ual} />
    </>
  );
}
