// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Typography, Button } from '@mui/material';
import { MIconButton } from 'src/components/@material-extend';
import { Icon } from '@iconify/react';
import minusFill from '@iconify/icons-eva/minus-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { issueSA } from 'src/redux/slices/seedball';
import { useSnackbar } from 'notistack';
import { hideLoader, showLoader } from 'src/redux/slices/loader';

// ----------------------------------------------------------------------

const ContentStyle = styled(Card)(({ theme }) => ({
  marginTop: -80,
  boxShadow: 'none',
  padding: theme.spacing(3),
  paddingTop: theme.spacing(12),
  color: theme.palette.common.white,
  backgroundImage: `linear-gradient(135deg,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.dark} 100%)`
}));

const Incrementer = ({updateCost}) => {
    const [value, setValue] = useState(1);
    const incrementQuantity = async () => {
      await setValue(value + 1);
    };
    const decrementQuantity = async () => {
      await setValue(value - 1);
    };
    useEffect(()=>{
        updateCost(value);
    },[updateCost, value])
  
    return (
      <Box
        sx={{
          py: 0.5,
          px: 0.75,
          border: 1,
          lineHeight: 0,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          borderColor: 'grey.50032'
        }}
      >
        <MIconButton size="small" color="inherit" disabled={value <= 1} onClick={decrementQuantity}>
          <Icon icon={minusFill} width={16} height={16} />
        </MIconButton>
        <Typography
          variant="body2"
          component="span"
          sx={{
            width: 40,
            textAlign: 'center',
            display: 'inline-block'
          }}
        >
          {value}
        </Typography>
        <MIconButton size="small" color="inherit" onClick={incrementQuantity}>
          <Icon icon={plusFill} width={16} height={16} />
        </MIconButton>
      </Box>
    );
  };

// ----------------------------------------------------------------------

export default function SeedballBuyCard({cardDetails}) {
  const initialState = cardDetails.iconi;
  const dispatch = useDispatch();
  const [cost, setCost] = useState(1);
  const UserState = useSelector((store) => store.user.userDetails);
  const assetQuantity = useSelector((store) => store.seedball.assetQuantity);
  const [quantity, setQuantity] = useState(1);
  const {enqueueSnackbar} = useSnackbar();
  const updateCost = (value) => {
      const increment = value || 1;
      setCost(initialState*increment);
      setQuantity(increment);
  }
  const buyResources = async () => {
    try {
      dispatch(showLoader());
      await dispatch(issueSA(UserState.session, UserState.name, cost, quantity, cardDetails.code, cardDetails.value, assetQuantity));
      enqueueSnackbar(`Congratulations, you bought ${cardDetails.name} successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }
  return (
    <div>
      <Box
        component="img"
        src={cardDetails.image}
        sx={{
          zIndex: 9,
          position: 'relative',
          left: 40,
          width: 100,
          borderRadius: '10px',
          filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
        }}
      />
      <ContentStyle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            Buy {cardDetails.name}
          </Typography>
        </Stack>

        <Typography variant="h4">
            {cost}.0000 ICONI
        </Typography>

        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <Typography variant="caption">
              Quantity
          </Typography> 
          <Incrementer updateCost={updateCost}/>
          <Button color="warning" variant="contained" onClick={buyResources}>
            Buy
          </Button>
        </Stack>
      </ContentStyle>
    </div>
  );
}
