import { Typography } from "@mui/material";

export const careInfo = <div>
        <Typography variant="h5" color={'#14a6a5'}>
                Mission task
        </Typography>
        <Typography>
            Required Ingredients
        </Typography>
        <Typography ml={4}>
            <ul>
                <li>Sprout - this little plant must now grow to produce food</li>
                <li>Fence - don't let the big animals destroy your crops</li>
                <li>Sprinkler - make sure your crops get enough water</li>
                <li>Scarecrow - birds are not always our friends.....</li>
                <li>Temperature - use a dome for better climate control</li>
                <li>PH Level - enhance the soil with fertilizer if needed</li>
            </ul>  
        </Typography>
    </div>;

export const careInfoTitleText = <div>
    <Typography>
        Outstanding work, my Friend.
    </Typography>
    <Typography>
        You have brought new life into this world. Now you will learn to care and protect for your newly sprouted life.
        You must craft different objects to make sure your sprout will mature and you can enjoy its harvest.
    </Typography>
    <Typography mt={2}>
        Good luck Friend !
    </Typography>
</div>
