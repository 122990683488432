import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  image: PropTypes.string
};

export default function Logo({ sx, image }) {
  return (
    <Box sx={{ width: 100, height: 40, mr: 5, ...sx }}>
      <img className="image-100" src={image ? image : "/static/seedball/seedball-logo.svg"} alt="Seedball logo"/>
    </Box>
  );
}
