import { createSlice } from '@reduxjs/toolkit';
import { getBalanceFromEOSIO } from './user';
import { getHarvestAssets } from './harvest';
import * as eosjsAccountName from 'eosjs-account-name';
import { hideLoader, hideLongLoader, showLoader, showLongLoader, showErrorPage, hideErrorPage } from './loader';
import { applicationConfig } from 'src/config';
// wax and anchor imports


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  simpleAssets: [],
  payments: [],
  basketPayments: [],
  selectedTemplate: '',
  sideBarStatus: {
    toggle: true,
    hover: false
  },
  filters: {
    selectAll: [],
    container: [],
    seedball: [],
    nurture: [],
    care: [],
    harvest: [],
    status: [],
    harvestStatus: [],
    basket: [],
    rarity: [],
    boost: [],
    power: []
  },
  assetQuantity: {
    SEEDBAL: null,
    SUN: null,
    RAIN: null,
    NUTRI: null,
    SPRAY: null,
    SOIL: null,
    VERMI: null,
    SEED: null,
    LIGHT: null,
    SPROUT: null,
    FENCE: null,
    SPRINKL: null,
    SCARECR: null,
    TEMPERA: null,
    PHMETER: null,
    SEEDKIT: null,
    CAREKIT: null
  },
  progressDetails: {},
  isSeedballCreated: false,
  isHarvestBoxCreated: false,
  isUnpacked: false
};

const assetData = {
  SEEDBAL: {
    name: 'Seed ball',
    image: '/static/seedball/Seedball.png',
  },
  SUN: {
    name: 'Sun',
    image: '/static/seedball/power-sun.png'
  },
  RAIN: {
    name: 'Rain',
    image: '/static/seedball/power-rain.png'
  },
  NUTRI: {
    name: 'Nutrition',
    image: '/static/seedball/power-compost.png'
  },
  SPRAY: {
    name: 'Water spray',
    image: '/static/seedball/spray.jpg'
  },
  SOIL: {
    name: 'Soil',
    image: '/static/seedball/soil.jpg'
  },
  VERMI: {
    name: 'Vermi compost',
    image: '/static/seedball/vermi.jpg'
  },
  SEED: {
    name: 'Seed',
    image: '/static/seedball/seed.jpg'
  },
  LIGHT: {
    name: 'Sun light',
    image: '/static/seedball/sunlight.jpg'
  },
  SPROUT: {
    name: 'Sprout',
    image: '/static/seedball/care-sprout.png'
  },
  FENCE: {
    name: 'Fence',
    image: '/static/seedball/care-fence.jpg'
  },
  SCARECR: {
    name: 'Scarecrow',
    image: '/static/seedball/care-scarecrow.jpg'
  },
  SPRINKL: {
    name: 'Sprinkler',
    image: '/static/seedball/care-sprinkler.jpg'
  },
  TEMPERA: {
    name: 'Temperature',
    image: '/static/seedball/care-temperature.jpg'
  },
  PHMETER: {
    name: 'PH Level',
    image: '/static/seedball/care-phlevel.jpg'
  },
  SEEDKIT: {
    name: 'Seed ball Kit',
    image: '/static/seedball/seed-ball-kit.png'
  },
  CAREKIT: {
    name: 'Care Kit',
    image: '/static/seedball/care-kit.png'
  }
}

const slice = createSlice({
  name: 'seedball',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSimpleAssets(state, action) {
      state.isLoading = false;
      state.simpleAssets = action.payload;
    },

    getAssetQuantityFromSA(state, action) {
      const filterAssets = state.simpleAssets.filter(obj => obj.author === process.env.REACT_APP_FT_SALE && obj.balance.includes(action.payload.value));
      state.assetQuantity = filterAssets.length > 0 ? filterAssets[0].balance.split(`${action.payload.value}`)[0] : 0;
    },

    setAssetQuantityForSA(state, action) {
      state.assetQuantity[action.payload.value] = +action.payload.count;
    },

    setAssetQuantityForSAToInitialState(state) {
      state.assetQuantity = initialState.assetQuantity;
    },

    setActiveContainers(state, action) {
      state.isLoading = false;
      state.activeContainers = action.payload;
    },

    setProgressData(state, action) {
      state.progressDetails = action.payload;
    },

    setActiveHarvests(state, action) {
      state.isLoading = false;
      state.activeHarvests = action.payload;
    },

    setPayments(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },

    setSeedballAssetStatus(state, action) {
      state.isLoading = false;
      state.isSeedballCreated = action.payload !== null ? true:false;
    },

    setHarvestBasket(state, action) {
      state.isLoading = false;
      state.isHarvestBoxCreated = action.payload !== null ? true:false;
    },
    setUnpack(state, action) {
      state.isUnpacked = action.payload !== null ? true:false;
    },
    setSprouted(state, action) {
      state.isLoading = false;
      state.isSprouted = action.payload !== null ? true:false;
    },
    filterAllResources(state, action) {
      state.filters.container = action.payload.container;
      state.filters.seedball = action.payload.seedball;
      state.filters.nurture = action.payload.nurture;
      state.filters.care = action.payload.care;
      state.filters.harvest = action.payload.harvest;
      state.filters.selectAll = action.payload.selectAll;
      state.filters.status = action.payload.status;
      state.filters.harvestStatus = action.payload.harvestStatus;
      state.filters.basket = action.payload.basket;
      state.filters.rarity = action.payload.rarity;
      state.filters.boost = action.payload.boost;
      state.filters.power = action.payload.power;

    },
    setSidebarStatus(state, action) {
      state.sideBarStatus.toggle = action.payload.toggle;
      state.sideBarStatus.hover = action.payload.hover;
    },
    setBasketPayments(state, action) {
      state.basketPayments = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
export const {
  filterAllResources,
  setSidebarStatus
} = slice.actions;

// Actions
export const getSimpleAssets = (session) => {
    return async (dispatch)=>{
      await dispatch(hideErrorPage());
      dispatch(showLoader());
      try {
        const ualType = localStorage.getItem('UALLoggedInAuthType');
        const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
        const simpleAssets = await rpcEndpoint.get_table_rows({
          json: true,               // Get the response as json
          code: 'simpleassets',      // Contract that we target
          scope: session.accountName,         // Account that owns the data
          table: 'accounts',        // Table name
          limit: 100,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false          // Optional: Show ram payer
        }).then(res=> {
          const gameAssets = res.rows.filter(obj=> obj.author === process.env.REACT_APP_FT_SALE);
          return gameAssets.map(obj=>({
            assetId: obj.id,
            name: assetData[obj.balance.split(' ')[1]].name,
            author: obj.author,
            image: assetData[obj.balance.split(' ')[1]].image,
            balance: obj.balance,
            quantity: obj.balance.split(' ')[0],
            cost: ['SUN', 'RAIN', 'NUTRI'].includes(obj.balance.split(' ')[1])? '5.0000 ICONI':
            (['SEEDBAL', 'SPROUT'].includes(obj.balance.split(' ')[1]) ? '' : 
            (['SEEDKIT', 'CAREKIT'].includes(obj.balance.split(' ')[1])?'10.0000 ICONI':'2.0000 ICONI')),
            category: ['SUN', 'RAIN', 'NUTRI', 'SEEDBAL'].includes(obj.balance.split(' ')[1])? 'Nurture'
            :(['SPRAY', 'SOIL', 'VERMI', 'SEED', 'LIGHT', 'SEEDKIT'].includes(obj.balance.split(' ')[1]) ? 'Seedball': 'Care'),
            subCategory: assetData[obj.balance.split(' ')[1]].name
          }));
        })
          
        await dispatch(slice.actions.setSimpleAssets(simpleAssets));
        dispatch(hideLoader());
      } catch (error) {
        dispatch(hideLoader());
        dispatch(showErrorPage());
      }
    }
  }

  export const getSimpleAssetsWithoutDispatch = async (session) => {
      
      try {
        const ualType = localStorage.getItem('UALLoggedInAuthType');
        const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
        const simpleAssets = await rpcEndpoint.get_table_rows({
          json: true,               // Get the response as json
          code: 'simpleassets',      // Contract that we target
          scope: session.accountName,         // Account that owns the data
          table: 'accounts',        // Table name
          limit: 100,                // Maximum number of rows that we want to get
          reverse: false,           // Optional: Get reversed data
          show_payer: false          // Optional: Show ram payer
        }).then(res=> {
          const gameAssets = res.rows.filter(obj=> obj.author === process.env.REACT_APP_FT_SALE);
          return gameAssets.map(obj=>({
            assetId: obj.id,
            name: assetData[obj.balance.split(' ')[1]].name,
            author: obj.author,
            image: assetData[obj.balance.split(' ')[1]].image,
            balance: obj.balance,
            quantity: obj.balance.split(' ')[0],
            cost: ['SUN', 'RAIN', 'NUTRI'].includes(obj.balance.split(' ')[1])? '5.0000 ICONI':
            (['SEEDBAL', 'SPROUT'].includes(obj.balance.split(' ')[1]) ? '' : 
            (['SEEDKIT', 'CAREKIT'].includes(obj.balance.split(' ')[1])?'10.0000 ICONI':'2.0000 ICONI')),
            category: ['SUN', 'RAIN', 'NUTRI', 'SEEDBAL'].includes(obj.balance.split(' ')[1])? 'Nurture'
            :(['SPRAY', 'SOIL', 'VERMI', 'SEED', 'LIGHT', 'SEEDKIT'].includes(obj.balance.split(' ')[1]) ? 'Seedball': 'Care'),
            subCategory: assetData[obj.balance.split(' ')[1]].name
          }));
        })
        return simpleAssets;
      } catch (error) {
        return [];
      }
  }

  export const getPaymentsData = (session) => {
    return async (dispatch)=>{
      await dispatch(hideErrorPage());
      await dispatch(showLoader());
      try {
        let next_key ='';
      let more = true;
      let payments = [];
      while(more){
        const ualType = localStorage.getItem('UALLoggedInAuthType');
        const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
        let res = await rpcEndpoint.get_table_rows({
         json: true,
         code: process.env.REACT_APP_GAME_CONTRACT,
         scope: process.env.REACT_APP_GAME_CONTRACT,
         table: "payments",
         limit: 500,
         lower_bound : next_key
        }).catch(e => { 
           throw e;
         } );
     
        if(res && res.rows){
         payments = payments.concat(res.rows);
         more = res.more;
         next_key = res.next_key;
        }
       }
        await dispatch(slice.actions.setPayments(payments));
        await new Promise(resolve => setTimeout(resolve, 3000));
        await dispatch(hideLoader());
      } catch (error) {
        dispatch(hideLoader());
        throw error;
      }
    }
  }

  export const setProgressDetails = (details) => {
    return async (dispatch)=>{
      dispatch(slice.actions.setProgressData(details));
    }
  }

  export const seedballStatusFalse = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.setSeedballAssetStatus(null));
    }
  }

  export const setHarvestBoxStatusFalse = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.setHarvestBasket(null));
      dispatch(slice.actions.setUnpack(null));
    }
  }

  export const sproutedFalse = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.setSprouted(null));
    }
  }

  export const setSproutedData = (message) => {
    return async (dispatch)=>{
      dispatch(slice.actions.setSprouted(message));
    }
  }
  
  export const setAssetQuantity = (value,count) => {
    return async (dispatch)=> {
      dispatch(slice.actions.setAssetQuantityForSA({value,count}));
    }
  }

  export const setAssetQuantityToInitialState = () => {
    return async (dispatch)=> {
      dispatch(slice.actions.setAssetQuantityForSAToInitialState());
    }
  }

  export const setNew = () => {
    return async (dispatch)=> {
      dispatch(slice.actions.setAssetQuantityForSAToInitialState());
    }
  }

  export const craftSeedball = (session, username) => {
    return async (dispatch)=>{
      try {
        await dispatch(hideErrorPage());
        const seedball = await session.signTransaction(
          {
              actions: [{
                  account: process.env.REACT_APP_GAME_CONTRACT,
                  name: 'craftsb',
                  authorization: [{
                      actor: username,
                      permission: session.requestPermission
                  }],
                  data: {
                      player: username
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      await dispatch(getSimpleAssets(session));
      await dispatch(slice.actions.setSeedballAssetStatus(seedball));
      } catch (error) {
        throw error;
      }
    }
  }

  export const careSeedballNFT = (session, username) => {
    return async (dispatch)=>{
      try {
        await dispatch(hideErrorPage());
        const seedball = await session.signTransaction(
          {
              actions: [{
                  account: process.env.REACT_APP_GAME_CONTRACT,
                  name: 'care',
                  authorization: [{
                      actor: username,
                      permission: session.requestPermission
                  }],
                  data: {
                      player: username
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      await new Promise(resolve => setTimeout(resolve, 3000));
      await dispatch(slice.actions.setHarvestBasket(seedball));
      await dispatch(getPaymentsData(session));
      } catch (error) {
        throw error;
      }
    }
  }

  export const claimSproutWihDispatch = (session, username, unboxId) => {
    return async (dispatch)=>{
      try {
        await dispatch(hideErrorPage());
        await session.signTransaction(
          {
              actions: [{
                  account: process.env.REACT_APP_GAME_CONTRACT,
                  name: 'claimsprout',
                  authorization: [{
                      actor: username,
                      permission: session.requestPermission
                  }],
                  data: {
                      unbox_id: unboxId
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      await dispatch(showLongLoader());
      await dispatch(slice.actions.setUnpack({message: 'Unpacked successfully'}));
      await new Promise(resolve => setTimeout(resolve, 5000));
      await dispatch(getHarvestAssets(username, session));
      await dispatch(hideLongLoader());
      } catch (error) {
        dispatch(hideLongLoader());
        throw error;
      }
    }
  }

  export const claimSprout = async (session, username, unboxId) => {
      try {
        return session.signTransaction(
          {
              actions: [{
                  account: process.env.REACT_APP_GAME_CONTRACT,
                  name: 'claimsprout',
                  authorization: [{
                      actor: username,
                      permission: session.requestPermission
                  }],
                  data: {
                      unbox_id: unboxId
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      } catch (error) {
        throw error;
      }
  }

  export const issueSA = (session, username, cost, quantity, resource, value, assetQuantity) => {
    return async (dispatch) => {
      await dispatch(hideErrorPage());
      dispatch(slice.actions.startLoading());
      try {
         await session.signTransaction(
          {
              actions: [{
                account: process.env.REACT_APP_TOKEN,
                name: 'transfer',
                authorization: [{
                    actor: username,
                    permission: session.requestPermission
                }],
                  data: {
                    from: username,
                    to: process.env.REACT_APP_FT_SALE,
                    quantity: cost+'.0000 ICONI',
                    memo: `BUY:${resource}:${quantity}`
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      await dispatch(getSimpleAssets(session));
      await dispatch(setAssetQuantity(value, ((+assetQuantity[value] || 0) +  quantity)))
      await dispatch(getBalanceFromEOSIO(session));
      } catch (error) {
        throw error;
      }
    }
  }

  export const buyAll = (session, username, actionType, assetQuantity) => {
    return async (dispatch) => {
      await dispatch(hideErrorPage());
      dispatch(slice.actions.startLoading());
      try {
         await session.signTransaction(
          {
              actions: [{
                account: process.env.REACT_APP_TOKEN,
                name: 'transfer',
                authorization: [{
                    actor: username,
                    permission: session.requestPermission
                }],
                  data: {
                    from: username,
                    to: process.env.REACT_APP_FT_SALE,
                    quantity: '10.0000 ICONI',
                    memo: `BUY:${actionType === 'seedballitems'? '1004': '1005'}:1`
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      await dispatch(getSimpleAssets(session));
      if(actionType === 'seedballitems'){
        ['SEEDKIT'].forEach(async(element) => {
          await dispatch(setAssetQuantity(element, ((+assetQuantity[element] || 0) +  1)))
        });
      } else {
        ['CAREKIT'].forEach(async(element) => {
          await dispatch(setAssetQuantity(element, ((+assetQuantity[element] || 0) +  1)))
        });
      }
      
      await dispatch(getBalanceFromEOSIO(session));
      } catch (error) {
        throw error;
      }
    }
  }

  export const getBasketPayments =  (session, username) => {
    return async (dispatch)=>{
      await dispatch(hideErrorPage());
      try {
        let next_key ='';
        let more = true;
        let payments = [];
        while(more){
        const ualType = localStorage.getItem('UALLoggedInAuthType');
        const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
         let res = await rpcEndpoint.get_table_rows({
          json: true,
          code: process.env.REACT_APP_GAME_CONTRACT,
          scope: process.env.REACT_APP_GAME_CONTRACT,
          table: "payments",
          limit: 100,
          lower_bound : next_key
         }).catch(e => { 
            throw e;
          } );
      
         if(res && res.rows){
          payments = payments.concat(res.rows.filter(obj=>obj.account === username));
          more = res.more;
          next_key = res.next_key;
         }
        }
        const paymentList = payments.filter(obj=> obj.type === 'Care for sprout' || (obj.type === 'Repair deal' && obj.asset_ids.length > 0)).map(obj=>({
            assetId: obj.id,
            name: obj.type === 'Repair deal'? 'Unbox':'Unbox Basket',
            author: obj.account,
            image: obj.type === 'Repair deal'? '/static/seedball/unclaimed-recycle-box.jpg':'/static/seedball/unclaimed-harvest-basket.jpg',
            category: 'Unbox Basket',
            subCategory: obj.type,
            dealId: obj.deal_id
        }))
        await dispatch(showLoader());
        await dispatch(slice.actions.setBasketPayments(paymentList));
        await dispatch(hideLoader());
       } catch (error) {
        await dispatch(hideLoader());
        console.log(`Error fetching container table data, err:`, error);
        await dispatch(slice.actions.setBasketPayments([]));
       }
    }
  }

  export const getPaymentsDataFromTable = (username,session) => {
      const ualType = localStorage.getItem('UALLoggedInAuthType');
      const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
      return rpcEndpoint.get_table_rows({
        json: true,
        code: process.env.REACT_APP_GAME_CONTRACT,
        scope: process.env.REACT_APP_GAME_CONTRACT,
        table: "payments",
        limit: 500,
        key_type: 'i64',
        index_position: 2,
        lower_bound: eosjsAccountName.nameToUint64(username),
        upper_bound : eosjsAccountName.nameToUint64(username)
       }).then(res=> res.rows.filter(obj=>obj.type==='Care for sprout'))
       .catch(() => { 
          return [];
        });
    }

  export const getMintedHarvestAssets = async () => {
    return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/templates?collection_name=${applicationConfig.collectionName}&schema_name=harvestnfts&page=1&limit=100&order=desc&sort=created`)
    .then(response => response.json())
    .then(async(res) => {
      const sum =res.data.reduce((partialSum, a) => partialSum + +a.issued_supply, 0);
      return sum;
    })
    .catch(()=>{ return 0 });
  }

  const groupBy = (items, property) => items.reduce(
    (acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
         acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
   }, {});

  export const getLeaderBoard = async () => {
      let page = 1;
      let leaderBoard = [];
      const assets = await getAllAssets(page, []);
      console.log(assets);
      let filterUserOwnedAssets = assets.filter(obj=> obj.minted_at_time === obj.transferred_at_time);
      let groupedUsers = groupBy(filterUserOwnedAssets, 'owner');
      let groupedBurntUsers = groupBy(filterUserOwnedAssets, 'burned_by_account');
      let filterUserTransferedAssets = assets.filter(obj=> obj.minted_at_time !== obj.transferred_at_time);
      console.log(filterUserOwnedAssets,filterUserTransferedAssets);
      Object.keys(groupedUsers).forEach((user)=>{
        if(user !== "null") {
          leaderBoard.push({account: user, count: groupedUsers[user].length + (groupedBurntUsers[user] ? groupedBurntUsers[user].length : 0)})
        }
      })
      return leaderBoard.sort((a,b) => (a.count > b.count ? -1: 1)).slice(0, 50);
  }

  export const getAllAssets = async(page, totalAssets) => {
    return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=harvestnfts&page=${page}&limit=1000&order=desc&sort=asset_id`)
      .then(response => response.json())
      .then(async(res) => {
        if(res.data.length > 0){
          totalAssets  = totalAssets.concat(res.data);
          let newPage = page + 1;
          return getAllAssets(newPage, totalAssets);
        } else {
          totalAssets  = totalAssets.concat(res.data);
          return totalAssets
        }
        // filterUserActiveAssets = res.data.filter(obj=> obj.minted_at_time === obj.transferred_at_time && obj.burned_at_time === null);
        // filterUserBurntAssets = res.data.filter(obj=> obj.minted_at_time === obj.transferred_at_time && obj.burned_at_time !== null);
        // console.log(filterUserActiveAssets,filterUserBurntAssets);
      }).catch(()=>{
        return totalAssets;
      });
  }

  
