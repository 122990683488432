import PropTypes from 'prop-types';
// material
import { Box, Card, Typography, Stack, Button, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import SeedBallContainerList from './SeedBallContainerList';
import { styled } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { setAssetQuantity } from 'src/redux/slices/seedball';

SeedballFTCard.propTypes = {
    container: PropTypes.object
};

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'relative',
  borderRadius: '8px',
});

const IconStyle = styled('div')(({ theme }) => ({
  marginLeft: 4,
  marginRight: 4,
  marginTop: 3,
  borderRadius: '50%',
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  backgroundColor: '#FF4842',  
  border: `solid 0px ${theme.palette.background.paper}`
}));

const ContainerImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '10px'
}));

export default function SeedballFTCard({ container, updateSeedballImage, loadAllImage }) {
  const { name, quantity, iconi, type, image } = container;
  const [open, setOpen] = useState(false);
  const [selectedSeedball, setSelectedSeedball] = useState(null);
  const simpleAssets = useSelector((state)=>state.seedball.simpleAssets);
  const assetQuantity = useSelector((state)=>state.seedball.assetQuantity);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const openDrawer = (name) =>{
    setOpen(true);
  }
  const handleCloseContainer = () =>{
    setOpen(false);
  }
  const updateImage = (url) => {
    setSelectedSeedball(url);
    container.isSelected = true;
    updateSeedballImage(container);
  }
  useEffect(()=>{
    if(assetQuantity[container.value] === null){
      const filterAssets = simpleAssets.filter(obj => obj.author === process.env.REACT_APP_FT_SALE && obj.balance.includes(container.value));
      dispatch(setAssetQuantity(container.value, filterAssets.length > 0 ? filterAssets[0].balance.split(`${container.value}`)[0] : 0));
    }
  },[dispatch, container.value, simpleAssets, assetQuantity, loadAllImage])
  return (
    <>
    {type !== 'Repair' && <Card sx={name === 'Sprout'?
    {backgroundImage: `linear-gradient(0deg,
      ${theme.palette.primary.darker} 0%,
      ${theme.palette.primary.dark} 100%)`, borderRadius: '8px'} : {backgroundImage: `linear-gradient(0deg,
    ${theme.palette.grey['500']} 0%,
    ${theme.palette.grey['400']} 100%)`, borderRadius: '8px'}}>
      {(!loadAllImage || name === 'Sprout') && <Box sx={{ p:1 }}>
        {selectedSeedball === null && <Button disabled={name !== 'Sprout'} onClick={()=>{openDrawer(name)}} sx={{border: (theme) => `dashed 1px ${theme.palette.divider}`, backgroundColor: theme.palette.grey[600], width: '100%', height: '200px', ':hover': {
        backgroundColor: theme.palette.grey[800],
        border:"0"
      }}}>
          { name === 'Sprout' && <AddIcon fontSize="large" sx={{color: 'text.primary'}}/>}
        </Button>}
        {selectedSeedball && <ProductImgStyle id={`image-selected-${name}`} alt={name} src={selectedSeedball} />}
      </Box>}
      {loadAllImage && name !== 'Sprout' && <Box>
        <ProductImgStyle id={`image-selected-${name}`} alt={name} src={image} />
        </Box>}
      <Stack sx={{ py: 1, px: 2 }}>
          <Typography fontSize='16px' fontWeight='600' lineHeight='24px' color={name === 'Sprout'? 'text.primary':'grey.800'} variant="body2">
            {name}
          </Typography>
          {/* <Typography color={name === 'Sprout'? 'text.primary':'grey.700'} variant="caption" noWrap>
            Quantity: {quantity}
          </Typography> */}

        {/* {iconi !== 0 && <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography color={name === 'Sprout'? 'text.primary':'grey.700'} variant="caption">
            {iconi}.0000 ICONI
          </Typography>
        </Stack>}
        {iconi === 0 && <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography color={name === 'Sprout'? 'text.primary':'grey.700'} variant="caption">
            Created from {type === 'Care'? 'Nurture': ''}
          </Typography>
        </Stack>} */}
      </Stack>
    </Card>}
    {type === 'Repair' && <Card sx={!['Creator', 'Producer', 'Master'].includes(name)?
    {backgroundColor: `#161C24`, borderRadius: '8px', mx: isMobile ? 3 : 'auto'} : {backgroundColor: `#161C24`, borderRadius: '8px', mx: isMobile ? 3 : 'auto'}}>
      {['Creator', 'Producer', 'Master'].includes(name) && <Box sx={{ p:1 }}>
        {selectedSeedball === null && <Button sx={{backgroundColor: `#161C24`, cursor: 'default', padding:0, width: '100%', height: '200px', ':hover': {
        backgroundColor: `#161C24`
      }}}>
          <ContainerImage src={"/static/seedball/"+ name.toLowerCase() +"-container-thumb.jpg"}/>
          {/* <AddIcon fontSize="large" sx={{color: 'text.primary'}}/> */}
        </Button>}
        {selectedSeedball && <ProductImgStyle id={`image-selected-${name}`} alt={name} src={selectedSeedball} />}
      </Box>}
      {!['Creator', 'Producer', 'Master'].includes(name) && <Box sx={{ p:1 }}>
      <Button  sx={{backgroundColor: `#161C24`, width: '100%', height: '200px', ':hover': {
        backgroundColor: `#161C24`
      }}}>
        <Box height={'100px'}
             width={'100px'}
             borderRadius={'50%'}
             alignItems={'center'}
             justifyContent={'center'}
             display={'flex'}
             fontFamily={'"Rye", cursive'}
             color={'black'}
             backgroundColor={theme.palette.warning.main}
             flexDirection={'column'}
             fontSize="24px"
              >
          <h2>{name.split('.')[0]} </h2>
        </Box>
        </Button>
        
      </Box>}
      <Stack sx={{ py: 1, px: 2, minHeight:'80px', backgroundColor:theme.palette.grey[800], justifyContent:'center' }}>
          <Typography fontSize='16px' fontWeight='600' lineHeight='24px' color={name === 'Sprout'? 'text.primary':'#fff'} variant="body2">
            {name}
          </Typography>
          {['Creator', 'Producer', 'Master'].includes(name) && <>
          <Typography color={'#fff'} variant="caption" noWrap>
            Quantity: {quantity}
          </Typography>
          <Stack direction="row">
          <Typography color={'#fff'} variant="caption" noWrap>
             Status: 
          </Typography>
          <IconStyle sx={{ mr: 1}}/>
          <Typography color={'#fff'} variant="caption" noWrap>
             Halted
          </Typography>
          </Stack>
          </>}
          
      </Stack>
    </Card>}
    {name === 'Sprout' && <SeedBallContainerList openContainerList={open} drawerDetails={container} updateImage={updateImage} onCloseContainer={handleCloseContainer} />}
    </>
  );
}
