import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    isLongLoading: false,
    isError: false
}

const slice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
       // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // START LONG LOADING
        startLongLoading(state) {
          state.isLongLoading = true;
        },

        stopLongLoading(state) {
            state.isLongLoading = false;
        },

        showError(state) {
          state.isError = true;
        },

        hideError(state) {
          state.isError = false;
        }

    }
})

export default slice.reducer;

export const showLoader = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.startLoading());
    }
  }
  export const hideLoader = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.stopLoading());
    }
  }

  export const showLongLoader = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.startLongLoading());
    }
  }
  export const hideLongLoader = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.stopLongLoading());
    }
  }

  export const showErrorPage = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.showError());
    }
  }
  export const hideErrorPage = () => {
    return async (dispatch)=>{
      dispatch(slice.actions.hideError());
    }
  }
 