import { createSlice } from "@reduxjs/toolkit";
import { getContainerAssets } from "./container";
import { hideLoader, hideLongLoader, showLoader, showLongLoader, showErrorPage, hideErrorPage } from "./loader";
import { getPaymentsData } from "./seedball";
import { getBalanceFromEOSIO } from "./user";
import * as eosjsAccountName from 'eosjs-account-name';

const initialState = {
    isLoading: false,
    error: false,
    repairDeals: [],
    repairDealsById: {},
    dealRepaired: false,
    dealUnpacked: false
}

function objFromArray(array, key = 'id') {
    return array.reduce((accumulator, current) => {
      accumulator[current[key]] = current;
      return accumulator;
    }, {});
}

const slice = createSlice({
    name: 'repair',
    initialState,
    reducers: {
       // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }, 

        setRepairDeals(state, action) {
            state.isLoading = false;
            state.repairDeals = action.payload;
            state.repairDealsById = objFromArray(action.payload, 'id');
        },

        setDealRepaired(state, action) {
          state.isLoading = false;
          state.dealRepaired = action.payload;
        },

        setDealUnpacked(state, action) {
          state.isLoading = false;
          state.dealUnpacked = action.payload;
        }
    }
})

export default slice.reducer;

export const setRepairStatusFalse = () => {
  return async (dispatch)=>{
    dispatch(slice.actions.setDealRepaired(false));
    dispatch(slice.actions.setDealUnpacked(false));
  }
}

export const getRepairDeals = (session) => {
    return async (dispatch)=>{
        dispatch(showLoader());
        try {
          const ualType = localStorage.getItem('UALLoggedInAuthType');
          const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
          const repairDeals = await rpcEndpoint.get_table_rows({
            json: true,               // Get the response as json
            code: process.env.REACT_APP_GAME_CONTRACT,      // Contract that we target
            scope: process.env.REACT_APP_GAME_CONTRACT,         // Account that owns the data
            table: 'repairs',        // Table name
            limit: 1000,          // Optional: Show ram payer
          }).then(res=>res.rows);
          await dispatch(slice.actions.setRepairDeals(repairDeals));
          dispatch(hideLoader());
        } catch (error) {
          dispatch(hideLoader());
          dispatch(slice.actions.hasError(error));
        }
      }
  }

  export const getRepairDealsById = async (session) => {
      try {
        const ualType = localStorage.getItem('UALLoggedInAuthType');
        const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
        return rpcEndpoint.get_table_rows({
          json: true,               // Get the response as json
          code: process.env.REACT_APP_GAME_CONTRACT,      // Contract that we target
          scope: process.env.REACT_APP_GAME_CONTRACT,         // Account that owns the data
          table: 'repairs',        // Table name
          limit: 1000,          // Optional: Show ram payer
        }).then(res=> {
          console.log(res.rows);
          return objFromArray(res.rows, 'id')
        });
      } catch (error) {
        return [];
      }
  }

  export const getPayments = async (session, username) => {
    try {
      let next_key ='';
      let more = true;
      let payments = [];
      while(more){
        const ualType = localStorage.getItem('UALLoggedInAuthType');
        const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
       let res = await rpcEndpoint.get_table_rows({
        json: true,
        code: process.env.REACT_APP_GAME_CONTRACT,
        scope: process.env.REACT_APP_GAME_CONTRACT,
        table: "payments",
        limit: 1000,
        lower_bound : next_key
       }).catch(e => { 
          throw e;
        } );
    
       if(res && res.rows){
        payments = payments.concat(res.rows.filter(obj=>obj.account === username && obj.type === 'Repair deal'));
        more = res.more;
        next_key = res.next_key;
       }
      }
      return payments;
     } catch (error) {
      console.log(`Error fetching container table data, err:`, error);
      throw error;
     }
  }

export const sendMoney = (session, username, cost, id) => {
  // // return async (dispatch) => {
  //   dispatch(slice.actions.startLoading());
    try {
       return session.signTransaction(
        {
            actions: [{
              account: process.env.REACT_APP_TOKEN,
              name: 'transfer',
              authorization: [{
                  actor: username,
                  permission: session.requestPermission
              }],
                data: {
                  from: username,
                  to: process.env.REACT_APP_GAME_CONTRACT,
                  quantity: cost,
                  memo: id
                }
            }]
        },
        {
            blocksBehind: 3,
            expireSeconds: 30
        }
    );
    } catch (error) {
      throw error;
    }
  // }
}

export const sendAtomicAssets = (session, username, assetIds, id) => {
    try {
       return session.signTransaction(
        {
            actions: [{
              account: 'atomicassets',
              name: 'transfer',
              authorization: [{
                  actor: username,
                  permission: session.requestPermission
              }],
                data: {
                  from: username,
                  to: process.env.REACT_APP_GAME_CONTRACT,
                  asset_ids: assetIds,
                  memo: id
                }
            }]
        },
        {
            blocksBehind: 3,
            expireSeconds: 30
        }
    );
    } catch (error) {
      throw error;
    }
}

export const claimRepair = (session, username, assetId) =>{
  return async (dispatch)=>{
    try {
      await dispatch(hideErrorPage());
      await session.signTransaction(
        {
            actions: [{
                account: process.env.REACT_APP_GAME_CONTRACT,
                name: 'claimrepair',
                authorization: [{
                    actor: username,
                    permission: session.requestPermission
                }],
                data: {
                  unbox_id: assetId
                }
            }]
        },
        {
            blocksBehind: 3,
            expireSeconds: 30
        }
    );
    await dispatch(showLongLoader());
    await dispatch(slice.actions.setDealUnpacked(true));
    await new Promise(resolve => setTimeout(resolve, 5000));
    await dispatch(getContainerAssets(username, session));
    await dispatch(hideLongLoader());
    } catch (error) {
      dispatch(hideLongLoader());
      dispatch(showErrorPage());
    }
  }
}

export const claimRepairEosio = (session, username, assetId) =>{
  
    try {
      return session.signTransaction(
        {
            actions: [{
                account: process.env.REACT_APP_GAME_CONTRACT,
                name: 'claimrepair',
                authorization: [{
                    actor: username,
                    permission: session.requestPermission
                }],
                data: {
                  unbox_id: assetId
                }
            }]
        },
        {
            blocksBehind: 3,
            expireSeconds: 30
        }
    );
    } catch (error) {
      throw error;
    }
}

export const getPaymentsDataFromTable = (username,session) => {
  const ualType = localStorage.getItem('UALLoggedInAuthType');
  const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
  return rpcEndpoint.get_table_rows({
    json: true,
    code: process.env.REACT_APP_GAME_CONTRACT,
    scope: process.env.REACT_APP_GAME_CONTRACT,
    table: "payments",
    limit: 500,
    key_type: 'i64',
    index_position: 2,
    lower_bound: eosjsAccountName.nameToUint64(username),
    upper_bound : eosjsAccountName.nameToUint64(username)
   }).then(res=> res.rows.filter(obj=>obj.type==='Repair deal'))
   .catch(() => { 
      return [];
    });
}
 