import { Typography } from "@mui/material";

export const harvestinfo = <div>
	<Typography variant="h5" color={'#14a6a5'}>
		Mission task
	</Typography>
	<Typography mb={1}>
		You will get reward tokens from Harvest NFTs.At the End of each harvast cycle you have to claim the rewards to start the new cycle.There are defined Harvast NFTs in multiple catagories and rarities. More details can be found in the White Paper.

	</Typography>
</div>;


export const harvestinfoTitleText = <div>
	<Typography mb={2}>
		Well done Friend !
	</Typography>
	<Typography mb={2}>
		You have cared and nurtured your Seedball into a full harvest. Now is the time to start the harvest cycles and reap the rewards of your hard work and dedication. Keep it up and you might become one of the only 200 Champions our worlds will select.
	</Typography>
	<Typography>
		Good Luck Friend - or should I say - "FUTURE CHAMPION" ?
	</Typography>
</div>