// material
import { Container, Grid, Button, Typography, Box, LinearProgress, Stack, Card, useMediaQuery, Dialog, IconButton, Slide } from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'src/redux/store';
import React, { useEffect, useState } from 'react';
import { MotionInView, varWrapEnter, varZoomIn, varFadeInUp, varFadeIn } from 'src/components/animate';
import { useNavigate, useParams } from 'react-router';
import { claimHarvest, getHarvestAssets, startHarvest } from 'src/redux/slices/harvest';
import { seedballStatusFalse, setAssetQuantityToInitialState, setHarvestBoxStatusFalse, sproutedFalse } from 'src/redux/slices/seedball';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useTheme } from '@emotion/react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { setRepairStatusFalse } from 'src/redux/slices/repair';
import Page500 from '../Page500';
import { hideLongLoader, showLongLoader } from 'src/redux/slices/loader';
import BoostDrawer from '../BoostDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { getBoostDetails, getHarvestBoosts } from 'src/redux/slices/boost';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/seedball-background.jpg)',
  padding: theme.spacing(10, 0),
  height: '100%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0)
  },
  [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0)
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/static/seedball/mobile_harvest_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  marginTop: -80,
  boxShadow: 'none',
  padding: theme.spacing(5),
  paddingTop: theme.spacing(10),
  color: theme.palette.common.white,
  backgroundImage: `linear-gradient(180deg,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.dark} 100%)`,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
    }
}));

const DataContentStyle = styled(Card)(({ theme }) => ({
  marginTop: -85,
  boxShadow: 'none',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  backgroundImage: `linear-gradient(180deg,
    ${theme.palette.dark} 0%,
    ${theme.palette.dark} 100%)`
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  zIndex: 9,
  position: 'relative',
  width: 250,
  [theme.breakpoints.down('sm')]: {
    transform: 'scale(.6)',
    transformOrigin: 'right'
  }
}));

const DialogImageStyle = styled("img")(({ theme }) => ({
  margin: 'auto'
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function HarvestDataCard({dataCardDetails}) {
  return (<div>
    <Stack direction="row" paddingRight={3} alignItems="center" justifyContent="flex-end">
    <Box
        component="img"
        src={dataCardDetails.image}
        sx={{
          zIndex: 9,
          position: 'relative',
          width: 100
        }}
      />
    </Stack>
    <DataContentStyle>
        <Typography color='success.light' variant="h5">
          {dataCardDetails.name}
        </Typography>
      
      <Typography variant="caption" sx={{py:1}}>
         {dataCardDetails.text}
      </Typography>
    </DataContentStyle>
  </div>);
}
  
function HarvestProgressCard({data, userDetails, boostId}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar}=useSnackbar();
  const startOrClaimHarvest = async() => {
    try {
      dispatch(showLongLoader());
      if(data.status === 'Available') {
        await dispatch(startHarvest(userDetails.session, userDetails.name, data.assetId, boostId));
        enqueueSnackbar(`Congratulations, you started harvesting ${data?.name} with asset id ${data.assetId} successfully`, { variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
      } else if(data.status === 'Harvest'){
        await dispatch(claimHarvest(userDetails.session, userDetails.name, data.assetId));
        enqueueSnackbar(`Congratulations, you claimed your harvest for ${data?.name} successfully`, { variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
      }
      dispatch(hideLongLoader());
    } catch (error) {
      dispatch(hideLongLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }
  const getSup = (count) => {
    if(count === 1) return 'st';
    if(count === 2) return 'nd';
    if(count === 3) return 'rd';
    if(count >= 4) return 'th';
  }
  return (<div>
    <Stack direction="row" paddingRight={3} alignItems="center" justifyContent="flex-end">
    <MotionInView sx={{zIndex:'9'}} variants={varFadeInUp}>
      <BoxStyle
        component="img"
        src={`/static/seedball/harvest-page-${data?.name.toLowerCase().trim(' ')}.png`}
      />
      </MotionInView>
    </Stack>
    {data.remainingCount !== data.totalCount && <ContentStyle>
        <Typography variant="h3" pb={3}>
        {data.status === 'Available'?'Start':data.remainingCount + 1}<sup>{data.status === 'Available'?'':getSup(data.remainingCount+1)}</sup> Harvest Progress
        </Typography>
      {data.status !== 'Available' && <LinearProgress
        variant="determinate"
        value={data.percent}
        sx={{height: '12px', backgroundImage: 'url("/static/seedball/progress-bar-harvest-fruitpage.gif")', backgroundSize: "8px", backgroundRepeat: 'repeat-x', backgroundPosition: 'center'}}
        color= {'warning'}
      />}
      {data.status !== 'Available' &&<Typography variant="body2" sx={{py:1}}>
         Remaining time: {data.status === 'Available' ?'24 hours (0%)' : `${data.time} (${data.percent}%)`}
      </Typography>}
      <Stack direction="row" spacing={1} alignItems="center" justifyContent={data.status === 'Available' ?'flex-start' : 'flex-end'}>
        <Button disabled={data.status === 'In Progress'} onClick={startOrClaimHarvest} color="warning" variant="contained">
          {data.status === 'Available' ?'Start Harvest' : 'Claim Harvest'}
        </Button>
      </Stack>
    </ContentStyle>}
    {data.remainingCount === data.totalCount && <ContentStyle>
      <Typography variant="h3" pb={3}>
         Thank you!
      </Typography>
      <Typography variant="body2" sx={{py:1}}>
        You have harvested all cycles for {data.name} successfully.
        </Typography>
      </ContentStyle>}
  </div>);
}
export default function HarvestProgress({ual}) {
  const dispatch = useDispatch();
  const {assetId} = useParams();
  const harvestDetails = useSelector((store) => store.harvest.harvestAssetById);
  const [progressDetails, setProgressDetails] = useState(harvestDetails[assetId]);
  const sideBarStatus = useSelector((store) => store.seedball.sideBarStatus);
  const UserState = useSelector((store) => store.user.userDetails);
  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();
  const navigateToHarvest = () => {
    navigate(PATH_DASHBOARD.general.harvest);
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showErrorPage = useSelector(state=> state.loader.isError);
  const [showError, setshowError] = useState(false);
  const [openBoostFilter, setBoostOpenFilter] = React.useState(false);
  const [boost, setBoost] = useState([]);
  const [openBoostDialog, setOpenBoostDialog] = useState(false);
  const [boostId, setBoostId] = useState(0);
  const [selectedBoost, setSelectedBoost] = useState({});
  const closeDialog = () => {
    setOpenBoostDialog(false);
  }
  useEffect(()=>{
    dispatch(seedballStatusFalse());
    dispatch(sproutedFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(setHarvestBoxStatusFalse());
    dispatch(setRepairStatusFalse());
    dispatch(getHarvestAssets(UserState.name, UserState.session));
  },[dispatch, UserState.session, UserState.name]);
  
  useEffect(()=>{
    setProgressDetails(harvestDetails[assetId]);
  },[harvestDetails, assetId])

  useEffect(()=>{
    if(progressDetails){
      const fetchData = async()=>{
        setDataList([
          {name: progressDetails.rarity, text: 'Rarity', image: '/static/seedball/vegetables.png'},
          {name: (progressDetails.totalCount - progressDetails.remainingCount) || 0, text: 'Remaining harvest', image: '/static/seedball/farmer.png'},
          {name: (progressDetails.iconipercycle * (progressDetails.totalCount - progressDetails.remainingCount)) || 0, text: 'Remaining ICONI', image: '/static/seedball/money-bag.png'}
        ])
        const boosts = await getHarvestBoosts(UserState.name, UserState.session);
        const boostDetails = await getBoostDetails('harvestboost', UserState.name);
        setBoost(boosts);
        if(progressDetails.boostId){
          setBoostId(progressDetails.boostId);
          const detail = boostDetails.find(obj=>obj.asset_id===progressDetails.boostId);
          setSelectedBoost({value: detail.data.value, name:detail.name});
        }
        if(boosts.length > 0 && progressDetails.status === 'Available') {
          setOpenBoostDialog(true)
        }
      }
      fetchData();
    }
    
  },[progressDetails])

  const closeFlter = () => {
    setBoostOpenFilter(false)
  }

  useEffect(()=>{
    setshowError(showErrorPage);
  },[showErrorPage]);
  
  const updateBoost = (data) => {
    setSelectedBoost(data);
    setBoostId(data.assetIds[0]);
  }
  return (
  <>
  {!showError &&<RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
  <Page title="Harvest" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      <Box sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        top: isMobile ? '-15px' : '30px',
        left: isMobile ? '10px' : (sideBarStatus.toggle && sideBarStatus.hover ? '200px' : '97px')}}>
          <Button variant="contained" onClick={navigateToHarvest}>
              Back
          </Button>
          <Button onClick={()=>{
            dispatch(getHarvestAssets(UserState.name, UserState.session));
            }} variant="contained" sx={{marginLeft:3}}>
            <RefreshIcon/>
          </Button>
        </Box>
        {progressDetails && ['Available'].includes(progressDetails.status) && <Box 
          component="img"
          onClick={()=>{setBoostOpenFilter(true)}}
          src={selectedBoost && selectedBoost.name?`/static/seedball/${selectedBoost.name.includes(50)?50:(selectedBoost.name.includes(25)?25:10)}-boost.png`: '/static/seedball/apply-boost.png'}
          sx={{
            mt: isMobile ? 0 : 2,
            position: 'absolute',
            width: '100px',
            cursor: 'pointer',
            top: isMobile ? '-50px' : '30px',
            right: isMobile ? '10px' : '97px'}}>
        </Box>}
        {progressDetails && ['In Progress', 'Harvest'].includes(progressDetails.status) && (progressDetails.boostId !== null && progressDetails.boostId !== undefined && progressDetails.boostId !== 0 )&& <Box
      component="img"
      src={selectedBoost && selectedBoost.name?`/static/seedball/${selectedBoost.name.includes(50)?50:(selectedBoost.name.includes(25)?25:10)}-boost.png`: '/static/seedball/apply-boost.png'}
      sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        width: '100px',
        pointer: 'none',
        top: isMobile ? '-50px' : '30px',
        right: isMobile ? '10px' : '97px'}}>
      </Box>}

        <BoostDrawer isOpenFilter={openBoostFilter} boost={boost.filter(el=>+el.quantity > 0)} onCloseFilter={closeFlter} type="Harvest" selectedBoost={selectedBoost} updateBoost={updateBoost}></BoostDrawer>
      <Container>
        <Grid container direction="row" spacing={3} justifyContent="space-around">
          <Grid item xs={12} md={8} lg={9}>
            <Box sx={{ pl: 5, position: 'absolute', mt: 4}}>
              <MotionInView variants={varFadeIn}>
                <Typography variant="h3" sx={{color: 'success.light'}}>
                  {progressDetails?.name}
                </Typography>
              </MotionInView>
              <Typography variant="caption">
                ID: {assetId}
              </Typography>
            </Box>
            {progressDetails && <HarvestProgressCard data={progressDetails} boostId={boostId} userDetails={UserState}/>}
          </Grid>
          
          <Grid item sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}} md={4} lg={3} xs={12}>
          {dataList.map((data, index)=>
            <motion.div key={index} variants={varZoomIn}>
              <HarvestDataCard dataCardDetails={data}/>
            </motion.div> 
          )}
          </Grid>
        </Grid>
        <Typography variant="caption" sx={{py:1}}>
         Screen may take some time to update blockchain data. Please be patient, and hit refresh after few seconds to get the latest updates.
      </Typography>
      </Container>
      <Dialog
        disablebackdropclick="true"
        open={openBoostDialog}
        TransitionComponent={Transition}>
        
          <Container sx={{margin: '30px 0'}}>
          <IconButton
              edge="start"
              color="default"
              sx={{
                right: 5,
                top: 5,
                position: 'absolute'
              }}
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon/>
              </IconButton>
              <DialogImageStyle
            src="/static/seedball/harvest-boost.png"
            alt="harvest boost"
          ></DialogImageStyle>
            <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
                It's time to boost.
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
                You have harvest boosts in your inventory. Use the boost to harvest your seedball.
            </Typography>
            <Box sx={{mt: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Button sx={{mr: 2}} variant="contained" onClick={()=>{setBoostOpenFilter(true); closeDialog()}}>
                  Use Boost
              </Button>
              <Button color="warning" 
              sx={{float: 'right',
              background: (theme) => theme.palette.grey[500],
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey[600],
                borderColor: (theme) => theme.palette.grey[600],
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
                backgroundColor: (theme) => theme.palette.grey[600],
                borderColor: (theme) => theme.palette.grey[600],
              },
              '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
              }
            }}
            onClick={closeDialog} variant="contained">
                  Later
              </Button>
            </Box>
          </Container>
      </Dialog>
      
    </Page>
  </RootStyle>}
  {showError && <Page500 ual={ual}/>}
  </>
  );
}
