// material
import { Box, Button, Container, Typography, useMediaQuery, Grid } from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useState } from 'react';
import {  varWrapEnter } from 'src/components/animate';
import { seedballStatusFalse, setAssetQuantityToInitialState, setHarvestBoxStatusFalse, sproutedFalse } from 'src/redux/slices/seedball';
import TopDataCards from '../../components/seedballgame/TopDataCards';
import { getHarvestAssets, harvestAll } from 'src/redux/slices/harvest';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import InfoDialog from '../InfoDialog';
import { harvestinfo, harvestinfoTitleText } from '../seedball/harvestinfo';
import { useTheme } from '@emotion/react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { setRepairStatusFalse } from 'src/redux/slices/repair';
import { useSnackbar } from 'notistack';
import Page500 from '../Page500';
import { hideLongLoader, showLongLoader } from 'src/redux/slices/loader';
import FooterBanner from '../FooterBanner';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/seedball-background.jpg)',
  padding: theme.spacing(10, 0),
  height: '100%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0)
  },
  [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0)
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/static/seedball/mobile_harvest_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));

export default function HarvestLayout({ual}) {
  const dispatch = useDispatch();
  const UserState = useSelector((store) => store.user.userDetails);
  const harvests = useSelector((store) => store.harvest.harvestAssets);
  const {enqueueSnackbar}=useSnackbar();
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const showErrorPage = useSelector(state=> state.loader.isError);
  const [showError, setshowError] = useState(false);
  const navigateToInventory = () => {
    navigate({pathname:PATH_DASHBOARD.general.inventory, search:'?type=harvest'});
  }
  const sideBarStatus = useSelector((store) => store.seedball.sideBarStatus);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const harvestAllAssets = async () => {
    try {
      const harvestAssets = harvests.filter(obj=> obj.status.toLowerCase() === 'harvest').slice(0, 10) || [];
      if(harvestAssets.length > 0){
        dispatch(showLongLoader());
        await dispatch(harvestAll(UserState.session, UserState.name, harvestAssets));
        enqueueSnackbar(`Congratulations, you claimed all harvests successfully`, { variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
        dispatch(hideLongLoader());
      } else {
        enqueueSnackbar(`You dont have any harvests to claim.`, { variant: 'info', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
      }
      
    } catch (error) {
      dispatch(hideLongLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }
  useEffect(()=>{
    dispatch(seedballStatusFalse());
    dispatch(sproutedFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(setHarvestBoxStatusFalse());
    dispatch(getHarvestAssets(UserState.name, UserState.session));
    dispatch(setRepairStatusFalse());
  },[dispatch, UserState.session, UserState.name]);

  useEffect(() => {
    const sortedData = harvests.slice().sort((a, b) => {
      if (a.statusOrder === b.statusOrder) {
        return b.percent - a.percent;
     }
     return a.statusOrder > b.statusOrder ? 1 : -1;
    });
    if(sortedData.length > 5) {
      let newArray = sortedData.slice(0,5);
      const updatedArray = [newArray[3], newArray[1], newArray[0], newArray[2],newArray[4]];
      setAssets(updatedArray);
    } else {
      if(sortedData.length === 3){
        const updatedArray = [sortedData[1], sortedData[0], sortedData[2]];
        setAssets(updatedArray);
      } else if(sortedData.length === 4){
        const updatedArray = [sortedData[2], sortedData[0], sortedData[1], sortedData[3]];
        setAssets(updatedArray);
      }else if(sortedData.length === 5){
        const updatedArray = [sortedData[3], sortedData[1], sortedData[0], sortedData[2],sortedData[4]];
      setAssets(updatedArray);
      } else{
        setAssets(sortedData);
      }
    }
  },[harvests])

  useEffect(()=>{
    setshowError(showErrorPage);
  },[showErrorPage]);

  const showBanner = (flag) => {
    let rootStyle = document.getElementById('root-style');
    if(rootStyle) 
      rootStyle.style.height = !flag ? '100%' : isMobile ? '92%' : '85%';
  };



  return (
  <>
  {!showError && <RootStyle id="root-style" initial="initial" animate="animate" variants={varWrapEnter}>
  <Page title="Harvest" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      <Container>
      <Box sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        top: isMobile ? '-15px' : '30px',
        left: isMobile ? '10px' : (sideBarStatus.toggle && sideBarStatus.hover ? '200px' : '97px')}}>
          <Button onClick={()=>{dispatch(getHarvestAssets(UserState.name, UserState.session))}} variant="contained">
            <RefreshIcon/>
          </Button>
      </Box>
      <Typography gutterBottom color='primary.main' variant="h3" sx={{ textAlign: 'center' , mx: 'auto' }}>
            Harvest
            <InfoDialog title="Harvest" message={harvestinfo} titleText={harvestinfoTitleText}/>
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 3 }}>
            Earn reward from harvest cycle.
        </Typography>
        <TopDataCards assets={assets}/>
        <Grid container>
          <Grid item xs={12} md={6.6}>
            <Box sx={{margin:'30px 0 40px 0'}} textAlign={{xs:"center",sm:"center",md:"right"}}>
              <Button color="primary" variant="contained" onClick={harvestAllAssets}>
                  Batch harvest
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={5.3}>
          {harvests.length > 5 && 
            <Box sx={{margin:'30px 0 40px 0'}} pr={{xs:0, sm:0, md:12}} textAlign={{xs:"center",sm:"center",md:"right"}}>
              <Button color="inherit" variant="outlined" onClick={navigateToInventory} >
                  View all harvest
              </Button>
            </Box> }
          </Grid>
        </Grid>
        
      </Container>
    </Page>
  </RootStyle>}
  {!showError && <FooterBanner showData={showBanner}/>}
  {showError && <Page500 ual={ual}/>}
  </>
  );
}
