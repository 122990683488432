import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { ShopFilterSidebar, ShopProductList } from "src/components/_dashboard/e-commerce/shop";
import { filterAllResources, getSimpleAssets } from "src/redux/slices/seedball";

function applyFilter(resources, filters) {
  let nurtureResources = (filters.nurture && filters.nurture.length > 0) ?
    resources.filter(obj => (((obj.category === 'Nurture' && obj.name !== 'Seed ball') || obj.name === 'Sprout') && +obj.quantity>0) && filters.nurture.includes(obj.subCategory))
    : resources.filter(obj => ((obj.category === 'Nurture' && obj.name !== 'Seed ball') || obj.name === 'Sprout') && +obj.quantity>0);
  return nurtureResources.length > 0 ? [...nurtureResources] : [];
}

export default function NurtureTab() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [nurtureFilters, setNurtureFilters] = React.useState({ nurture: [] });
  const { simpleAssets, filters } = useSelector((state) => state.seedball);
  const UserState = useSelector((store) => store.user.userDetails);
  const filteredProducts = applyFilter([...simpleAssets], nurtureFilters);
  const formik = useFormik({
    initialValues: {
      nurture: [],
    },
    onSubmit: () => {
      
    }
  });

  const { values, resetForm, initialValues } = formik;

  React.useEffect(() => {
    dispatch(getSimpleAssets(UserState.session));
    setNurtureFilters(filters);
  }, [dispatch, UserState.name, UserState.session, filters]);

  React.useEffect(() => {
    dispatch(filterAllResources(values));
  }, [dispatch, values]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    resetForm();
  };

  return (
    <>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ShopFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
        </Stack>
      </Stack>
      <ShopProductList products={filteredProducts} isLoad={!filteredProducts && !initialValues} />
    </>
  )
}
