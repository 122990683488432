
import { styled } from '@mui/material/styles';
// material
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setPlayerLogout } from 'src/redux/slices/user';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page500({ual}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      dispatch(setPlayerLogout(ual));
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <RootStyle title="Error">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Oops!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>An unexpected error has occurred... please logout and login. </Typography>

          {/* <SeverErrorIllustration sx={{ height: 200, my: { xs: 5, sm: 10 } }} /> */}

          <Button size="large" onClick={handleLogout} style={{marginTop: '30px'}} variant="contained">
            Logout
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
