import { createSlice } from "@reduxjs/toolkit";
import { applicationConfig } from '../../config';
import { getContainerData } from "./container";
import { getHarvestData } from "./harvest";

const initialState = {
    isLoading: false,
    error: false,
    boostContainerAssets: [],
    boostHarvestAssets: []
}

const slice = createSlice({
    name: 'boost',
    initialState,
    reducers: {
       // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }, 

        setBoostContainerAssets(state, action) {
            state.isLoading = false;
            state.boostContainerAssets = action.payload;
        },

        setBoostHarvestAssets(state, action) {
            state.isLoading = false;
            state.boostHarvestAssets = action.payload;
        }
    }
})

export default slice.reducer;
const groupBy = (items, property) => items.reduce(
  (acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
       acc[key] = [];
    }
    // Add object to list for given key's value

    acc[key].push(obj);
    return acc;
 }, {});

export const getContainerBoosts = async (username, session) => {
  
      try {
        const assets = await fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/accounts/${username}/${applicationConfig.collectionName}`)
        .then(response => response.json())
        .then(res => {
          const templateIds = res.data.templates.map(obj=>obj.template_id);
          return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=nurtureboost&owner=${username}&template_whitelist=${templateIds.join('%2c')}&page=1&limit=1000&order=desc&sort=asset_id`)
          .then(response => response.json())
          .then(async(res) => {
            let mappedNurtureBoost = [];
            res.data = res.data.filter(obj=> obj.mutable_data && obj.mutable_data['remaining-boost'] !== 0)
            const containersFromTable = await getContainerData(username, session);
            res.data = res.data.sort((a,b) => (a.name < b.name)?1:-1)
            let groupedNurtureBoosts = groupBy(res.data,'name');
            for (const key in groupedNurtureBoosts) {
              if (Object.hasOwnProperty.call(groupedNurtureBoosts, key)) {
                mappedNurtureBoost.push({
                  name: key,
                  assetIds: groupedNurtureBoosts[key].map(obj=> obj.asset_id).filter(obj=> containersFromTable.findIndex(el=> el.boost_id === obj) < 0),
                  quantity: groupedNurtureBoosts[key].reduce((count,obj)=>{
                    if(obj.mutable_data && obj.mutable_data['remaining-boost']){
                      count += obj.mutable_data['remaining-boost'];
                    } else{
                      count += obj.data['total-boost-count'];
                    }
                    if(containersFromTable.findIndex(el=> el.boost_id === obj.asset_id) >= 0){
                      count -= 1;
                    }
                    return count;
                  }, 0),
                  value: key.includes('50%') ? 50 : (key.includes('25%')? 25: 10),
                  image: `/static/seedball/${key.includes('50%')? '50-nurture-boost': (key.includes('25%') ? '25-nurture-boost' : '10-nurture-boost')}.png`,
                  type: 'Nurture',
                  category: 'Boost'
                })
              }
            }
            return mappedNurtureBoost;
          });
        });
        return assets;
      } catch (error) {
        return [];
      }
}

export const getHarvestBoosts = async (username, session) => {
  try {
    const assets = await fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/accounts/${username}/${applicationConfig.collectionName}`)
    .then(response => response.json())
    .then(res => {
      const templateIds = res.data.templates.map(obj=>obj.template_id);
      return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=harvestboost&owner=${username}&template_whitelist=${templateIds.join('%2c')}&page=1&limit=1000&order=desc&sort=asset_id`)
      .then(response => response.json())
      .then(async(res) => {
        let mappedHarvestBoost = [];
        res.data = res.data.filter(obj=> obj.mutable_data && obj.mutable_data['remaining-boost'] !== 0)
        const harvestsFromTable = await getHarvestData(username, session);
        res.data = res.data.sort((a,b) => (a.name < b.name)?1:-1)
            let groupedHarvestBoosts = groupBy(res.data,'name');
            for (const key in groupedHarvestBoosts) {
              if (Object.hasOwnProperty.call(groupedHarvestBoosts, key)) {
                mappedHarvestBoost.push({
                  name: key,
                  assetIds: groupedHarvestBoosts[key].map(obj=> obj.asset_id).filter(obj=> harvestsFromTable.findIndex(el=> el.boost_id === obj) < 0),
                  quantity: groupedHarvestBoosts[key].reduce((count,obj)=>{
                    if(obj.mutable_data && obj.mutable_data['remaining-boost']){
                      count += obj.mutable_data['remaining-boost'];
                    } else{
                      count += obj.data['total-boost-count'];
                    }
                    if(harvestsFromTable.findIndex(el=> el.boost_id === obj.asset_id) >= 0){
                      count -= 1;
                    }
                    return count;
                  }, 0),
                  value: key.includes('50%') ? 50 : (key.includes('25%')? 25: 10),
                  image: `/static/seedball/${key.includes('50%')? '50-harvest-boost': (key.includes('25%') ? '25-harvest-boost' : '10-harvest-boost')}.png`,
                  type: 'Harvest',
                  category: 'Boost'
                })
              }
            }
            return mappedHarvestBoost;
      });
    });
    return assets;
  } catch (error) {
    return [];
  }
}

export const getBoostDetails = async(type, username) => {
  try {
    const assets = await fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/accounts/${username}/${applicationConfig.collectionName}`)
    .then(response => response.json())
    .then(res => {
      const templateIds = res.data.templates.map(obj=>obj.template_id);
      return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=${type}&owner=${username}&template_whitelist=${templateIds.join('%2c')}&page=1&limit=1000&order=desc&sort=asset_id`)
      .then(response => response.json())
      .then(async(res) => {
        
            return res.data;
      });
    });
    return assets;
  } catch (error) {
    return [];
  }
}