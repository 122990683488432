// material
import { Container, Grid, Button, Typography, Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import SeedballFTCard from './SeedballFTCard';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useState } from 'react';
import { buyAll, craftSeedball, getSimpleAssets, getSimpleAssetsWithoutDispatch, seedballStatusFalse, setAssetQuantity, setAssetQuantityToInitialState, setHarvestBoxStatusFalse, sproutedFalse } from 'src/redux/slices/seedball';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { MotionInView, varWrapEnter, varFadeInDown, varRotateIn } from 'src/components/animate';
import { useSnackbar } from 'notistack';
import InfoDialog from '../InfoDialog';
import { seedballinfo, seedballInfoTitleText } from './seedballinfo';
import { useTheme } from '@emotion/react';
import { setRepairStatusFalse } from 'src/redux/slices/repair';
import { Player } from '@lottiefiles/react-lottie-player';
import Page500 from '../Page500';
import { hideLoader, showLoader } from 'src/redux/slices/loader';
import { fetchData } from 'src/utils/awsFunction';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/container-background.jpg)',
  padding: theme.spacing(10, 0),
  height: '100%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0)
  },
  [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0)
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/static/seedball/mobile_seedball_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));

export default function Seedball({ual}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const UserState = useSelector((store) => store.user.userDetails);
  const seedballCreated = useSelector((store) => store.seedball.isSeedballCreated);
  let seedballData = [{name:'Water spray', code: '1008', type: 'Seedball', value: 'SPRAY', quantity: '1', image: '/static/seedball/spray.jpg', iconi: 2, isSelected: false},
  {name:'Soil', code: '1005', type: 'Seedball', value: 'SOIL', image: '/static/seedball/soil.jpg', quantity: '1',  iconi: 2, isSelected: false},
  {name:'Vermicompost', code: '1006', type: 'Seedball', value: 'VERMI', image: '/static/seedball/vermi.jpg', quantity: '1', iconi: 2, isSelected: false},
  {name:'Seed', code: '1004', type: 'Seedball', value: 'SEED', image: '/static/seedball/seed.jpg', quantity: '1', iconi: 2, isSelected: false},
  {name:'Sun light', code: '1007', type: 'Seedball', value: 'LIGHT', image: '/static/seedball/sunlight.jpg', quantity: '1', iconi: 2, isSelected: false}
]
  const [isSeedballCreated, setIsSeedballCreated] = useState(false);
  const [showCreateSeedballButton, setShowCreateSeedballButton] = useState(false);
  const [isBuyAllButtonEnabled, setIsBuyAllButtonEnabled] = useState(false);
  const [loadAllImage, setLoadAllImage] = useState(false);
  const navigate = useNavigate();
  const assetQuantity = useSelector((store) => store.seedball.assetQuantity);
  const sideBarStatus = useSelector((store) => store.seedball.sideBarStatus);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const showErrorPage = useSelector(state=> state.loader.isError);
  const [showError, setshowError] = useState(false);
  useEffect(()=>{
    dispatch(getSimpleAssets(UserState.session));
    dispatch(sproutedFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(setHarvestBoxStatusFalse());
    dispatch(setRepairStatusFalse());
    if(seedballCreated){
      setIsSeedballCreated(true);
    }
  },[dispatch, UserState.session, seedballCreated]);

  useEffect(()=>{
    const fetchData = async () => {
      const simpleAssets = await getSimpleAssetsWithoutDispatch(UserState.session);
      if(assetQuantity['SEEDKIT'] === null){
        const filterAssets = simpleAssets.filter(obj => obj.author === process.env.REACT_APP_FT_SALE && obj.balance.includes('SEEDKIT'));
        dispatch(setAssetQuantity('SEEDKIT', filterAssets.length > 0 ? filterAssets[0].balance.split('SEEDKIT')[0] : 0));
      }
    }
    fetchData();
  },[])

  const createSeedball = async () => {
    try {
      dispatch(showLoader());
      await dispatch(craftSeedball(UserState.session, UserState.name));
      enqueueSnackbar(`Congratulations, you created seedball successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
    
  }

  const navigateToSeedball = () => {
    setIsSeedballCreated(false);
    setLoadAllImage(false);
    setShowCreateSeedballButton(false);
    dispatch(seedballStatusFalse());
  }

  const navigateToContainers = () => {
    navigate(PATH_DASHBOARD.general.container);
  }

  const updateSeedballImage = (obj) => {
    const elements = document.querySelectorAll('[id^="image-selected"]');
    setShowCreateSeedballButton((elements.length + 1) === 5);
    setIsBuyAllButtonEnabled(true);
  }

  const buyAllResources = async () => {
    try {
      if(assetQuantity.SEEDKIT === null || assetQuantity.SEEDKIT === 0){
        dispatch(showLoader());
        await dispatch(buyAll(UserState.session, UserState.name, 'seedballitems', assetQuantity));
        dispatch(hideLoader());
      enqueueSnackbar(`Congratulations, you bought all seedball resources successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      }
      
      setLoadAllImage(true);
      setTimeout(()=>{
        const elements = document.querySelectorAll('[id^="image-selected"]');
    setShowCreateSeedballButton((elements.length) === 5);
      },0);
      
    
    } catch (error) {
      dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  useEffect(()=>{
    setshowError(showErrorPage);
  },[showErrorPage]);

  return (
    <>
    {!showError && <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
    <Page title="Seedball" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      {!isSeedballCreated && 
      <Container>
        <Typography color='primary.main' gutterBottom variant="h3" sx={{ textAlign: 'center' , mx: 'auto' }}>
                Create Seedball
                <InfoDialog title="CREATE SEED BALL" message={seedballinfo} titleText={seedballInfoTitleText}/>
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 3 }}>
                Buy all required material and resources for 10.0000 ICONI.
        </Typography>
        {!showCreateSeedballButton && isMobile && <Box sx={{textAlign: 'center', my: 3}}>
          <Button color="warning" disabled={isBuyAllButtonEnabled}  variant="contained" onClick={buyAllResources}>
                  {assetQuantity.SEEDKIT !== null && assetQuantity.SEEDKIT > 0 ? 'Use Seed ball Kit' : 'Buy Seed ball Kit'}
          </Button>
        </Box>}
        {showCreateSeedballButton && isMobile && <Box sx={{textAlign: 'center', mt: 5}}>
          <Button  variant="contained" onClick={createSeedball}>
                  Create Seed ball 
          </Button>
        </Box>}
        <Grid container spacing={3} px={{xs:9, sm:14, md:0}} sx={{justifyContent: 'center'}}>
           {seedballData.map((data, index) => (
            <Grid key={data.name} item xs={12} sm={6} md={2}>
              <motion.div variants={varFadeInDown}>
                <SeedballFTCard container={data} loadAllImage={loadAllImage} updateSeedballImage={updateSeedballImage} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
        {!showCreateSeedballButton && !isMobile && <Box sx={{textAlign: 'center', mt: 5}}>
          <Button color="warning" disabled={isBuyAllButtonEnabled}  variant="contained" onClick={buyAllResources}>
          {assetQuantity.SEEDKIT !== null && assetQuantity.SEEDKIT > 0 ? 'Use Seed ball Kit' : 'Buy Seed ball Kit'}
          </Button>
        </Box>}
        {showCreateSeedballButton && !isMobile && <Box sx={{textAlign: 'center', mt: 5}}>
          <Button  variant="contained" onClick={createSeedball}>
                  Create Seed ball 
          </Button>
        </Box>}
        
      </Container>}
      {isSeedballCreated && 
      <Container>
        <Player
          autoplay
          loop
          src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
          style={{ height: '70%', width: '70%', position:'absolute', right:'0', left:"0", zIndex:'9' }}
        >
        </Player>
        <Box sx={{
        mt: isMobile ? 0 : 2,
        position: 'absolute',
        top: isMobile ? '-50px' : '30px',
        left: isMobile ? '10px' : (sideBarStatus.toggle && sideBarStatus.hover ? '200px' : '97px')}}>
          <Button variant="contained" onClick={navigateToSeedball}>
              Back
          </Button>
      </Box>
        <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
            Congratulations! You made one seedball.
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
            You are all ready to start a container.
        </Typography>
          
        <MotionInView variants={varRotateIn} sx={{
          width: 'fit-content',
          margin: 'auto'
        }}>
          <Box
            component="img"
            src="/static/seedball/Seedball.png"
            sx={{
              width: 200,
              borderRadius: '10px',
              display: 'block',
              filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
            }}
          />
        </MotionInView>
        <Box sx={{textAlign: 'center', mt: 5}}>
          <Button variant="contained" onClick={navigateToContainers} sx={{zIndex:'99'}}>
              Nurture
          </Button>
        </Box>
      </Container>}
    </Page>
    </RootStyle>}
    {showError && <Page500 ual={ual}/>}
    </>
  );
}
