import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { ShopFilterSidebar, ShopProductList } from "src/components/_dashboard/e-commerce/shop";
import { filterAllResources } from "src/redux/slices/seedball";
import { getHarvestBoosts, getContainerBoosts } from "src/redux/slices/boost";
import { hideLoader, showLoader } from "src/redux/slices/loader";


function applyFilter(resources, filters) {
  let boostResources = ((filters.boost && filters.boost.length > 0) || (filters.power && filters.power.length > 0)) ?
    resources.filter(obj=> obj.category === 'Boost' && (filters.boost.includes(obj.type) || filters.power.includes(obj.value+'%')))
    : resources.filter(obj => obj.category === 'Boost')
  return boostResources.length > 0 ? [...boostResources]: [];
}

export default function BoostTab() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [boostFilter, setBoostFilter] = React.useState({ boost: [], power: [] });
  const filters = useSelector((state) => state.seedball.filters);
  const [boosts, setBoosts] = useState([]);
  const UserState = useSelector((store) => store.user.userDetails);
  let filteredProducts = applyFilter([...boosts], boostFilter);
  const formik = useFormik({
    initialValues: {
      boost: [],
      power: []
    },
    onSubmit: () => {
      
    }
  });

  const { values, resetForm, initialValues } = formik;

  React.useEffect(() => {
    const fetchData = async () => {
      showLoader();
      const nurtureBoosts = await getHarvestBoosts(UserState.name, UserState.session);
      const harvestBoosts = await getContainerBoosts(UserState.name, UserState.session);
      setBoostFilter(filters);
      setBoosts([...nurtureBoosts, ...harvestBoosts]);
      filteredProducts = applyFilter([...boosts], boostFilter);
      hideLoader();
    }
    fetchData();
  }, [dispatch, UserState.name, UserState.session, filters]);

  React.useEffect(() => {
    dispatch(filterAllResources(values));
  }, [dispatch, values]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    resetForm();
  };

  return (
    <>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <ShopFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
          />
        </Stack>
      </Stack>
      <ShopProductList products={filteredProducts} isLoad={!filteredProducts && !initialValues} />
    </>
  )
}
