import { createSlice } from "@reduxjs/toolkit";
import { applicationConfig } from '../../config';
import { showLoader, hideLoader, showErrorPage, hideErrorPage } from "./loader";
import { getSimpleAssets, setSproutedData } from "./seedball";
import * as eosjsAccountName from 'eosjs-account-name';
import { getBoostDetails } from "./boost";

function objFromArray(array, key = 'assetId') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
    isLoading: false,
    error: false,
    containerAssets: [],
    containerAssetById: {}
}

const slice = createSlice({
    name: 'container',
    initialState,
    reducers: {
       // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }, 

        setContainerAssets(state, action) {
            state.isLoading = false;
            state.containerAssets = action.payload;
            state.containerAssetById = objFromArray(action.payload, 'assetId');
        }
    }
})

export default slice.reducer;

export const getContainerData = async (username, session) => {
  const ualType = localStorage.getItem('UALLoggedInAuthType');
  const rpcEndpoint = ualType && ualType === 'wax' ?  session.wax.rpc : session.rpc;
  return rpcEndpoint.get_table_rows({
    json: true,               // Get the response as json
    code: process.env.REACT_APP_GAME_CONTRACT,      // Contract that we target
    scope: process.env.REACT_APP_GAME_CONTRACT,         // Account that owns the data
    table: 'containers',        // Table name
    limit: 1000,                // Maximum number of rows that we want to get
    key_type: 'i64',
    index_position: 2,
    lower_bound: eosjsAccountName.nameToUint64(username),
    upper_bound : eosjsAccountName.nameToUint64(username),         // Optional: Show ram payer
    }).then(res=> res.rows || [])
   .catch ((error)=> {
    console.log(`Error fetching container table data, err:`, error);
    throw error;
   });
  }   

const getEndTime = (startTime, delaySeconds, boostDetails) => {
  if(boostDetails && boostDetails.data && boostDetails.data.value){
    const boostTimeInSeconds = delaySeconds - (delaySeconds * boostDetails.data.value /100)
    return new Date((startTime + boostTimeInSeconds) * 1000);
  } else{
    return new Date((startTime + delaySeconds) * 1000);
  }
}

  const getPercent = (resourceDetails, remainingCount, boostDetails) => {
    if(remainingCount === 0){
      return 0;
    } else if(resourceDetails && resourceDetails.start_time !== 0 && new Date().getTime() < getEndTime(resourceDetails.start_time, resourceDetails.delay_seconds, boostDetails)){
        const today = Date.parse(new Date());
        const start = resourceDetails.start_time * 1000;
        const end = getEndTime(resourceDetails.start_time, resourceDetails.delay_seconds, boostDetails);
        return Math.round(((today - start) / (end - start)) * 100);
      } else if(resourceDetails && resourceDetails.start_time !== 0 &&  new Date().getTime() >= getEndTime(resourceDetails.start_time, resourceDetails.delay_seconds, boostDetails)){
        return 100;
      } else {
        return 0;
      }
  }

  const getStatus = (resourceDetails, remainingCount, boostDetails) => {
    if(remainingCount === 0){
      return 'Halted';
    } else if(resourceDetails && resourceDetails.start_time !== 0 && new Date().getTime() < getEndTime(resourceDetails.start_time, resourceDetails.delay_seconds, boostDetails)){
        return 'In Progress';
      } else if(resourceDetails && resourceDetails.start_time !== 0 && new Date().getTime() >= getEndTime(resourceDetails.start_time, resourceDetails.delay_seconds, boostDetails)){
        return 'Sprout';
      } else {
        return 'Available';
      }
  }

  const getColor = (resourceDetails, remainingCount, boostDetails) => {
    if(remainingCount === 0){
      return 'error.main';
    } else if(resourceDetails && resourceDetails.start_time !== 0 && new Date().getTime() < getEndTime(resourceDetails.start_time, resourceDetails.delay_seconds, boostDetails)){
        return 'primary.main';
      } else if(resourceDetails && resourceDetails.start_time !== 0 && new Date().getTime() >= getEndTime(resourceDetails.start_time, resourceDetails.delay_seconds, boostDetails)){
        return 'info.main';
      } else {
        return 'warning.main';
      }
  }

  const getImage = (name) => {
    if(name.toLowerCase() === 'master'){
      return '/static/seedball/master-container-thumb.jpg';
    } else if(name.toLowerCase() === 'creator'){
      return '/static/seedball/creator-container-thumb.jpg';
    } else if(name.toLowerCase() === 'producer'){
      return '/static/seedball/producer-container-thumb.jpg';
    } else if(name.toLowerCase() === 'propagator'){
      return '/static/seedball/propagator-container-thumb.jpeg';
    }
  }

  export const startContainer = (session, username, assetId, boostId) => {
    return async (dispatch)=>{
      try {
        await dispatch(hideErrorPage());
        await session.signTransaction(
          {
              actions: [{
                  account: process.env.REACT_APP_GAME_CONTRACT,
                  name: 'startnh',
                  authorization: [{
                      actor: username,
                      permission: session.requestPermission
                  }],
                  data: {
                      player: username,
                      asset_id: assetId,
                      boost_id: boostId || ''
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      await dispatch(getContainerAssets(username, session));
      await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (error) {
        throw error;
        // throw error;
      }
    }
  }

  export const sproutContainer = (session, username, assetId) => {
    return async (dispatch)=>{
      try {
        await dispatch(hideErrorPage());
        await session.signTransaction(
          {
              actions: [{
                  account: process.env.REACT_APP_GAME_CONTRACT,
                  name: 'sprout',
                  authorization: [{
                      actor: username,
                      permission: session.requestPermission
                  }],
                  data: {
                      asset_owner: username,
                      asset_id: assetId
                  }
              }]
          },
          {
              blocksBehind: 3,
              expireSeconds: 30
          }
      );
      await dispatch(getSimpleAssets(session));
      await dispatch(setSproutedData({message: 'Sprouted successfully'}));
      await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (error) {
        throw error;
      }
    }
  }

export const getContainerAssets = (username, session) => {
    
    return async (dispatch) => {
        // dispatch(slice.actions.startLoading());
        await dispatch(hideErrorPage());
        await dispatch(showLoader());
        try {
          const statusOrderObject = { 'Sprout': 0, 'In Progress': 1, 'Available': 2, 'Halted': 3}
          const assets = await fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/accounts/${username}/${applicationConfig.collectionName}`)
          .then(response => response.json())
          .then(res => {
            const templateIds = res.data.templates.map(obj=>obj.template_id);
            return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=container&owner=${username}&template_whitelist=${templateIds.join('%2c')}&page=1&limit=1000&order=desc&sort=asset_id`)
            .then(response => response.json())
            .then(async(res) => {
              const containersFromTable = await getContainerData(username, session);
              const allBoosts = await getBoostDetails('nurtureboost', username);
              res.data = res.data.sort((a,b)=>(a.minted_at_time > b.minted_at_time? -1: 1)); 
              return res.data.map((obj)=>{
                const containerObject = containersFromTable.find(el=>el.asset_id === obj.asset_id);  
                let boostDetails = {} 
              if(containerObject && containerObject.boost_id && allBoosts.length>0){
                boostDetails = allBoosts.find(el=> el.asset_id === containerObject.boost_id);
              }
                const newObj = {
                  assetId: obj.asset_id,
                  name: obj.name,
                  image: getImage(obj.name),
                  code: obj.data.code,
                  totalCount: obj.data.life,
                  boostId: containerObject ? containerObject['boost_id'] : 0,
                  powerSlot: obj.data['power slot'],
                  remainingCount: +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life,
                  percent: getPercent(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails),
                  status: getStatus(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails),
                  color: getColor(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails),
                  statusOrder: statusOrderObject[getStatus(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails)],
                  title: 'Life',
                  navigateTo: `${obj.asset_id}`,
                  category: 'Container',
                  subCategory: obj.name
                }
                return newObj;
              });
            });
          });
          await dispatch(slice.actions.setContainerAssets(assets));
          await dispatch(hideLoader());
        } catch (error) {
          dispatch(hideLoader());
          dispatch(showErrorPage());
        }
      };
}

export const getContainerAssetsEosio = async (username, session) => {
      try {
        const statusOrderObject = { 'Sprout': 0, 'In Progress': 1, 'Available': 2, 'Halted': 3}
        const assets = await fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/accounts/${username}/${applicationConfig.collectionName}`)
        .then(response => response.json())
        .then(res => {
          const templateIds = res.data.templates.map(obj=>obj.template_id);
          return fetch(`${applicationConfig.atomichubBaseUrl}atomicassets/v1/assets?collection_name=${applicationConfig.collectionName}&schema_name=container&owner=${username}&template_whitelist=${templateIds.join('%2c')}&page=1&limit=1000&order=desc&sort=asset_id`)
          .then(response => response.json())
          .then(async(res) => {
            const containersFromTable = await getContainerData(username, session);
            const allBoosts = await getBoostDetails('nurtureboost', username);
            res.data = res.data.sort((a,b)=>(a.minted_at_time > b.minted_at_time? -1: 1)); 
            return res.data.map((obj)=>{
              const containerObject = containersFromTable.find(el=>el.asset_id === obj.asset_id); 
              let boostDetails = {} 
              if(containerObject && containerObject.boost_id){
                boostDetails = allBoosts.find(el=> el.asset_id === containerObject.boost_id);
              }
              const newObj = {
                assetId: obj.asset_id,
                name: obj.name,
                image: getImage(obj.name),
                code: obj.data.code,
                totalCount: obj.data.life,
                powerSlot: obj.data['power slot'],
                boostId: containerObject ? containerObject['boost_id'] : 0,
                remainingCount: +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life,
                percent: getPercent(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails),
                status: getStatus(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails),
                color: getColor(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails),
                statusOrder: statusOrderObject[getStatus(containerObject, +obj.mutable_data['remaininglife'] >= 0 ?+obj.mutable_data['remaininglife'] : +obj.data.life, boostDetails)],
                title: 'Life',
                navigateTo: `${obj.asset_id}`,
                category: 'Container',
                subCategory: obj.name
              }
              return newObj;
            });
          });
        });
        return assets;
      } catch (error) {
        return [];
      }
}