// material
import { Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useLayoutEffect, useState } from 'react';
import {  varWrapEnter } from 'src/components/animate';
import { seedballStatusFalse, setAssetQuantityToInitialState, setHarvestBoxStatusFalse, sproutedFalse } from 'src/redux/slices/seedball';

import InfoDialog from '../InfoDialog';
import { getRepairDeals, setRepairStatusFalse } from 'src/redux/slices/repair';
import RepairDataCard from 'src/components/seedballgame/RepairDataCard';
import { repairinfo, repairinfoTitleText } from './repaitInfo';
import Page500 from '../Page500';

// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/repair-backgorund.jpg)',
  padding: theme.spacing(10, 0),
  height: '100%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0)
  },
  [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0)
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/static/seedball/mobile_repair_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));

export default function RepairLayout({ual}) {
  const dispatch = useDispatch();
  const UserState = useSelector((store) => store.user.userDetails);
  const repairDeals = useSelector((store) => store.repair.repairDeals);
  const [assets, setAssets] = useState([]);
  const showErrorPage = useSelector(state=> state.loader.isError);
  const [showError, setshowError] = useState(false);
  useEffect(()=>{
    dispatch(seedballStatusFalse());
    dispatch(sproutedFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(setHarvestBoxStatusFalse());
    dispatch(setRepairStatusFalse());
    dispatch(getRepairDeals(UserState.session));
  },[dispatch, UserState.session, UserState.name]);

  useLayoutEffect(() => {
    setAssets(repairDeals);
  },[repairDeals])

  useEffect(()=>{
    setshowError(showErrorPage);
  },[showErrorPage]);

  return (
  <>
  {!showError && <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
  <Page title="Harvest" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      <Container>
      <Typography gutterBottom color='primary.main' variant="h3" sx={{ textAlign: 'center' , mx: 'auto' }}>
            Recycle Center
            <InfoDialog title="Recycle Center" message={repairinfo} titleText={repairinfoTitleText}/>
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 7 }}>
           Hurry! Avail the deals before it is gone. <Link href="https://the-iconical.gitbook.io/project-seed-ball/recycle-center" target="_blank" variant="subtitle2">
      Learn more
    </Link>
        </Typography>
        <RepairDataCard assets={assets}/>
      </Container>
    </Page>
  </RootStyle>}
  {showError && <Page500 ual={ual}/>}
  </>
  );
}
