import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { Box, Backdrop, Paper, Divider, Typography, Stack, Grid, Link, Button } from '@mui/material';
//
import { MIconButton } from 'src/components/@material-extend';
import { useDispatch, useSelector } from 'react-redux';
import SeedballBuyCard from './SeedballBuyCard';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { setAssetQuantity } from 'src/redux/slices/seedball';
// import { Player, Controls } from '@lottiefiles/react-lottie-player';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 350;

SeedBallContainerList.propTypes = {
    openContainerList: PropTypes.bool,
    onCloseContainer: PropTypes.func
  };
  
export default function SeedBallContainerList({ openContainerList, onCloseContainer, drawerDetails, updateImage }) {
  const dispatch = useDispatch();
  const UserState = useSelector((store) => store.user.userDetails);
  const selectedAssetQuantity = useSelector((store) => store.seedball.assetQuantity); 
  const navigate = useNavigate();

  useEffect(() => {
    if (openContainerList) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [dispatch, openContainerList, UserState.name, drawerDetails.value]);

  const onSelectSeedball = () => {
    if(selectedAssetQuantity[drawerDetails.value] > 0){
      onCloseContainer();
      dispatch(setAssetQuantity(drawerDetails.value, selectedAssetQuantity[drawerDetails.value] - 1));
      updateImage(drawerDetails.image, drawerDetails.name, drawerDetails.index || 0);
    }
  }

  const navigateTo = () => {
    navigate(drawerDetails.type === 'Power' ? PATH_DASHBOARD.general.seedball : PATH_DASHBOARD.general.nurture);
  }

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openContainerList} onClick={onCloseContainer} />

      <Box
        sx={{
          top: 12,
          bottom: 12,
          right: 0,
          position: 'fixed',
          zIndex: 2001,
          ...(openContainerList && { right: 12 })
        }}
      >

        <Paper
          sx={{
            display: 'flex',
           flexDirection: 'column',
            height: 1,
            width: '0px',
            overflow: 'hidden',
            boxShadow: (theme) => theme.customShadows.z24,
            transition: (theme) => theme.transitions.create('width'),
            ...(openContainerList && { width: DRAWER_WIDTH })
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
            <Typography variant="subtitle1">{drawerDetails.type}</Typography>
            <MIconButton onClick={onCloseContainer}>
              <Icon icon={closeFill} width={20} height={20} />
            </MIconButton>
          </Stack>
          <Divider />
          {+selectedAssetQuantity[drawerDetails.value] === 0 && drawerDetails.type !== 'Repair' && 
          <>
          <Stack sx={{margin: 'auto'}}>
            {drawerDetails.iconi !== 0 && <SeedballBuyCard cardDetails={drawerDetails}/>}
            {drawerDetails.iconi === 0 && <Button onClick={navigateTo} variant="contained" fullWidth={true}>Create {drawerDetails.type === 'Power' ? 'Seedball' : 'Sprout'}</Button>}
          </Stack>
          </>
          }
          {+selectedAssetQuantity[drawerDetails.value] > 0 && drawerDetails.type !== 'Repair' && <><Grid container sx={{p:5}}>
                <Grid item xs={5} md={5} lg={5}>
                <Link variant="body2" underline="none" component="button" color="text.secondary" onClick={onSelectSeedball}>
                <Box
                  component="img"
                  src={drawerDetails.image}
                  sx={{
                    left: 40,
                    width: 100,
                    borderRadius: '10px',
                    filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
                  }}
                />
                {/* <Player
                  autoplay
                  loop
                  src="https://assets5.lottiefiles.com/packages/lf20_elqubb3f.json"
                  style={{ height: '300px', width: '300px' }}
                >
                </Player> */}
                </Link>
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  <Typography variant="h4" gutterBottom>
                    {drawerDetails.name}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    Available quantity
                  </Typography>
                  <Typography variant="h2" gutterBottom>
                    {+selectedAssetQuantity[drawerDetails.value]}
                  </Typography>
                </Grid>
              </Grid>
              <Stack sx={{flex: 1}}></Stack>
              {drawerDetails.iconi !== 0 && <Stack direction="row" sx={{pb: 5}} justifyContent="center">
                <SeedballBuyCard cardDetails={drawerDetails}/>
              </Stack>}
              {drawerDetails.iconi === 0 && <Stack direction="row" sx={{pb: 5, px:2}} justifyContent="center">
                <Button onClick={navigateTo} variant="contained" fullWidth={true}>Create {drawerDetails.type === 'Power' ? 'Seedball' : 'Sprout'}</Button>
              </Stack>}
              </>}
              {
                drawerDetails.type === 'Repair' && <Grid container sx={{p:5}}>
                <Grid item xs={5} md={5} lg={5}>
                <Link variant="body2" underline="none" component="button" color="text.secondary" onClick={onSelectSeedball}>
                <Box
                  component="img"
                  src={drawerDetails.image}
                  sx={{
                    left: 40,
                    width: 100,
                    borderRadius: '10px',
                    filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
                  }}
                />
                {/* <Player
                  autoplay
                  loop
                  src="https://assets5.lottiefiles.com/packages/lf20_elqubb3f.json"
                  style={{ height: '300px', width: '300px' }}
                >
                </Player> */}
                </Link>
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  <Typography variant="h4" gutterBottom>
                    {drawerDetails.name}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    Available quantity
                  </Typography>
                  <Typography variant="h2" gutterBottom>
                    {+selectedAssetQuantity[drawerDetails.value]}
                  </Typography>
                </Grid>
              </Grid>
              }
        </Paper>
      </Box>
    </>
  );
}
