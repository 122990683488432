import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import Seedball from 'src/pages/seedball/Seedball';
import ContainerNurture from 'src/pages/container/ContainerNurture';
import EcommerceShop from 'src/pages/dashboard/EcommerceShop';
import HarvestProgress from 'src/pages/harvest/HarvestProgress';
import SeedballCare from 'src/pages/seedball/SeedballCare';
import HarvestLayout from 'src/pages/harvest';
import ContainerLayout from 'src/pages/container';
// import UserProfile from 'src/pages/dashboard/UserProfile';
import RepairLayout from 'src/pages/repair';
import StartRepair from 'src/pages/repair/StartRepair';
import SeedballDashboard from 'src/pages/seedball/SeedballDashboard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/seedball');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router({ual}) {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'seedball',
      element: (
        <AuthGuard ual={ual}>
          <DashboardLayout ual={ual}/>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/seedball/nurture" replace /> },
        { path: 'nurture', element: <ContainerLayout ual={ual}/>},
        { path: 'nurture/:assetId', element: <ContainerNurture ual={ual}/>},
        { path: 'seedball', element: <Seedball ual={ual}/>},
        { path: 'harvest', element: <HarvestLayout ual={ual}/>},
        { path: 'harvest/:assetId', element: <HarvestProgress ual={ual}/>},
        { path: 'inventory', element: <EcommerceShop ual={ual}/>},
        { path: 'care', element: <SeedballCare ual={ual}/>},
        // { path: 'dashboard', element: <SeedballDashboard ual={ual}/>},
        // { path: 'profile', element: <UserProfile/>},
        { path: 'recycle', element: <RepairLayout ual={ual}/>},
        { path: 'recycle/:id', element:<StartRepair ual={ual}/>}
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <MainLayout ual={ual} />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout ual={ual} />,
      children: [
        { element: <LandingPage /> },
        { path: 'game', element: <ComingSoon /> },
        { path: 'training', element: <ComingSoon /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
