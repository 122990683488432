// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function MaintenanceIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box component="img" src="/static/seedball/maintenance-projectseedball.png" sx={{margin: '0 auto'}}>
    </Box>
  );
}
