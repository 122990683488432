// material
import { Container, Grid, Button, Typography, Box, Slide, Dialog, useMediaQuery } from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../../components/Page';
import { motion } from 'framer-motion';
import SeedballFTCard from './SeedballFTCard';
import { useDispatch, useSelector } from 'src/redux/store';
import React, { useEffect, useState } from 'react';
import { buyAll, careSeedballNFT, claimSprout, getPaymentsDataFromTable, getSimpleAssets, getSimpleAssetsWithoutDispatch, seedballStatusFalse, setAssetQuantity, setAssetQuantityToInitialState, sproutedFalse } from 'src/redux/slices/seedball';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { MotionInView, varWrapEnter, varZoomIn, varFadeInDown } from 'src/components/animate';
import { useSnackbar } from 'notistack';
import InfoDialog from '../InfoDialog';
import { careInfo, careInfoTitleText } from './careInfo';
import { Player } from '@lottiefiles/react-lottie-player';
import { getHarvestAssets, getHarvestAssetsFromEosio } from 'src/redux/slices/harvest';
import { hideLoader, hideLongLoader, showLoader, showLongLoader } from 'src/redux/slices/loader';
import { useTheme } from '@emotion/react';
// ----------------------------------------------------------------------
const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/seedball/seedball-background.jpg)',
  padding: theme.spacing(10, 0),
  height: '100%',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0)
  },
  [theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0)
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/static/seedball/mobile_harvest_bg.jpg)',
    backgroundAttachment: 'fixed'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SeedballCare() {
  const dispatch = useDispatch();
  const UserState = useSelector((store) => store.user.userDetails);
  const harvests = useSelector((store) => store.harvest.harvestAssets);
  let seedballData = [{name:'Sprout', code: '1009', type: 'Care', value: 'SPROUT', quantity: '1', image: '/static/seedball/care-sprout.png', iconi: 0, isSelected: false},
  {name:'Fence', code: '1015', type: 'Care', value: 'FENCE', image: '/static/seedball/care-fence.jpg', quantity: '1',  iconi: 2, isSelected: false},
  {name:'Sprinkler', code: '1016', type: 'Care', value: 'SPRINKL', image: '/static/seedball/care-sprinkler.jpg', quantity: '1', iconi: 2, isSelected: false},
  {name:'Scarecrow', code: '1017', type: 'Care', value: 'SCARECR', image: '/static/seedball/care-scarecrow.jpg', quantity: '1', iconi: 2, isSelected: false},
  {name:'Temperature', code: '1018', type: 'Care', value: 'TEMPERA', image: '/static/seedball/care-temperature.jpg', quantity: '1', iconi: 2, isSelected: false},
  {name:'PH Level', code: '1019', type: 'Care', value: 'PHMETER', image: '/static/seedball/care-phlevel.jpg', quantity: '1', iconi: 2, isSelected: false}
]
  const [isSeedballCreated, setIsSeedballCreated] = useState(false);
  const [hasUnpacked, setHasUnpacked] = useState(false);
  const [fruit, setFruit] = useState({});
  const [showCreateSeedballButton, setShowCreateSeedballButton] = useState(false);
  const [isBuyAllButtonEnabled, setIsBuyAllButtonEnabled] = useState(false);
  const [loadAllImage, setLoadAllImage] = useState(false);
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const assetQuantity = useSelector((store) => store.seedball.assetQuantity);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(()=>{
    dispatch(getSimpleAssets(UserState.session));
    dispatch(seedballStatusFalse());
    dispatch(sproutedFalse());
    dispatch(setAssetQuantityToInitialState());
    dispatch(getHarvestAssets(UserState.name, UserState.session));
  },[]); 
  useEffect(()=>{
    const fetchData = async () => {
      const simpleAssets = await getSimpleAssetsWithoutDispatch(UserState.session);
      if(assetQuantity['CAREKIT'] === null){
        const filterAssets = simpleAssets.filter(obj => obj.author === process.env.REACT_APP_FT_SALE && obj.balance.includes('CAREKIT'));
        dispatch(setAssetQuantity('CAREKIT', filterAssets.length > 0 ? filterAssets[0].balance.split('CAREKIT')[0] : 0));
      }
    }
    fetchData();
  },[])   

  const careSeedball = async () => {
    try {
      dispatch(showLongLoader());
      await dispatch(careSeedballNFT(UserState.session, UserState.name));
      enqueueSnackbar(`Congratulations, you have protected your seedball successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      setIsSeedballCreated(true);
      dispatch(hideLongLoader());
    } catch (error) {
      dispatch(hideLongLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  const getLatestHarvest = async(harvestAssetId, count) => {
      if(count>=5 ) return {};
    const harvestList = await getHarvestAssetsFromEosio(UserState.name, UserState.session);
    if(harvestList.length === 0){
      let newCount = count + 1;
      await new Promise(resolve => setTimeout(resolve, 10000));
     return getLatestHarvest(harvestAssetId, newCount);
    } else if(harvestList[0].assetId === harvestAssetId){
        let newCount = count + 1;
        await new Promise(resolve => setTimeout(resolve, 10000));
       return getLatestHarvest(harvestAssetId, newCount);
    } else{
        return harvestList[0];
    }
  }

  const unpack = async () => {
    try {
      dispatch(showLongLoader());
      const filterPayments = await getPaymentsDataFromTable(UserState.name,UserState.session)
      const harvestList = await getHarvestAssetsFromEosio(UserState.name, UserState.session);
      localStorage.setItem('harvestAssetId', harvestList.length > 0 ? harvestList[0].assetId : null);
      if(filterPayments.length === 0){
        enqueueSnackbar(`We are processing the data, kindly wait or go to inventory to unbox it.`, { variant: 'warning', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        } });
      } else{
        const unboxId = Math.max.apply(Math, filterPayments.map((obj)=> obj.id));
        await claimSprout(UserState.session, UserState.name, unboxId);
        const harvestAssetId = localStorage.getItem('harvestAssetId');
        const fruitObj = await getLatestHarvest(harvestAssetId, 0);
        if(fruitObj && fruitObj.name){
            setFruit(fruitObj);
            setHasUnpacked(true);
            enqueueSnackbar(`Congratulations, you have unpacked the harvest successfully.`, { variant: 'success', anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              } });
        } else{
            enqueueSnackbar(`It is taking more time than expected, kindly go to Inventory to unbox after some time.`, { variant: 'warning', anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              } });
              setLoadAllImage(false);
              setShowCreateSeedballButton(false);
              setIsBuyAllButtonEnabled(false);
        }
        setIsSeedballCreated(false);
        dispatch(hideLongLoader());
      }
    } catch (error) {
      dispatch(hideLongLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  const navigateToHarvest = () => {
    navigate(`${PATH_DASHBOARD.general.harvest}/${fruit.assetId}`);
  }

  const updateSeedballImage = (obj) => {
    const elements = document.querySelectorAll('[id^="image-selected"]');
    setShowCreateSeedballButton((elements.length + 1) >= 6);
    if(obj.value !== 'SPROUT'){
      setIsBuyAllButtonEnabled(true);
    }
  }

  const buyAllResources = async () => {
    try {
      if(assetQuantity.CAREKIT === null || assetQuantity.CAREKIT === 0){
      dispatch(showLoader());
      await dispatch(buyAll(UserState.session, UserState.name, 'careitems', assetQuantity));
      enqueueSnackbar(`Congratulations, you bought all care resources successfully`, { variant: 'success', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
      dispatch(hideLoader());
      }
      setLoadAllImage(true);
      setIsBuyAllButtonEnabled(true);
      setTimeout(()=>{
        const elements = document.querySelectorAll('[id^="image-selected"]');
        setShowCreateSeedballButton((elements.length) === 6);
      },0);
    } catch (error) {
      dispatch(hideLoader());
      enqueueSnackbar(`There was some error in processing request : ${error.message}`, { variant: 'error', anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      } });
    }
  }

  return (
    <>
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
    <Page title="Seedball" sx={{position: 'relative',
     height: '100%', 
     display: 'flex',
     flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}}>
      {!isSeedballCreated && !hasUnpacked && 
      <Container>
        <Typography color='primary.main' gutterBottom variant="h3" sx={{ textAlign: 'center' , mx: 'auto' }}>
                Care
                <InfoDialog title="Care" message={careInfo} titleText={careInfoTitleText}/>
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 3 }}>
          Buy all required material and resources for 10.0000 ICONI.
        </Typography>
        {!showCreateSeedballButton && isMobile && <Box sx={{textAlign: 'center', my: 3}}>
          <Button color="warning" disabled={isBuyAllButtonEnabled}  variant="contained" onClick={buyAllResources}>
          {assetQuantity.CAREKIT !== null && assetQuantity.CAREKIT > 0 ? 'Use Care Kit' : 'Buy Care Kit'}
          </Button>
        </Box>}
        {showCreateSeedballButton && isMobile && <Box sx={{textAlign: 'center', mt: 5}}>
          <Button  variant="contained" onClick={careSeedball}>
          Care
          </Button>
        </Box>}
        <Grid container spacing={3} px={{xs:9, sm:14, md:0}} sx={{justifyContent: 'center'}}>
           {seedballData.map((data, index) => (
            <Grid key={data.name} item xs={12} sm={6} md={2}>
              <motion.div variants={varFadeInDown}>
                <SeedballFTCard container={data} loadAllImage={loadAllImage} updateSeedballImage={updateSeedballImage} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
        {!showCreateSeedballButton && !isMobile && <Box sx={{textAlign: 'center', mt: 5}}>
          <Button color="warning" disabled={isBuyAllButtonEnabled}  variant="contained" onClick={buyAllResources}>
          {assetQuantity.CAREKIT !== null && assetQuantity.CAREKIT > 0 ? 'Use Care Kit' : 'Buy Care Kit'}
          </Button>
        </Box>}
        {showCreateSeedballButton && !isMobile && <Box sx={{textAlign: 'center', mt: 5}}>
          <Button  variant="contained" onClick={careSeedball}>
          Care
          </Button>
        </Box>}
        
      </Container>}
      <Dialog
        disablebackdropclick="true"
        open={isSeedballCreated}
        TransitionComponent={Transition}>
          <Container sx={{margin: '30px 0'}}>
            <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
              style={{ height: '90%', width: '90%', position:'absolute', zIndex:'9' }}
            >
            </Player>
            <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
                Congratulations! You received a fruit basket.
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
                Unpack this basket to get the fruit.
            </Typography>
              
            <MotionInView variants={varZoomIn}>
              <Box
                component="img"
                src="/static/seedball/seedball-basket.png"
                sx={{
                  width: '70%',
                  borderRadius: '10px',
                  display: 'block',
                  margin: 'auto',
                  filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
                }}
              />
            </MotionInView>
            <Box sx={{textAlign: 'center', mt: 5}}>
              <Button variant="contained" onClick={unpack} sx={{zIndex:'99'}}>
                  Unpack
              </Button>
            </Box>
          </Container>
      </Dialog>
      {hasUnpacked && fruit.name && 
        <Container>
          <Player
            autoplay
            loop
            src="https://assets6.lottiefiles.com/packages/lf20_30iie6.json"
            style={{ height: '75%', width: '75%', position:'absolute', right:'0', left:"0", zIndex:'9' }}
          >
          </Player>
          <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' , mx: 'auto' }}>
              Congratulations! You received {fruit.name}.
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mx: 'auto', mb: 5 }}>
              Go to harvest page to claim the reward.
          </Typography>
            
          <MotionInView variants={varZoomIn}>
            <Box
              component="img"
              src={`/static/seedball/harvest-care-${fruit.name.toLowerCase()}.jpg`}
              sx={{
                width: 250,
                borderRadius: '10px',
                display: 'block',
                margin: 'auto',
                filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
              }}
            />
          </MotionInView>
          <Box sx={{textAlign: 'center', mt: 5}}>
            <Button variant="contained" onClick={navigateToHarvest} sx={{zIndex:'99'}}>
                Claim Rewards
            </Button>
          </Box>
        </Container>}
    </Page>
    </RootStyle>
    </>
  );
}
